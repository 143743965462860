import { civilityObj } from "Constant/objs";

export const API = {
  patch: "/back/client/",
};

export const EDIT_ITEMS_CLIENT = [
  {
    title: "First name",
    placeholder: "First name",
    name: "firstname",
    inputForm: "SimpleInput",
  },
  {
    title: "Last name",
    placeholder: "Last name",
    name: "lastname",
    inputForm: "SimpleInput",
  },
  civilityObj,
  {
    title: "Company",
    placeholder: "company",
    name: "company",
    inputForm: "SimpleInput",
    required: false,
  },
  {
    title: "Phone",
    placeholder: "phone",
    name: "phone",
    inputForm: "SimpleInput",
  },
  {
    title: "Registry",
    placeholder: "Registry",
    name: "registry",
    inputForm: "SimpleInput",
  },

  {
    title: "Address",
    placeholder: "address",
    name: "address",
    inputForm: "SimpleInput",
  },
  {
    title: "City",
    placeholder: "city",
    name: "city",
    inputForm: "SimpleInput",
  },
  {
    title: "Zip code",
    placeholder: "zip code",
    name: "zipCode",
    inputForm: "SimpleInput",
  },

  {
    title: "Pro card",
    placeholder: "pro card",
    name: "proCard",
    inputForm: "SimpleInput",
    required: false,
  },
  {
    title: "Pro card got at",
    placeholder: "pro card got at",
    name: "proCardGotAt",
    inputForm: "DateInput",
    required: false,
  },
  {
    title: "Pro card expire at",
    placeholder: "pro card expire at",
    name: "proCardExpireAt",
    inputForm: "DateInput",
    required: false,
  },
];
