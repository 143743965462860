import React from "react";
import { Database, ChevronRight, ChevronDown } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { toggleSidebar } from "../../redux/requeteSql";


const Recherche = ({ setSearchQuery }) => {
  
  const dispatch = useDispatch();
  const sidebarVisible = useSelector(
    (state) => state.requeteSql.sidebarVisible
  );

  const toggleSidebarVisibility = () => {
    dispatch(toggleSidebar());
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
      <Container>
        <ContainerIcon>
          <Database size={17} />
          <span style={{ paddingLeft: "8px" }}>Data Base</span>
        </ContainerIcon>
        <ButtonIcon onClick={toggleSidebarVisibility}>
          {sidebarVisible ? (
            <ChevronRight size={20} />
          ) : (
            <ChevronDown size={20} />
          )}
        </ButtonIcon>
      </Container>
      <InputRecherche placeholder="Rechercher..." onChange={handleSearch} />
      <StyledHr />
    </>
  );
};

export default Recherche;

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  justify-content: space-between;
  padding: 10px;
`;

const ContainerIcon = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonIcon = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  &:hover {
    background-color: #c8c8c8;
  }
`;

const InputRecherche = styled.input`
  width: 90%;
  padding: 1px;
  padding-left: 10px;
  border: 1px solid #c1c1c1;
  margin-left: 10px;
  border-radius: 5px;
`;

const StyledHr = styled.hr`
  border: 0;
  height: 0.5px;
  background-color: #ccc;
  margin-top: 7px;
  margin-bottom: 3px;
`;
