import React, { Fragment, useState, useEffect } from "react";
import Multiselect from "multiselect-react-dropdown";

import { getCodeSerial } from "../../helper/getOptions";

import axiosClient from "../../Services/axiosClient";
import { Col, FormGroup, Label, Row } from "reactstrap";

const ChooseProducts = ({ products, onSelect, onRemove }) => {
  const [optionsList, setOptionsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const fetchedOptions = await Promise.all(
        products.map(async (elem) => {
          const { category, model } = elem;
          const url = `/back/products/${category}/${model}`;
          const res = await axiosClient.get(url);
          const options = getCodeSerial(res);
          return { category: elem.category, options, quantity: elem.quantity };
        })
      );
      setOptionsList(fetchedOptions);
    };

    try {
      fetchData();
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [products]);

  if (!isLoading && !isError)
    return (
      <Fragment>
        <Row>
          {optionsList.map((elem, index) => (
            <Fragment key={index}>
              <Col sm="4">
                <FormGroup>
                  <Label>{elem.category}</Label>
                  <Multiselect
                    options={elem.options}
                    onSelect={(selectedItems) =>
                      onSelect(elem.category, selectedItems)
                    }
                    onRemove={(selectedItems) =>
                      onRemove(elem.category, selectedItems)
                    }
                    displayValue="label"
                    showCheckbox={true}
                    selectionLimit={elem.quantity}
                  />
                </FormGroup>
              </Col>
            </Fragment>
          ))}{" "}
        </Row>
      </Fragment>
    );
};

export default ChooseProducts;
