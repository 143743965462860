import React from "react";
import { Slash } from "react-feather";

const BooleanIcon = ({ state }) => {
  if (state === null) return <Slash />;
  return state ? (
    <i className="fa fa-circle font-success f-12"></i>
  ) : (
    <i className="fa fa-circle font-danger f-12"></i>
  );
};

export default BooleanIcon;
