import React, { Fragment, useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import SearchForm from "./SearchForm";
import { SEARCH } from "../../Constant";
import { initializeSearchItems } from "../../redux/searchLink";

const FilterBar = ({ searchByItems }) => {
  const dispatch = useDispatch();
  const [render, setRender] = useState(0);

  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    setRender((prevState) => prevState + 1);
    if (isOpen) {
      dispatch(initializeSearchItems());
    }
  };

  return (
    <div style={{ marginBottom: "10px" }}>
      <Accordion open={isOpen ? "1" : "0"} toggle={toggleAccordion}>
        <AccordionItem>
          <AccordionHeader targetId="1">{t(SEARCH)}</AccordionHeader>
          <AccordionBody accordionId="1">
            <Fragment key={render}>
              <SearchForm searchByItems={searchByItems} />
            </Fragment>
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default FilterBar;
