import React, { Fragment, useEffect, useState } from "react";
import useFetchData from "hooks/useFetchData";
import { getData } from "helper/getData";
import NotFound from "Components/NotFound";
import StatisticLoading from "Components/Loading/StatisticLoading";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import Grid from "@mui/material/Grid";
import MDBox from "Components/MDBox";
import PieChart from "examples/Charts/PieChart";

const PurchaseRequestPieChart = ({ inputs, params }) => {
  const url = "/stat/stock-Materials/purchase-request";
  const { data, isLoading, isError } = useFetchData(url);

  const productStatus = getData(data) ?? [];

  const transformData = (apiData) => {
    if (!Array.isArray(apiData)) {
      return {
        labels: [],
        datasets: {
          label: "Raw material Quantity",
          backgroundColors: ["info", "primary", "dark", "secondary"],
          data: [],
        },
      };
    }

    // Define your desired statuses and corresponding colors
    const statuses = ["DRAFT", "FINISHED", "CANCELED", "PENDING"];
    const colors = ["info", "primary", "dark", "secondary"];

    // Initialize datasets for pie chart
    const datasets = {
      labels: statuses,
      datasets: {
        label: "Projects",
        backgroundColors: colors,
        data: statuses.map(status =>
          apiData.filter(item => item.label === status).reduce((total, item) => total + item.data, 0)
        ),
      },
    };

    return datasets;
  };

  if (isError) return <NotFound />;
  if (isLoading) return <StatisticLoading />;

  const reportsLineChartData = transformData(productStatus);

  return (
    <Fragment>
          {reportsLineChartData && (
            <PieChart
              icon={{ color: "info", component: <RequestQuoteIcon /> }}
              title="Purchase Request"
              description="By Status"
              height="320px"
              chart={reportsLineChartData}
            />
          )}
    </Fragment>
    
  );
};

export default PurchaseRequestPieChart;
