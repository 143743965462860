import { ShowErrorMsg, addAlert } from "../Components/Alerts/Alerts";
import axiosClient from "./axiosClient";

const postData = async (newData, URL_CREATE, changeLoadingState, success) => {
  await axiosClient
    .post(URL_CREATE, newData)
    .then(() => {
      addAlert();
      success ?? success();
    })
    .catch((error) => {
      ShowErrorMsg(error);
    });
  console.log(changeLoadingState);
  if (changeLoadingState) changeLoadingState(false);
};

export default postData;
