const handleInputs = (event, setInputs) => {
  if (event.target === undefined) {
    setInputs((values) => ({ ...values, [event.name]: event.value }));
  } else {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  }
};

export default handleInputs;
