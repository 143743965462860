import React, { Fragment } from "react";
import { Card, CardBody } from "reactstrap";

import DataTables from "../../Components/DataTables";
import DataTableHeader from "../../Components/DataTableHeader";
import Breadcrumbs from "../../Components/Breadcrumbs";
import FilterBar from "../../Components/FilterBar";
import EditItem from "../../Components/EditItem";
import ModalApp from "../../Components/ModalApp";
import AddItem from "../../Components/AddItem";

import { API, COLUMNS, CREATE_ITEMS, EDIT_ITEMS, SEARCH_ITEMS } from "./data";

import {
  CREATE_PURCHASE_REQUESTS,
  EDIT_PURCHASE_REQUESTS,
  MY_PURCHASE_REQUESTS,
} from "../../Constant/index";

const PurchaseRequestsPage = () => {
  const list = API.list;

  const breadCrumbsProps = [MY_PURCHASE_REQUESTS];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTableHeader createBtn={true} />

          <FilterBar searchByItems={SEARCH_ITEMS} />

          <DataTables newColumns={COLUMNS} link={list} />
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_PURCHASE_REQUESTS}>
        <AddItem addURL={API.create} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_PURCHASE_REQUESTS}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default PurchaseRequestsPage;
