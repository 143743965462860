import React, { Fragment, useState } from "react";
import { Row, Col } from "reactstrap";
import Grid from "@mui/material/Grid";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";

import { useTranslation } from "react-i18next";
import MDBox from "Components/MDBox";
import FilterByCategoryModel from "pages/StatStockPage/FilterByCategoryModel";
import CountSalesRevenue from "./data/CountSalesRevenue";
import GrowthRate from "./data/GrowthRate";
import BarChartCategorySales from "./data/BarChartCategorySales";
import BarChartSalesByCity from "./data/BarChartSalesByCity";
import BarCharService from "./data/BarCharService";
import LineChartTurnover from "./data/LineChartTurnover";

const StatSalesPage = () => {
  const { t } = useTranslation();
  const [inputs, setInputs] = useState({
    dateValue: "",
    dateValue2: "",
  });
  const [inputs1, setInputs1] = useState({
    dateValue: "",
  });

  const params = {
    date: true,
  };

  return (
    <>
      {/* <DashboardNavbar /> */}
      <Row>
        <Fragment>
          <Row>
            <Col>
              <FilterByCategoryModel
                params={params}
                inputs={inputs}
                setInputs={setInputs}
              />
            </Col>
            <Col>
              <FilterByCategoryModel
                params={params}
                inputs={inputs1}
                setInputs={setInputs1}
              />
            </Col>
          </Row>
        </Fragment>
      </Row>
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <CountSalesRevenue inputs={inputs} />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <GrowthRate inputs={inputs} inputs1={inputs1} />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={5}>
              <MDBox mb={3}>
                <BarChartSalesByCity />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={7}>
              <MDBox mb={3}>
                <LineChartTurnover />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={5}>
              <MDBox mb={3}>
                <BarCharService />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={7}>
              <MDBox mb={3}>
                <BarChartCategorySales />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </>
  );
};

export default StatSalesPage;
