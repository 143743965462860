import React from "react";
import { Row, Col, Card, Nav } from "reactstrap";

const HeaderTab = ({ tab }) => {
  return (
    <Col md="12" className="project-list">
      <Card>
        <Row>
          <Col sm="6">
            <Nav tabs className="border-tab">
              {tab.map((elem, i) => (
                <div className="text-end" key={i}>
                  {elem}
                </div>
              ))}
            </Nav>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export default HeaderTab;
