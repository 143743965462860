import { Group } from "@mantine/core";
import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";
import EditBtn from "Components/MantineDataTableBtns/EditBtn";
import { getCodeLabel } from "helper/getOptions";

// Urls
const GET_TARIFF_RULES = (code) => `/back/tariff/${code}/rules`;

export const API = {
  list: (t) => `/back/tariff/${t}/rules`,
  get: "/back/rule/",
  GET_TARIFF_RULES: GET_TARIFF_RULES,
  create: "/back/tariff/",
  edit: "/back/rule/",
};

// Columns
export const COLUMNS = [
  {
    name: "Label",
    selector: "label",
  },

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 50,
    Cell: ({ row }) => {
      const { code, active, label } = row.original;
      return (
        <Group gap="xs">
          <ActiveBtn patch={API.edit + code} state={active} />
          <EditBtn read={API.get + code} extra={label} />
        </Group>
      );
    },
  },
];

// Search
const activeOptions = [
  {
    value: "true",
    label: "active",
    name: "active",
  },
  {
    value: "false",
    label: "not active",
    name: "active",
  },
  {
    value: "",
    label: "All",
    name: "active",
  },
];
export const SEARCH_ITEMS = [
  {
    title: "label",
    placeholder: "label",
    name: "label",
    inputForm: "TitleInput",
  },
  {
    name: "active",
    title: "Active",
    inputForm: "SelectInput",
    options: activeOptions,
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "label",
    placeholder: "label",
    name: "label",
    inputForm: "TitleInput",
  },
  {
    title: "active_hour",

    placeholder: "active_hour",
    name: "active_hour",
    inputForm: "TitleInput",
  },
  {
    title: "active_days",
    placeholder: "active_days",
    name: "active_days",
    inputForm: "TitleInput",
  },
  {
    title: "Polygon",
    name: "polygon",
    inputForm: "SelectApiInput",
    optionsApi: "/back/polygons/choices",
    getOptions: getCodeLabel,
  },
  {
    title: "holiday",
    placeholder: "holiday",
    name: "holiday",
    inputForm: "CheckBox",
  },
  {
    title: "backForth",

    placeholder: "backForth",
    name: "backForth",
    inputForm: "CheckBox",
  },
];

export const EDIT_ITEMS = [
  {
    title: "label",
    placeholder: "label",
    name: "label",
    inputForm: "TitleInput",
  },
  {
    title: "active_hour",
    placeholder: "active_hour",
    name: "active_hour",
    inputForm: "TitleInput",
  },
  {
    title: "active_days",
    placeholder: "active_days",
    name: "active_days",
    inputForm: "TitleInput",
  },
  {
    title: "Polygon",
    name: "polygon",
    inputForm: "SelectApiInput",
    optionsApi: "/back/polygons/choices",
    getOptions: getCodeLabel,
  },
  {
    title: "holiday",
    placeholder: "holiday",
    name: "holiday",
    inputForm: "CheckBox",
  },
  {
    title: "backForth",
    placeholder: "backForth",
    name: "backForth",
    inputForm: "CheckBox",
  },
];
