import React, { Fragment, useRef } from "react";

import { useSelector } from "react-redux";
import logo from "../../assets/images/logo/logoV2.png";
import { Card, CardBody } from "reactstrap";
import { useReactToPrint } from "react-to-print";

import styles from "./style.module.css";
import PrintBtn from "../../Components/PrintBtn";
import useFetchData from "../../hooks/useFetchData";
import Loading from "../../Components/Loading/Loading";
import { CheckCircle, Slash, Square } from "react-feather";

const getTaximeterData = (param) => {
  try {
    return param.data.results.data;
  } catch {
    return [];
  }
};

const getCurrentDate = () => {
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();

  const result = `${day}-${month}-${year}`;
  console.log(result);
  return result;
};

const PDFTaximeter = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { serial_number } = useSelector(
    (state) => state.currentRow.rowMaterialStock
  );
  const url = `/backQC/taximeter/${serial_number}`;

  const { data, isLoading, isError } = useFetchData(url);
  if (isLoading) return <Loading />;

  if (isError) return null;

  const taximterData = getTaximeterData(data);

  const linkedData = taximterData.linked
    ? [
        { label: "profile", value: taximterData.profile },
        { label: "BadgeSerialNumber", value: taximterData.BadgeSerialNumber },
        { label: "BadgeRfid", value: taximterData.BadgeRfid },
      ]
    : [];

  const taximeterTable = [
    { label: "Serial", value: taximterData.serialNumber },
    { label: "meid", value: taximterData.meid },

    { label: "imei", value: taximterData.imei },
    { label: "imei2", value: taximterData.imei2 },
    { label: "status", value: taximterData.status },
    { label: "Produit à", value: taximterData.producedAt },

    ...linkedData,
  ];

  const icon = <CheckCircle />;
  const translationMap = {
    motherBoardCheck: "Carte mère",
    hardwareCheck: "Composants",
    gpioInCheck: "GPIO In",
    gpioOutCheck: "GPIO Out",
    rs232Check: "RS232",
    usbCheck: "USB",
    emtCheck: "EMT",
    afterCutCheck: "Après coupe",
    primitiveCheck: "Primitive",
    packagingCheck: "Emballage",
  };

  const currentDate = getCurrentDate();

  return (
    <Fragment>
      <div ref={componentRef} className={styles["pdf-cart"]}>
        <Card className={styles["custom-card"]}>
          <CardBody className={styles["custom-card-body"]}>
            <div className={styles["logo-container"]}>
              <img src={logo} className={styles["logo"]} alt="logo" />
            </div>
            <div className={styles["data"]}>
              {taximeterTable.map((elem, i) => (
                <div className={styles["data-item"]} key={i}>
                  <strong>{elem.label}</strong>
                  {elem.value === null ? "     ---" : elem.value}
                </div>
              ))}

              {Object.keys(taximterData.check).map((key) => {
                const translatedKey = translationMap[key];
                return (
                  <div className={styles["data-item"]} key={key}>
                    <div style={{ display: "flex", gap: "10px" }}>
                      {taximterData.check[key] ? icon : <Slash />}
                      <strong>{translatedKey}</strong>
                    </div>
                  </div>
                );
              })}
            </div>

            <div style={{ display: "flex", gap: "10px", marginTop: "20px" }}>
              <Square />
              <strong>CE</strong>
            </div>

            {/* Signature Box */}
            <div style={{ marginTop: "10px" }}>Signature:</div>
            <div className={styles["signature-box"]} />
            <div className={styles["data"]}>
              <strong>Date:</strong> {currentDate}
            </div>
          </CardBody>
        </Card>
      </div>
      <div className={styles["print-button"]}>
        <PrintBtn onClick={handlePrint} />
      </div>
    </Fragment>
  );
};

export default PDFTaximeter;
