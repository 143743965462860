import { Fragment } from "react";

import DataTables from "Components/DataTables/DataTable";
import EditItem from "Components/EditItem";
import ModalApp from "Components/ModalApp";
import AddItem from "Components/AddItem";

import { API, COLUMNS, CREATE_ITEMS, EDIT_ITEMS } from "./data";
import { CREATE_TERM_CONDITION, EDIT_TERM_CONDITION } from "Constant";

const TermCondition = () => {
  const list = API.list;

  return (
    <Fragment>
      <DataTables newColumns={COLUMNS} link={list} globalSearch={false} />

      <ModalApp type="create" title={CREATE_TERM_CONDITION}>
        <AddItem addURL={API.create} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_TERM_CONDITION}>
        {/* <EditItem itemsUpdate={EDIT_ITEMS} /> */}
      </ModalApp>
    </Fragment>
  );
};

export default TermCondition;
