import React, { Fragment } from "react";
import Breadcrumbs from "../../Components/Breadcrumbs";
import { THERE_IS_NO_HISTORY_FOUND } from "../../Constant";

const Historique = ({ data }) => {
  function checkHistory(history) {
    const isEmpty = Object.values(history).every((arr) => arr.length === 0);

    if (isEmpty) {
      return "empty";
    } else {
      return Object.keys(history).filter((key) => history[key].length > 0);
    }
  }

  const history = {
    h_boxs: [],
    h_luminouss: [],
    h_printers: [],
    h_sunshields: [],
    h_sos_buttons: [],
    h_styluss: [],
    h_relay_switchs: [],
    h_sensors: [],
    h_obd_adapters: [],
    h_brackets: [],
    h_female_brackets: [],
    h_taximeters: [],
  };
  const breadCrumbsProps = [THERE_IS_NO_HISTORY_FOUND];

  if (checkHistory(history) === "empty") {
    return <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />;
  } else {
    return (
      <>
        {checkHistory(history).map((elem, i) => (
          <Fragment key={i}>{elem}</Fragment>
        ))}
      </>
    );
  }
};

export default Historique;
