import React from "react";
import { useNavigate } from "react-router-dom";

import { ActionIcon } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";

const EditBtn = ({ read: path, disabled }) => {
  const navigate = useNavigate();

  return (
    <ActionIcon
      size={30}
      variant="default"
      onClick={() => navigate(path)}
      disabled={disabled}
    >
      <IconEdit />
    </ActionIcon>
  );
};

export default EditBtn;
