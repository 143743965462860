import { Fragment } from "react";

import { Card, CardBody } from "reactstrap";

import DataTables from "Components/DataTables/DataTable";
import Breadcrumbs from "Components/Breadcrumbs";
//import AddItem from "Components/AddItem";

import { API, COLUMNS } from "./data";

import { PURCHASE_MY_ORDERS } from "Constant";

const PurchaseOrderPage = () => {
  const list = API.list;

  const breadCrumbsProps = [PURCHASE_MY_ORDERS];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTables newColumns={COLUMNS} link={list} createBtn={false} />
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default PurchaseOrderPage;
