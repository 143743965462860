import React from "react";
import { PlayCircle } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeCurrentRow } from "../../../redux/currentRow";
import { Btn } from "../../../AbstractElements";
import style from "../../../CommonElements/Button/btncustomize.module.css";
import { setEditorContent, setButtonClicked } from "../../../redux/requeteSql";

const ExcuteBtn = ({ read, edit, sqlQuery }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleExcute = () => {
    const selectedRow = {
      getLink: read || edit,
      putLink: edit || read,
    };
    dispatch(changeCurrentRow(selectedRow));
    dispatch(setEditorContent(sqlQuery.sql_code));
    dispatch(setButtonClicked(true));
    navigate("/statistics-requests");
  };

  return (
    <Btn
      attrBtn={{
        onClick: handleExcute,
        color: "success",
        variant: "contained",
        className: `btn btn-secondary btn-sm ${style["custom-btn-container"]}   `,
      }}
    >
      <PlayCircle className={style["custom-btn"]} />
    </Btn>
  );
};

export default ExcuteBtn;
