import AddItem from "Components/AddItemWithZod";
import React from "react";
import { CREATE_ClIENT } from "./data";
import onSubmit from "./onSubmitEstimate";

const CreateClient = () => {
  // customFn(data, addURL, setIsLoading, addedWithSuccess)
  return (
    <div>
      <AddItem
        addURL={"/back/client"}
        items={CREATE_ClIENT}
        customFn={onSubmit}
      />
    </div>
  );
};

export default CreateClient;
