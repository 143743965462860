import { Group } from "@mantine/core";
import ReadPageBtn from "Components/MantineDataTableBtns/ReadPageBtn";

// Urls
export const API = {
  list: "/back/services/prices",
  create: "/back/service",
};

// Columns
export const COLUMNS = [
  { name: "Label", selector: "label" },
  { name: "Price", selector: "price" },
  { name: "Updated at", selector: "updated_at" },

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 60,
    Cell: ({ row }) => {
      const { code } = row.original;
      return (
        <Group gap="xs">
          <ReadPageBtn read={`/pricing-services/${code}`} />
        </Group>
      );
    },
  },
];

// Search
export const SEARCH_ITEMS = [
  {
    title: "Required",
    name: "required",
    inputForm: "SelectInput",
    options: [
      {
        value: "true",
        label: "required",
        name: "required",
      },
      {
        value: "false",
        label: "not required",
        name: "required",
      },
    ],
  },
  {
    title: "Label",
    placeholder: "Label",
    name: "label",
    inputForm: "SimpleInput",
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Code",
    placeholder: "Code",
    name: "code",
    inputForm: "SimpleInput",
  },
  {
    title: "Label",
    placeholder: "Label",
    name: "label",
    inputForm: "SimpleInput",
  },
  {
    title: "Required",
    name: "required",
    inputForm: "SelectInput",
    options: [
      {
        value: "true",
        label: "required",
        name: "required",
      },
      {
        value: "false",
        label: "not required",
        name: "required",
      },
    ],
  },
];

export const EDIT_ITEMS = [
  {
    title: "Code",
    placeholder: "Code",
    name: "code",
    inputForm: "SimpleInput",
  },
  {
    title: "Label",
    placeholder: "Label",
    name: "label",
    inputForm: "SimpleInput",
  },
  {
    title: "Required",
    name: "required",
    inputForm: "SelectInput",
    options: [
      {
        value: "true",
        label: "required",
        name: "required",
      },
      {
        value: "false",
        label: "not required",
        name: "required",
      },
    ],
  },
];
