import { createSlice } from "@reduxjs/toolkit";

export const toggleStyleSlice = createSlice({
  name: "toggleStyle",

  initialState: {
    sidebarResponsive: false,
    currentPage: "",
    domain: "messages",
  },

  reducers: {
    toggleStyle: (state) => {
      state.sidebarResponsive = !state.sidebarResponsive;
    },
  },
});

export const { toggleStyle } = toggleStyleSlice.actions;

export default toggleStyleSlice.reducer;
