// import StatMPassagerPage from "../pages/StatMPassagerPage";
import StatReservationPage from "../pages/StatReservationPage";
import StatSalesPage from "../pages/StatSalesPage";
import StatStockPage from "../pages/StatStockPage";
// import StatSharingPage from "../pages/StatSharingPage";
import StatStockFinishedProductPage from "../pages/StatStockFinishedProductPage";

const STATISTICS_ROUTES = [
  {
    path: "/statistics-stock-Raw-Material",
    element: <StatStockPage />,
    roles: [
      "SUPER_ADMIN",
      "ADMIN_PRODUCTION",
      "ADMIN_COMR",
      "ADMIN_STOCK",
      "AGENT_PCB_CONTROL",
      "AGENT_PACKAGING",
      "AGENT_SOFTWARE_CONTROL",
      "AGENT_COMR",
      "AGENT_STOCK",
      "AGENT_PRODUCTION",
      "BACK",
      "USER",
      "ADMIN_STOCK_PRODUCTION",
    ],
  },
  {
    path: "/statistics-sales",
    element: <StatSalesPage />,
    roles: [
      "SUPER_ADMIN",
      "ADMIN_PRODUCTION",
      "ADMIN_COMR",
      "ADMIN_STOCK",
      "AGENT_PCB_CONTROL",
      "AGENT_PACKAGING",
      "AGENT_SOFTWARE_CONTROL",
      "AGENT_COMR",
      "AGENT_STOCK",
      "AGENT_PRODUCTION",
      "BACK",
      "USER",
      "ADMIN_STOCK_PRODUCTION",
    ],
  },
  {
    path: "/statistics-fp",
    element: <StatStockFinishedProductPage />,
    roles: [
      "SUPER_ADMIN",
      "ADMIN_PRODUCTION",
      "ADMIN_COMR",
      "ADMIN_STOCK",
      "AGENT_PCB_CONTROL",
      "AGENT_PACKAGING",
      "AGENT_SOFTWARE_CONTROL",
      "AGENT_COMR",
      "AGENT_STOCK",
      "AGENT_PRODUCTION",
      "BACK",
      "USER",
      "ADMIN_STOCK_PRODUCTION",
    ],
  },
  // {
  //   path: "/statistics-marketing-passager",
  //   element: <StatMPassagerPage />,
  //   roles: [
  //     "SUPER_ADMIN",
  //     "ADMIN_PRODUCTION",
  //     "ADMIN_COMR",
  //     "ADMIN_STOCK",
  //     "AGENT_PCB_CONTROL",
  //     "AGENT_PACKAGING",
  //     "AGENT_SOFTWARE_CONTROL",
  //     "AGENT_COMR",
  //     "AGENT_STOCK",
  //     "AGENT_PRODUCTION",
  //     "BACK",
  //     "USER",
  //     "ADMIN_STOCK_PRODUCTION",
  //   ],
  // },
  {
    path: "/statistics-reservation",
    element: <StatReservationPage />,
    roles: [
      "SUPER_ADMIN",
      "ADMIN_PRODUCTION",
      "ADMIN_COMR",
      "ADMIN_STOCK",
      "AGENT_PCB_CONTROL",
      "AGENT_PACKAGING",
      "AGENT_SOFTWARE_CONTROL",
      "AGENT_COMR",
      "AGENT_STOCK",
      "AGENT_PRODUCTION",
      "BACK",
      "USER",
      "ADMIN_STOCK_PRODUCTION",
    ],
  },
  // {
  //   path: "/statistics-sharing",
  //   element: <StatSharingPage />,
  //   roles: [
  //     "SUPER_ADMIN",
  //     "ADMIN_PRODUCTION",
  //     "ADMIN_COMR",
  //     "ADMIN_STOCK",
  //     "AGENT_PCB_CONTROL",
  //     "AGENT_PACKAGING",
  //     "AGENT_SOFTWARE_CONTROL",
  //     "AGENT_COMR",
  //     "AGENT_STOCK",
  //     "AGENT_PRODUCTION",
  //     "BACK",
  //     "USER",
  //     "ADMIN_STOCK_PRODUCTION",
  //   ],
  // },
];

export default STATISTICS_ROUTES;
