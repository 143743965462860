import { Fragment } from "react";
import { Card, CardBody } from "reactstrap";

import DataTables from "Components/DataTables/DataTable";
import Breadcrumbs from "Components/Breadcrumbs";
import ModalApp from "Components/ModalApp";
import EditItem from "Components/EditItemWithZod";
import AddItem from "Components/AddItemWithZod";

import { API, COLUMNS, CREATE_ITEMS, EDIT_ITEMS } from "./data";

import {
  CREATE_DISTRIBUTOR,
  EDIT_DISTRIBUTOR,
  MY_DISTRIBUTORS,
} from "Constant";

const DistributorPage = () => {
  const list = API.list;

  const breadCrumbsProps = [MY_DISTRIBUTORS];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />
          <DataTables newColumns={COLUMNS} link={list} />
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_DISTRIBUTOR}>
        <AddItem addURL={API.create} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_DISTRIBUTOR}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default DistributorPage;
