import React, { Fragment, useState } from "react";
import style from "./HorizontalTab.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { initializeListOfCodes } from "../../redux/selectedRows";

const HorizontalTab = ({ table }) => {
  const [rSelected, setRSelected] = useState(0);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleOnClick = (val) => {
    setRSelected(val);
    dispatch(initializeListOfCodes());
  };

  return (
    <div>
      <div className={style["container"]}>
        <div className={style["radio-tile-group"]}>
          {table.map((item, i) => (
            <Fragment key={i}>
              <div
                className={`${style["input-container"]} ${
                  rSelected === i ? style["checked"] : ""
                }`}
                onClick={() => handleOnClick(i)}
              >
                <div className={style["radio-tile"]}>
                  <div className={`${style.icon} ${style["walk-icon"]}`}>
                    <item.icon />
                  </div>
                  <label className={style["radio-tile-label"]}>
                    {t(item.title)}
                  </label>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      </div>

      {table[rSelected].elem}
    </div>
  );
};

export default HorizontalTab;
