import React, { useState } from "react";
import { Button, Loader } from "@mantine/core";
import { useSelector } from "react-redux";
import postData from "Components/AddItem/postData";

const SendData = () => {
  const [isSending, setIsSending] = useState(false);
  const sales = useSelector((state) => state.sales);
  const { client, taxi, type, products } = useSelector((state) => state.sales);

  const url = "/back/salesvoucher";

  const createSalesVoucher = () => {
    setIsSending(true);
    postData(sales, url, setIsSending, addedWithSuccess);
  };

  const addedWithSuccess = () => {
    // Handle successful addition
  };

  function areAllProductsFilled() {
    return products.every(
      (product) => product.category && product.codes && product.codes.length > 0
    );
  }

  const allDataFilled = client && taxi && type && areAllProductsFilled();
  return (
    <Button
      variant="filled"
      color="green"
      onClick={() => createSalesVoucher()}
      disabled={isSending && !allDataFilled}
    >
      {isSending ? <Loader color="white" size="sm" /> : "Send"}
    </Button>
  );
};

export default SendData;
