import Cookies from "js-cookie";

const clearUserData = () => {
  localStorage.clear();
  Cookies.remove("_auth_state");
  Cookies.remove("_auth_storage");
  Cookies.remove("_auth_type");
  Cookies.remove("_auth");
  window.location.href = "/login";
};

export default clearUserData;
