import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Space } from "@mantine/core";
import { editService } from "../../redux/estimateSlicer";
import { Panel } from "primereact/panel";

import { getPriceVat } from "helper/getData";
import axiosClient from "Services/axiosClient";

import Translate from "Components/Translate";
import {
  DESCRIPTION,
  QUANTITY,
  SERVICE,
  TTC,
  UNITY_PRICE,
  VAT,
} from "Constant";

import { DeleteServiceBtn } from "./EstimatesBtns";
import SelectApiInputService from "./SelectApiInputService";
import { getNumber, serviceInp } from "./data";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";

const calculateService = (price_ht = 0, vat, quantity) => {
  const calculate_vat = Number(price_ht) * (Number(vat) / 100);
  const price_vat = (Number(price_ht) + calculate_vat).toFixed(4);
  const price_ttc = Number(price_vat * Number(quantity)).toFixed(4);

  return {
    price_ttc,
  };
};

export default function OneProduct({ index }) {
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({});

  const serviceData = useSelector((state) => state.estimate.services[index]);
  const service = serviceData.service;

  console.log();

  useEffect(() => {
    dispatch(
      editService({
        data: inputs,
        index: index,
      })
    );
  }, [inputs]);

  useEffect(() => {
    if (service) {
      axiosClient(`/back/service/${service}/price`)
        .then((res) => {
          const result = getPriceVat(res);
          dispatch(editService({ data: result, index: index }));
        })
        .catch((errror) => {
          console.error(errror);
        });
    }
  }, [service]);

  const serviceResult = {
    ...serviceData,
    ...calculateService(
      serviceData.price_ht,
      serviceData.vat,
      serviceData.quantity
    ),
  };

  console.log(serviceData["service"]);

  return (
    <Fragment>
      <Panel
        headerTemplate={
          <div className="p-panel-header justify-content-space-between">
            <div className="flex align-items-center ">
              <span className="font-bold">
                <Translate>{SERVICE}</Translate>: {index + 1}
              </span>
            </div>
            <div>
              <DeleteServiceBtn i={index} />
            </div>
          </div>
        }
        toggleable
      >
        <div className="grid">
          <div className="col-12 md:col-2" style={{ minWidth: "100px" }}>
            <div className="flex flex-column ">
              <label>
                <Translate>{SERVICE}</Translate>
              </label>
              <SelectApiInputService
                register={(e) => {
                  const newVal = { [e.name]: e.value };
                  setInputs((preState) => ({ ...preState, ...newVal }));
                }}
                inputName={serviceInp}
                oldValue={serviceData["service"]}
              />
            </div>
          </div>

          <div className="col" style={{ minWidth: "100px", maxWidth: "150px" }}>
            <div className="flex flex-column ">
              <label>
                <Translate>{QUANTITY}</Translate>
              </label>
              <InputNumber
                value={serviceData["quantity"]}
                onValueChange={(e) => {
                  const newVal = { quantity: e.value };
                  setInputs((preState) => ({ ...preState, ...newVal }));
                }}
                min={1}
                showButtons
                size={5}
              />
            </div>
          </div>
          <div className="col-12 md:col-2" style={{ minWidth: "150px" }}>
            <div className="flex flex-column ">
              <label>
                <Translate>{DESCRIPTION}</Translate>
              </label>
              <InputText
                value={serviceData["description"]}
                onChange={(e) => {
                  const newVal = { description: e.target.value };
                  setInputs((preState) => ({ ...preState, ...newVal }));
                }}
              />
            </div>
          </div>

          <div className="col" style={{ minWidth: "100px", maxWidth: "150px" }}>
            <div className="flex flex-column ">
              <label>
                <Translate>{UNITY_PRICE}</Translate>
              </label>
              <InputNumber
                value={getNumber(serviceResult["price_ht"])}
                mode="decimal"
                disabled={true}
                variant="filled"
                size={5}
              />
            </div>
          </div>
          <div className="col" style={{ minWidth: "100px", maxWidth: "150px" }}>
            <div className="flex flex-column ">
              <label>
                <Translate>{VAT}</Translate>
              </label>
              <InputNumber
                value={getNumber(serviceResult["vat"])}
                mode="decimal"
                disabled={true}
                variant="filled"
                size={5}
                suffix="%"
              />
            </div>
          </div>
          <div className="col" style={{ minWidth: "100px", maxWidth: "150px" }}>
            <div className="flex flex-column ">
              <label>
                <Translate>{TTC}</Translate>
              </label>
              <InputNumber
                value={getNumber(serviceResult["price_ttc"])}
                mode="decimal"
                disabled={true}
                variant="filled"
                size={5}
              />
            </div>
          </div>
        </div>
      </Panel>

      <Space h="lg" />
    </Fragment>
  );
}
