import { DEPOSIT, TAXI } from "Constant";
import {
  getCodeLabel,
  getModelsLabel,
  getServiceLabel,
} from "helper/getOptions";

export const clientInp = {
  title: "Client",
  name: "client",
  optionsApi: "/back/client",
  getOptions: getCodeLabel,
  required: false,
  width: "4",
};

export const taxiInp = (taxi) => ({
  title: TAXI,
  name: "taxi",
  optionsApi: `/back/client/${taxi}/taxis/choices`,
  getOptions: getCodeLabel,
  required: false,
  width: "4",
});

export const depositInp = {
  title: DEPOSIT,
  name: "deposit",
};

export const categoryInp = {
  title: "Category",
  name: "category",
  optionsApi: "/back/inventory-categories",
  getOptions: getCodeLabel,
};

export const modelInp = (category) => ({
  title: "Model",
  name: "model",
  optionsApi: `/back/models/choices/${category}`,
  getOptions: getModelsLabel,
});

export const serviceInp = {
  title: "Service",
  name: "service",
  inputForm: "SelectApiInput",
  optionsApi: "/back/services",
  getOptions: getServiceLabel,
};

export const getNumber = (param) => {
  if (isNaN(param)) return 0;

  return parseFloat(param).toFixed(1);
};

export const calculatePriceAfterDiscount = (price, discount = 0) => {
  console.log(price);
  console.log(discount);
  const discountVal = discount ? discount : 0;
  // Ensure that price and discount are valid numbers
  let validPrice = isNaN(price) ? 0 : parseFloat(price);
  let validDiscount = isNaN(discountVal) ? 0 : parseFloat(discountVal);

  let finalPrice = validPrice - validPrice * (validDiscount / 100);

  // Return the final price formatted to one decimal place
  return finalPrice.toFixed(1);
};
