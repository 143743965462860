import { Group } from "@mantine/core";
import ReadPageBtn from "../../Components/MantineDataTableBtns/ReadPageBtn";
import { getCodeLabel } from "../../helper/getOptions";

// Urls
export const API = {
  // GET_WORDINGS_CHOICE: "/back/wordings/choices",
  import: "/back/wordings",
  create: "/back/wording",
  list: "/back/wordings",
  // GET_WORDING: "/back/wording",
};

// Columns
export const COLUMNS = [
  {
    name: "Code",
    selector: "code",
  },
  {
    name: "Label",
    selector: "label",
  },
  {
    name: "Domain",
    selector: "domain",
  },
  {
    name: "Created at",
    selector: "created_at",
  },

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 70,
    Cell: ({ row }) => {
      const { code, active, label } = row.original;
      return (
        <Group gap="xs">
          <ReadPageBtn read={`/wording/${code}`} />
        </Group>
      );
    },
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Domain",
    name: "domain",
    inputForm: "SelectApiInput",
    optionsApi: "/back/wordingdomains/choices",
    getOptions: getCodeLabel,
  },
  {
    title: "Code",
    placeholder: "Code",
    name: "code",
    inputForm: "SimpleInput",
  },
  {
    title: "Label",
    placeholder: "Label",
    name: "label",
    inputForm: "SimpleInput",
  },
];

export const EDIT_ITEMS = [
  {
    title: "Domain",
    placeholder: "Domain",
    name: "domain",
    inputForm: "SimpleInput",
  },
  {
    title: "Code",
    placeholder: "Code",
    name: "code",
    inputForm: "SimpleInput",
  },
  {
    title: "Label",
    placeholder: "Label",
    name: "label",
    inputForm: "SimpleInput",
  },
];
