import React, { Fragment } from "react";
import useFetchData from "hooks/useFetchDataWithDep";
import { getData } from "helper/getData";
import generateSearchLink from "Services/generateSearchLink";
import NotFound from "Components/NotFound";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import CancelIcon from "@mui/icons-material/Cancel";
import FeedbackIcon from "@mui/icons-material/Feedback";
import CommentIcon from "@mui/icons-material/Comment";
import StatisticLoading from "Components/Loading/StatisticLoading";
import Grid from "@mui/material/Grid";
import MDBox from "Components/MDBox";
import HailIcon from "@mui/icons-material/Hail";

const ReservationIndicator = ({ inputs }) => {
  const searchItems = {
    date: inputs.dateValue,
  };
  const searchItems1 = {
    date: inputs.dateValue,
    status: inputs.status,
  };

  function getToday() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const url = generateSearchLink(
    "/stat/reservation-client/satisfaction-rate",
    searchItems
  );
  const url1 = generateSearchLink(
    "/stat/reservation-client/rate-canceled",
    searchItems1
  );
  const url2 = generateSearchLink(
    "/stat/reservation-client/taux-reclamation",
    searchItems
  );
  const url3 = generateSearchLink(
    "/stat/reservation-client/dispute-comment",
    searchItems
  );
   const url4 = generateSearchLink(
     "/stat/reservation-client/count",
     searchItems1
   );

  const { data, isLoading, isError } = useFetchData(url, inputs);
  const {
    data: data1,
    isLoading: isLoading1,
    isError: isError1,
  } = useFetchData(url1, inputs);
  const {
    data: data2,
    isLoading: isLoading2,
    isError: isError2,
  } = useFetchData(url2, inputs);
  const {
    data: data3,
    isLoading: isLoading3,
    isError: isError3,
  } = useFetchData(url3, inputs);
   const {
     data: data4,
     isLoading: isLoading4,
     isError: isError4,
   } = useFetchData(url4, inputs);

  if (isError || isError1 || isError2 || isError3 || isError4) return <NotFound />;

  const satisfactionRate = getData(data) ?? 0;
  const cancellationRate = getData(data1) ?? 0;
  const reclamationRate = getData(data2) ?? 0;
  const disputeComments = getData(data3) ?? 0;
  const countReservation = getData(data4) ?? 0;

  return (
    <Fragment>
      <Grid item xs={12} md={4} lg={4}>
        <MDBox mb={1.5}>
          {isLoading3 ? (
            <StatisticLoading />
          ) : (
            <ComplexStatisticsCard
              color="primary"
              icon={<HailIcon />}
              title="Number Of Booking"
              count={`${countReservation} bookings`}
              percentage={{
                color: "primary",
                label: inputs.dateValue ? inputs.dateValue : getToday(),
                amount: "Number of Booking for Status : "+
                  (inputs.status ? inputs.status : ""),
              }}
            />
          )}
        </MDBox>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <MDBox mb={1.5}>
          {isLoading ? (
            <StatisticLoading />
          ) : (
            <ComplexStatisticsCard
              color="success"
              icon={<SentimentVerySatisfiedIcon />}
              title="Satisfaction Rate"
              count={`${satisfactionRate}%`}
              percentage={{
                color: "success",
                label: inputs.dateValue ? inputs.dateValue : getToday(),
                amount: "Customer satisfaction rate",
              }}
            />
          )}
        </MDBox>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <MDBox mb={1.5}>
          {isLoading2 ? (
            <StatisticLoading />
          ) : (
            <ComplexStatisticsCard
              color="warning"
              icon={<FeedbackIcon />}
              title="Reclamation Rate"
              count={`${reclamationRate}%`}
              percentage={{
                color: "warning",
                label: inputs.dateValue ? inputs.dateValue : getToday(),
                amount: "Rate of reclamations",
              }}
            />
          )}
        </MDBox>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <MDBox mb={1.5}>
          {isLoading1 ? (
            <StatisticLoading />
          ) : (
            <ComplexStatisticsCard
              color="error"
              icon={<CancelIcon />}
              title="Cancellation Rate"
              count={`${cancellationRate}%`}
              percentage={{
                color: "error",
                label: inputs.dateValue ? inputs.dateValue : getToday(),
                amount:
                  "Rate of cancellations for Status: " +
                  (inputs.status ? inputs.status : ""),
              }}
            />
          )}
        </MDBox>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <MDBox mb={1.5}>
          {isLoading3 ? (
            <StatisticLoading />
          ) : (
            <ComplexStatisticsCard
              color="info"
              icon={<CommentIcon />}
              title="Dispute Comments"
              count={`${disputeComments} comments`}
              percentage={{
                color: "info",
                label: inputs.dateValue ? inputs.dateValue : getToday(),
                amount: "Number of dispute comments",
              }}
            />
          )}
        </MDBox>
      </Grid>
    </Fragment>
  );
};

export default ReservationIndicator;
