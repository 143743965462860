import React, { Fragment } from "react";
import useFetchData from "hooks/useFetchDataWithDep";
import { getData } from "helper/getData";
import generateSearchLink from "Services/generateSearchLink";
import NotFound from "Components/NotFound";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";import StatisticLoading from "Components/Loading/StatisticLoading";

const CountSalesRevenue = ({ inputs }) => {

  const searchItems = {
    date: inputs.dateValue,
  };

  console.log(inputs.dateValue);

  const url = generateSearchLink("/stat/sales/revenue", searchItems);
  const { data, isLoading, isError } = useFetchData(url, inputs);
  if (isError) return <NotFound />;

  const productStatus = getData(data) ?? [];

  const totalAmount =
    productStatus  > 0 ? productStatus : 0;
  
  return (
    <Fragment>
      {!isLoading && (
        <>
          <ComplexStatisticsCard
            color="info"
            icon={<MonetizationOnIcon />}
            title="Sales"
            count={`${totalAmount} TND`}
            percentage={{
              color: "success",
              label: inputs.dateValue ? inputs.dateValue : "2024",
              amount: "The Revenue of Sales for the date",
            }}
          />
        </>
      )}
      {isLoading && <StatisticLoading />}
    </Fragment>
  );
};

export default CountSalesRevenue;
