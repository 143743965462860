import { Fragment } from "react";
import Select from "react-select";
import { Col, FormGroup, Label } from "reactstrap";
import SelectSpinner from "../SelectSpinner";

import useFetchData from "hooks/useFetchData";
import { getData } from "helper/getData";

const SelectApiInput = ({ register, inputName, oldValue, isDisabled }) => {
  const { name, title, optionsApi, getOptions, extrOptions, required, width } =
    inputName;
  const { data, isLoading, isError } = useFetchData(optionsApi);

  let options = [];

  const handleOnChange = (e) => {
    register({ name, value: e.value });
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#fbdc10" : "#ccc",
      boxShadow: state.isFocused ? "0 0 0 0.2rem #fbdc10" : "none",
    }),
  };

  if (!isLoading && !isError) options = getOptions(getData(data));
  if (extrOptions) options.push(extrOptions);

  const defaultVal = getDefaultVal(oldValue, options);
  const isReq = required === undefined ? true : required;

  return (
    <Fragment>
      <Col sm={width ? width : "3"}>
        <FormGroup>
          <Label>
            {title} {isReq && <span className="text-danger">*</span>}
          </Label>
          {isLoading && (
            <Select
              isDisabled={isDisabled}
              styles={customStyles}
              onChange={handleOnChange}
              options={options}
              name={name}
              required={isReq}
              defaultValue={getDefaultVal(oldValue, options)}
              components={
                isLoading
                  ? {
                      DropdownIndicator: SelectSpinner,
                    }
                  : {}
              }
            />
          )}

          {!isLoading && (
            <Select
              isDisabled={isDisabled}
              styles={customStyles}
              onChange={handleOnChange}
              options={options}
              name={name}
              required={isReq}
              defaultValue={getDefaultVal(oldValue, options)}
              components={
                isLoading
                  ? {
                      DropdownIndicator: SelectSpinner,
                    }
                  : {}
              }
            />
          )}
        </FormGroup>
      </Col>
    </Fragment>
  );
};
export default SelectApiInput;

function getLabel(options, oldValue) {
  const option = options.find((option) => option.value === oldValue);
  return option ? option.label : "";
}

function getDefaultVal(oldValue, options) {
  return options.find((option) => option.value === oldValue) || null;
}
