import React, { Fragment } from "react";
import { Card, CardBody } from "reactstrap";

import DataTable from "Components/DataTables/DataTable";
import EditItem from "Components/EditItemWithZod";
import Breadcrumbs from "Components/Breadcrumbs";
import ModalApp from "Components/ModalApp";

import { CREATE_CLIENT, EDIT_CLIENT, MY_CLIENT } from "Constant";

import { API, COLUMNS, EDIT_ITEMS_CLIENT } from "./data";
import CreateClient from "./CreateClient";

const ClientPage = () => {
  const breadCrumbsProps = [MY_CLIENT];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTable
            newColumns={COLUMNS}
            link={API.list}
            columnSearch={false}
          />
        </CardBody>
      </Card>

      <ModalApp type="edit" title={EDIT_CLIENT}>
        <EditItem itemsUpdate={EDIT_ITEMS_CLIENT} newVal={{ active: true }} />
      </ModalApp>

      <ModalApp type="create" title={CREATE_CLIENT}>
        <CreateClient />
      </ModalApp>
    </Fragment>
  );
};

export default ClientPage;
