import React from "react";
import { Spinner } from "reactstrap";
import { Btn } from "../../AbstractElements";
import { useTranslation } from "react-i18next";

const LoadingBtn = ({ isLoading, content, attrBtn }) => {
  const { t } = useTranslation();

  const done =
    typeof content.done === "string" ? t(content.done) : content.done;

  return (
    <Btn attrBtn={{ ...attrBtn, disabled: isLoading }}>
      {isLoading ? (
        <div style={{ display: "flex", gap: 2 }}>
          <Spinner size="sm" />
          <span> {t(content.loading)}</span>
        </div>
      ) : (
        <span> {done}</span>
      )}
    </Btn>
  );
};

export default LoadingBtn;
