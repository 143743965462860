import React, { useState, Fragment, useContext } from "react";
import { AlignCenter } from "react-feather";
import { Image } from "../../AbstractElements";
import CheckContext from "../../helperContext/Customizer";

const Leftbar = () => {
  const { toggleSidebar } = useContext(CheckContext);
  const [toggle, setToggle] = useState(false);

  const openCloseSidebar = () => {
    setToggle(!toggle);
    toggleSidebar(!toggle);
  };

  return (
    <Fragment>
      <div className="main-header-left">
        <Image
          attrImage={{
            className: "img-fluid d-inline",
            src: `${require("../../assets/images/logo/logo.png")}`,
            alt: "",
          }}
        />
        <div className="toggle-sidebar" onClick={() => openCloseSidebar()}>
          <AlignCenter className="status_toggle middle" id="sidebar-toggle" />
        </div>
      </div>
    </Fragment>
  );
};

export default Leftbar;
