import React, { Fragment, useRef } from "react";
import { useParams } from "react-router-dom";
import { Card } from "reactstrap";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import useFetchData from "../../hooks/useFetchData";
import Loading from "../../Components/Loading/Loading";
import DownloadBtn from "../../Components/DownloadBtn";
import Header from "../../Components/Header/Header";
import NotFound from "../../Components/NotFound";

import { isArrayNotEmpty } from "../../helper/testData";
import StaticDataTable from "../../Components/DataTables/StaticDataTable";

const ReadRMInventory = () => {
  const { code } = useParams();
  const printRef = useRef();
  const requiredKeys = [
    { key: "invNumber", name: "InvNumber" },
    { key: "status", name: "Status" },
    { key: "createdAt", name: "Created At" },
    { key: "creator", name: "Creator" },
  ];
  const downloadableFileStyle = {
    // backgroundColor: "white",
    padding: "10px",
    borderRadius: "8px",
  };
  const ITEMS_COLUMNS = [
    { name: "amount", selector: "amount" },
    { name: "model", selector: "model" },
    { name: "created_at", selector: "created_at" },
  ];

  const { data, isLoading, isError } = useFetchData(
    `/back/rawmaterialinventory/${code}`
  );

  const handleDownloadPdf = async () => {
    const element = printRef.current;

    // Capture the HTML content with its exact dimensions
    const canvas = await html2canvas(element, {
      width: element.clientWidth,
      height: element.clientHeight,
    });
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("print.pdf");
  };

  if (isLoading) return <Loading />;

  if (isError) return <NotFound />;

  if (!isLoading && !isError) {
    const itemData = data.data.results.data;
    const { items } = itemData;

    return (
      <Fragment>
        <div ref={printRef} style={{ padding: "20px" }}>
          <Card style={downloadableFileStyle}>
            <Header objectData={itemData} requiredKeys={requiredKeys} />

            {isArrayNotEmpty(items) && (
              <div>
                <label
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "26px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <span style={{ textDecoration: "underline" }}> items </span>
                </label>
                <StaticDataTable newColumns={ITEMS_COLUMNS} newRows={items} />
              </div>
            )}
          </Card>
        </div>
        <DownloadBtn onChange={handleDownloadPdf} />
      </Fragment>
    );
  }
};
export default ReadRMInventory;
