import React, { Fragment } from "react";

import { Chip, Group, List, ThemeIcon, rem } from "@mantine/core";
import { IconCircleCheck, IconCircleDashed, IconX } from "@tabler/icons-react";
import { useSelector } from "react-redux";

const FinalStep = () => {
  const { client, taxi, type, products } = useSelector((state) => state.sales);

  function areAllProductsFilled() {
    return products.every(
      (product) => product.category && product.codes && product.codes.length > 0
    );
  }

  const arrayOfSteps = [
    {
      title: "Client",
      value: client,
    },
    {
      title: "Taxi",
      value: taxi,
    },
    {
      title: "Type",
      value: type,
    },
    {
      title: "Products",
      value: areAllProductsFilled(),
    },
  ];

  const doneIcon = (
    <ThemeIcon color="teal" size={24} radius="xl">
      <IconCircleCheck style={{ width: rem(16), height: rem(16) }} />
    </ThemeIcon>
  );

  const notDoneIcon = (
    <ThemeIcon color="blue" size={24} radius="xl">
      <IconCircleDashed style={{ width: rem(16), height: rem(16) }} />
    </ThemeIcon>
  );

  return (
    <Group justify="left" mt="md">
      <Chip.Group multiple>
        {arrayOfSteps.map((elem) => {
          const { title, value } = elem;
          const res = value
            ? {
                title: `${title}`,
                chipProps: {
                  color: "green",
                  checked: true,
                },
              }
            : {
                title: `${title}`,
                chipProps: {
                  color: "red",
                  checked: true,
                  icon: <IconX style={{ width: rem(16), height: rem(16) }} />,
                },
              };
          return (
            <Chip
              checked={res.chipProps.checked}
              color={res.chipProps.color}
              icon={res.chipProps.icon}
            >
              {res.title}
            </Chip>
          );
        })}
      </Chip.Group>
    </Group>
  );
};

export default FinalStep;
