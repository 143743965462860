import React, { Fragment, useState } from "react";
import { Card, CardBody } from "react-bootstrap";
import { Divider, SegmentedControl, Space } from "@mantine/core";

import DeleteRequests from "./DeleteRequests";
import HistoryRequests from "./HistoryRequests";

import {
  HISTORY_REQUESTS,
  MY_RGPD,
  PRIVACY_POLICY,
  REMOVED_REQUESTS,
  TERM_CONDITION,
} from "Constant";
import Breadcrumbs from "Components/Breadcrumbs";
import TermCondition from "./TermCondition";
import PrivacyPolicy from "./PrivacyPolicy";

const RGPDPage = () => {
  const [state, setState] = useState("term_condition");

  const breadCrumbsProps = [MY_RGPD];

  const componants = {
    term_condition: <TermCondition />,
    privacy_policy: <PrivacyPolicy />,
    removed_requests: <DeleteRequests />,
    history_requests: <HistoryRequests />,
  };

  return (
    <Fragment>
      <div className="card">
        <Card>
          <CardBody>
            <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

            <SegmentedControl
              transitionDuration={200}
              transitionTimingFunction="linear"
              color="yellow"
              fullWidth
              size="md"
              value={state}
              onChange={setState}
              data={[
                { label: TERM_CONDITION, value: "term_condition" },
                { label: PRIVACY_POLICY, value: "privacy_policy" },
                { label: REMOVED_REQUESTS, value: "removed_requests" },
                { label: HISTORY_REQUESTS, value: "history_requests" },
              ]}
            />

            <Divider my="md" />

            {componants[state]}
          </CardBody>
        </Card>
      </div>
    </Fragment>
  );
};

export default RGPDPage;
