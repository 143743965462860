import ClientPage from "pages/ClientPage";
// import ReadClientPage from "pages/ReadClientPage";
import ReadClientPage from "pages/ReadClientPage.test";
import ReadClientTaxiPage from "pages/ReadClientTaxiPage";

import BillPage from "pages/BillPage";
import ReadBillPage from "pages/ReadBillPage";
import CreateBillPage from "pages/CreateBillPage";

import CollaboratorPage from "pages/CollaboratorPage";
import ReadCollaborator from "pages/ReadCollaborator";

import SalesVouchersPage from "pages/SalesVouchersPage";

import EstimatePage from "pages/EstimatePage";
import EditEstimatePage from "pages/EditEstimate";
import CreateEstimatePage from "pages/CreateEstimatePage";
import ReadEstimatePage from "pages/ReadEstimatePage/index.test";

import DeliveryFormPage from "pages/DeliveryFormPage";
import ReadDeliveryFormPage from "pages/ReadDeliveryFormPage";

import DistributorPage from "pages/DistributorPage";
import ReadDistributorPage from "pages/ReadDistributorPage";

import LeadPage from "pages/LeadPage";
import ReadLeadsPage from "pages/ReadLeadsPage";
import PurchaseOrderPage from "pages/PurchaseOrderPage";

import ReadService from "pages/ReadService";
import ServicesListPage from "pages/ServicesListPage";

import PricingServicePage from "pages/PricingPages/PricingServicePage";
import PricingProductPage from "pages/PricingPages/PricingProductPage";
import ReadPricingServicePage from "pages/ReadPricingServicePage";
import ReadPricingProductPage from "pages/ReadPricingProductPage";

const COMMERCIAL_ROUTES = [
  {
    path: "/client",
    element: <ClientPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/client/:code",
    element: <ReadClientPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/client/taxi/:code",
    element: <ReadClientTaxiPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },

  {
    path: "/collaborator",
    element: <CollaboratorPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/collaborator/:code",
    element: <ReadCollaborator />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },

  {
    path: "/distributor",
    element: <DistributorPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/distributor/:code",
    element: <ReadDistributorPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },

  {
    path: "/purchase-order",
    element: <PurchaseOrderPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },

  // delivary form
  {
    path: "/delivery-form",
    element: <DeliveryFormPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/delivery-form/:code",
    element: <ReadDeliveryFormPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },

  // estimate
  {
    path: "/estimate",
    element: <EstimatePage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/estimate/:code",
    element: <ReadEstimatePage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/create-estimate",
    element: <CreateEstimatePage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/edit-estimate/:code",
    element: <EditEstimatePage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },

  // bill
  {
    path: "/bill",
    element: <BillPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/bill/:code",
    element: <ReadBillPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/create-bill/:code",
    element: <CreateBillPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },

  {
    path: "/leads",
    element: <LeadPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },

  // lead
  {
    path: "/leads/:code",
    element: <ReadLeadsPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },

  // services
  {
    path: "/services-list",
    element: <ServicesListPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/services-list/:code",
    element: <ReadService />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },

  {
    path: "/sales-vouchers",
    element: <SalesVouchersPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },

  // pricing
  {
    path: "/pricing-services",
    element: <PricingServicePage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/pricing-services/:code",
    element: <ReadPricingServicePage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/pricing-product",
    element: <PricingProductPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
  {
    path: "/pricing-product/:code",
    element: <ReadPricingProductPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },
];

export default COMMERCIAL_ROUTES;
