import React, { useEffect, useState } from "react";

import { Group } from "@mantine/core";
import { getCodeLabel } from "helper/getOptions";
import onChangeInput from "helper/onChangeInput";
import MultiSelectApiInput from "Components/Inputs/MultiSelectApiInput";
import { useDispatch } from "react-redux";
import { editProductForSales } from "../../redux/salesSlicer";

const ProdData = ({ index }) => {
  const [inputs, setInputs] = useState({});
  const dispatch = useDispatch();

  const onChange = (e) => {
    const newVal = onChangeInput(e);

    setInputs((prevState) => ({ ...prevState, ...newVal }));
  };

  const codes = getCodesFromInputs(inputs);

  const result = {
    category: inputs.category,
    codes: codes,
  };

  useEffect(() => {
    const param = { data: result, index: index };
    dispatch(editProductForSales(param));
  }, [inputs]);

  return (
    <Group justify="center">
      <MultiSelectApiInput
        register={onChange}
        inputName={{
          title: "Category",
          name: "category",
          inputForm: "MultiSelectApiInput",
          optionsApi: "/back/inventory-categories",
          getOptions: getCodeLabel,
          secondInp: "isMulti",
          secondSelect: (param) => {
            return {
              title: "For sales",
              name: "codes",
              optionsApi: `back/${
                param[param.length - 1] === "s" ? param + "es" : param + "s"
              }/forsale`,
              getOptions: getCodeLabel,
            };
          },
        }}
      />
    </Group>
  );
};

export default ProdData;

const getCodesFromInputs = (param) => {
  try {
    const codes = param.codes;
    if (!codes) return [];
    const res = codes.map((elem) => elem.value);
    return res;
  } catch {
    return [];
  }
};
