import React from "react";
import styled from "styled-components";
import TableDataBase from "../../Components/TableDatabase";
import { useSelector } from "react-redux";

const PageWrapperTable = styled.div`
  margin: 10px;
  margin-bottom: 30px;
  width: ${({ $sidebarVisible }) => ($sidebarVisible ? "73%" : "94.3%")};
  @media (max-width: 768px) {
    width: ${({ $sidebarVisible }) => ($sidebarVisible ? "64%" : "85%")};
  }
`;
const TableDataBasePage = () => {
  const sidebarVisible = useSelector(
    (state) => state.requeteSql.sidebarVisible
  );

  return (
    <Wrapper>
      <PageWrapperTable $sidebarVisible={sidebarVisible}>
        <TableDataBase />
      </PageWrapperTable>
      {/*<Sidebar />*/}
    </Wrapper>
  );
};

export default TableDataBasePage;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
