import WorkOrdersPage from "../pages/WorkOrdersPage";
import ReadWorkOrder from "../pages/ReadWorkOrder";
import PrimitivesPage from "pages/PrimitivesPage";

const PRODUCTION_ROUTES = [
  {
    path: "/work-order",
    element: <WorkOrdersPage />,
    roles: [
      "SUPER_ADMIN",
      "AGENT_PRODUCTION",
      "AGENT_PCB_CONTROL",
      "AGENT_SOFTWARE_CONTROL",
      "AGENT_PACKAGING",
      "ADMIN_PRODUCTION",
      "ADMIN_STOCK_PRODUCTION",
    ],
  },

  {
    path: "/work-order/:code",
    element: <ReadWorkOrder />,
    roles: [
      "SUPER_ADMIN",
      "AGENT_PRODUCTION",
      "AGENT_PCB_CONTROL",
      "AGENT_SOFTWARE_CONTROL",
      "AGENT_PACKAGING",
      "ADMIN_PRODUCTION",
      "ADMIN_STOCK_PRODUCTION",
    ],
  },

  {
    path: "/primitives",
    element: <PrimitivesPage />,
    roles: [
      "SUPER_ADMIN",
      "AGENT_PRODUCTION",
      "AGENT_PCB_CONTROL",
      "AGENT_SOFTWARE_CONTROL",
      "AGENT_PACKAGING",
      "ADMIN_PRODUCTION",
      "ADMIN_STOCK_PRODUCTION",
    ],
  },
];

export default PRODUCTION_ROUTES;
