import ActiveBtn from "../../Components/DataTableBtns/ActiveBtn";

// Urls
export const API = {
  list: (code) => `/back/parameter/${code}/values`,
  create: (code) => `/back/parameter/${code}/value`,
  patch: (code) => `/back/parameter/${code}`,
  get: "/back/parameter/",
};

// Columns
export const COLUMNS = [
  { name: "Value", selector: "value" },

  { name: "Date begin", selector: "date_begin" },
  { name: "Date end", selector: "date_end" },

  { name: "Created at", selector: "created_at" },
  {
    name: "Active",
    cell: (row) => <ActiveBtn patch={API.patch(row.code)} state={row.active} />,
    button: "true",
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Value",
    placeholder: "value",
    name: "value",
    inputForm: "SimpleInput",
  },
  {
    title: "Date Begin",
    placeholder: "date_begin",
    name: "date_begin",
    inputForm: "DateInput",
  },
];

export const EDIT_ITEMS = [
  {
    title: "Value",
    placeholder: "value",
    name: "value",
    inputForm: "SimpleInput",
  },
  {
    title: "Date Begin",
    placeholder: "date_begin",
    name: "date_begin",
    inputForm: "DateInput",
  },
];
