import { Dropdown } from "primereact/dropdown";
import React from "react";
import patchItem from "Services/patchItem";

const Badge = ({ badgesList, currenctBadge, driverCode }) => {
  const serialNumber = extractSerialNumber(currenctBadge);
  const options = transformTable(badgesList);
  const currenctCode = getCurrentBadge(serialNumber, options);

  const handleChage = (event) => {
    const unavailableBadge = `/back/user/${driverCode}/badge`;

    if (event.value) {
      const url = `/back/user/${driverCode}/badge/${event.value}`;
      patchItem(url);
    } else patchItem(unavailableBadge);
  };

  return (
    <Dropdown
      value={currenctCode}
      onChange={handleChage}
      options={options}
      optionLabel="label"
      showClear
      placeholder="Select a badge"
      className="w-full md:w-14rem"
      size={5}
    />
  );
};

export default Badge;

function extractSerialNumber(str) {
  const regex = /BG\d{10}/; // This pattern matches "BG" followed by 10 digits
  const match = str.match(regex);
  return match ? match[0] : null; // Return the matched serial number or null if not found
}

function transformTable(data) {
  try {
    return data.map((item) => ({
      value: item.code,
      label: item.serial_number,
    }));
  } catch (error) {
    console.error("Error transforming table:", error);
    return [];
  }
}

function getCurrentBadge(label, options) {
  try {
    const badge = options.find((item) => item.label === label);
    return badge ? badge.value : null; // Return the code if found, otherwise return null
  } catch (error) {
    console.error("Error getting the current badge:", error);
    return null;
  }
}
