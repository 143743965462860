import React from "react";
import { Edit } from "react-feather";

import { useDispatch } from "react-redux";

import { changeModalType, changeTitle } from "../../redux/modal";
import { changeCurrentRow } from "../../redux/currentRow";
import { Btn } from "../../AbstractElements";

const EditBtn = ({ read, edit, extra }) => {
  const dispatch = useDispatch();

  const handleEdit = () => {
    const selectedRow = {
      getLink: read || edit,
      putLink: edit || read,
    };
    dispatch(changeModalType("edit"));
    dispatch(changeTitle(extra));
    dispatch(changeCurrentRow(selectedRow));
  };

  return (
    <Btn
      attrBtn={{
        onClick: handleEdit,
        color: "info",
        variant: "contained",
        className: "btn btn-secondary btn-sm",
      }}
    >
      <Edit />
    </Btn>
  );
};

export default EditBtn;
