import React, { Fragment } from "react";

import useFetchData from "../../hooks/useFetchData";
import Loading from "../../Components/Loading/Loading";
import NotFound from "../../Components/NotFound";
import DataTableHeader from "../../Components/DataTableHeader";
import DataTables from "../../Components/DataTables";
import { API, COLUMNS_BOXES } from "./data";

const Boxes = ({ code }) => {
  let profileData;
  const { data, isLoading, isError } = useFetchData(
    "/back/taximeter/" + code + "/boxes"
  );



  if (isLoading) return <Loading />;

  if (isError) return <NotFound />;

  if (!isLoading && !isError) {
    profileData = data.data.results.data;
  }
  const list = API.list(code) + "/boxes";

  return (
    <Fragment>
      <DataTableHeader />
      <DataTables newColumns={COLUMNS_BOXES} link={list} />
    </Fragment>
  );
};

export default Boxes;
