import { CATEGORY, LABEL } from "Constant";
import ActiveBtn from "../../Components/DataTableBtns/ActiveBtn";
import EditBtn from "../../Components/DataTableBtns/EditBtn";
import ReadPageBtn from "../../Components/DataTableBtns/ReadPageBtn";
import { getCodeLabel } from "../../helper/getOptions";

// Urls
export const API = {
  list: (model) => `/back/brand/${model}/models`,
  // GET_CATEGORIES: "/back/categories",
  // GET_MODELS_CHOICE: "/back/models/choices/TAXIMETER",
  // IMPORT_MODELS: "/back/models",
  edit: "/back/model/",
  create: "/back/brand/taxiora/model",
};

// Columns
export const COLUMNS = [
  {
    name: "Label",
    selector: "label",
  },
  {
    name: "Category",
    selector: "category",
  },
  { name: "Created at", selector: "created_at" },

  {
    name: "Active",
    cell: (row) => <ActiveBtn patch={API.edit + row.code} state={row.active} />,
    button: "true",
  },
  {
    name: "Read",
    cell: (row) => <ReadPageBtn read={`/model/${row.code}`} />,
    button: "true",
  },
  {
    name: "Edit",
    cell: (row) => <EditBtn read={API.edit + row.code} extra={row.label} />,
    button: "true",
  },
];

// Search
export const SEARCH_ITEMS = [
  // {
  //   title: "Brand",
  //   name: "brand",
  //   inputForm: "SelectApiInput",
  //   optionsApi: "/back/brands/choices",
  //   getOptions: getCodeLabel,
  // },
];

// Form
export const CREATE_ITEMS = [
  {
    title: LABEL,
    placeholder: "label",
    name: "label",
    inputForm: "SimpleInput",
  },
  {
    title: CATEGORY,
    name: "category",
    inputForm: "SelectApiInput",
    optionsApi: "/back/categories",
    getOptions: getCodeLabel,
  },
];

export const EDIT_ITEMS = [
  {
    title: "label",
    placeholder: "label",
    name: "label",
    inputForm: "SimpleInput",
  },
  {
    title: CATEGORY,
    name: "category",
    inputForm: "SelectApiInput",
    optionsApi: "/back/categories",
    getOptions: getCodeLabel,
  },
];
