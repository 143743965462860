import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { MRT_EditActionButtons } from "material-react-table";
import React from "react";

const CreateRowDialogContent = ({ table, row, internalEditComponents }) => {
  return (
    <>
      <DialogTitle variant="h3">Create New User</DialogTitle>

      <DialogContent
        sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
      >
        {internalEditComponents}
      </DialogContent>
      <DialogActions>
        <MRT_EditActionButtons variant="text" table={table} row={row} />
      </DialogActions>
    </>
  );
};

export default CreateRowDialogContent;
