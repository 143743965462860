import React, { Fragment, useRef } from "react";
import { useReactToPrint } from "react-to-print";

import { useSelector } from "react-redux";
import logo from "../../assets/images/logo/logoV2.png";
import { Card, CardBody } from "reactstrap";

import styles from "./style.module.css";
import PrintBtn from "../../Components/PrintBtn";

const PdfCart = () => {
  const { category, model, amount } = useSelector(
    (state) => state.currentRow.rowMaterialStock
  );

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const currentDate = new Date().toLocaleDateString();
  return (
    <Fragment>
      <div ref={componentRef} className={styles["pdf-cart"]}>
        <Card className={styles["custom-card"]}>
          <CardBody className={styles["custom-card-body"]}>
            <div className={styles["logo-container"]}>
              <img src={logo} className={styles["logo"]} alt="logo" />
            </div>
            <div className={styles["data"]}>
              <div className={styles["data-item"]}>
                <strong>Category:</strong> {category}
              </div>
              <div className={styles["data-item"]}>
                <strong>Model:</strong> {model}
              </div>
              <div className={styles["data-item"]}>
                <strong>Quantity:</strong> {amount}
              </div>

              <div className={styles["data-item"]}>
                <strong>Date:</strong> {currentDate}
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
      <div className={styles["print-button"]}>
        <PrintBtn onClick={handlePrint} />
      </div>
    </Fragment>
  );
};

export default PdfCart;
