import React from "react";
import styles from "./SelectSpinner.module.css";
const SelectSpinner = () => {
  return (
    <svg className={styles["loading-svg"]} viewBox="25 25 50 50">
      <circle
        className={styles["loading-circle"]}
        r="20"
        cy="50"
        cx="50"></circle>
    </svg>
  );
};

export default SelectSpinner;
