import { ShowErrorMsg, addAlert } from "Components/Alerts/Alerts";
import axiosClient from "Services/axiosClient";
import errorMsg from "Services/errorMsg";

const onSubmit = async (newData, url, changeLoadingState) => {
  const submitedData = {
    civility: newData.civility,
    firstname: newData.firstname,
    lastname: newData.lastname,
    company: newData.company,
    registry: newData.registry,
    phone: newData.phone,
    mail: newData.mail,
    address: newData.address,
    zip_code: newData.zip_code,
    city: newData.city,
    pro_card: newData.pro_card,
    pro_card_got_at: newData.pro_card_got_at,
    pro_card_expire_at: newData.pro_card_expire_at,
    birthDate: newData.birthDate,
    taxi: {
      model: newData.model,
      profile: newData.profile,
      places: newData.places,
      licenceNumber: newData.licenceNumber,
      serialNumber: newData.serialNumber,
      registrationNumber: newData.registrationNumber,
      licenceGotAt: newData.licenceGotAt,
      licenceExpireAt: newData.licenceExpireAt,
      circulationDate: newData.circulationDate,
    },
  };

  await axiosClient
    .post(url, submitedData)
    .then((res) => {
      addAlert();
      const href = `/client/${res.data.results.data.client}`;
      setTimeout(() => {
        window.location.href = href;
      }, 1000);
    })
    .catch((error) => {
      errorMsg(error);
    });
  if (changeLoadingState) changeLoadingState(false);
};

export default onSubmit;
