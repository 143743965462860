import BooleanIcon from "../../../Components/DataTableBtns/BooleanIcon";

// Urls
export const API = {
  list: "/back/passengers/requests/history",
};

// Columns
export const COLUMNS = [
  { name: "Number", selector: "p_alias" },
  { name: "Passenger", selector: "passenger" },
  {
    id: "treated",
    header: "Treated",
    Cell: (row) => <BooleanIcon state={row.required} />,
    size: 50,
  },

  { name: "Created at", selector: "created_at" },
];
