import { Group } from "@mantine/core";
import ActiveBtn from "../../Components/MantineDataTableBtns/ActiveBtn";
import ReadPageBtn from "../../Components/MantineDataTableBtns/ReadPageBtn";

// Urls
export const API = {
  list: "/back/services",
  patch: "/back/service/",
  create: "/back/service",
};

// Columns
export const COLUMNS = [
  { name: "Label", selector: "label" },
  { name: "Created at", selector: "created_at" },

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 10,
    Cell: ({ row }) => {
      const { code, active, label } = row.original;
      return (
        <Group gap="xs">
          <ActiveBtn patch={API.patch + code} state={active} />
          {/* <ReadPageBtn read={`/services-list/${code}`} /> */}
        </Group>
      );
    },
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Code",
    placeholder: "Code",
    name: "code",
    inputForm: "SimpleInput",
  },
  {
    title: "Label",
    placeholder: "Label",
    name: "label",
    inputForm: "SimpleInput",
  },
  {
    title: "Required",
    name: "required",
    inputForm: "SelectInput",
    options: [
      {
        value: "true",
        label: "required",
        name: "required",
      },
      {
        value: "false",
        label: "not required",
        name: "required",
      },
    ],
  },
];

export const EDIT_ITEMS = [
  {
    title: "Code",
    placeholder: "Code",
    name: "code",
    inputForm: "SimpleInput",
  },
  {
    title: "Label",
    placeholder: "Label",
    name: "label",
    inputForm: "SimpleInput",
  },
  {
    title: "Required",
    name: "required",
    inputForm: "SelectInput",
    options: [
      {
        value: "true",
        label: "required",
        name: "required",
      },
      {
        value: "false",
        label: "not required",
        name: "required",
      },
    ],
  },
];
