import React from "react";
import { useParams } from "react-router-dom";

import useFetchData from "hooks/useFetchData";

import Badge from "./Badge";
import { Skeleton } from "primereact/skeleton";

const ChangeBadge = ({ data: currenctBadge, driverCode }) => {
  const { code } = useParams();

  const url = `/back/client/${code}/badges`;
  const { data, isLoading, isError } = useFetchData(url);

  const badgesList = getBadges(data);

  if (isLoading) return <Skeleton width="5rem" className="mb-2"></Skeleton>;

  if (isError) return null;

  return (
    <>
      <Badge
        badgesList={badgesList}
        currenctBadge={currenctBadge}
        driverCode={driverCode}
      />
    </>
  );
};

export default ChangeBadge;

const getBadges = (data) => {
  try {
    return data.data.results.data;
  } catch {
    return [];
  }
};
