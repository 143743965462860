import { useState, Fragment } from "react";
import { Form, ModalFooter, Row } from "reactstrap";

import FilterInput from "../Inputs";
import postData from "./postData";

import handleInputs from "../../helper/handleInputs";
import { toggleRefresh } from "../../redux/searchLink";
import { toggleModal } from "../../redux/modal";
import { useDispatch } from "react-redux";
import { Divider } from "@mantine/core";
import { Button } from "primereact/button";

import CancelModalBtn from "Components/ModalApp/CancelModalBtn";

const AddItem = ({ addURL, items, defVal }) => {
  const defInp = defVal ? defVal : {};
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState(defInp);
  const [isLoading, setIsLoading] = useState(false);

  const addedWithSuccess = () => {
    dispatch(toggleRefresh());
    dispatch(toggleModal());
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    postData(inputs, addURL, setIsLoading, addedWithSuccess);
  };

  return (
    <Fragment>
      <Form onSubmit={(e) => onSubmit(e)}>
        <Row>
          {items.map((item, index) => (
            <FilterInput
              key={index}
              item={item}
              handleChange={(e) => handleInputs(e, setInputs)}
              index={index}
              oldValue={defVal ? defVal[item.name] : null}
            />
          ))}
        </Row>
        <Divider my="md" />
        <ModalFooter>
          <CancelModalBtn />
          <Button
            type="submit"
            label="Go"
            severity="success"
            loading={isLoading}
            onClick={() => onSubmit}
          />
        </ModalFooter>
      </Form>
    </Fragment>
  );
};

export default AddItem;
