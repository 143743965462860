// @mui material components
import Tooltip from "@mui/material/Tooltip";


import MDBox from "Components/MDBox";
import MDTypography from "Components/MDTypography";
import { Avatar } from "@mantine/core";
import CommentIcon from "@mui/icons-material/Comment";
import DriveEtaIcon from "@mui/icons-material/DriveEta";

export default function Details({ inputs, fetchData, isLoading, isError }) {
  const avatars = (members) =>
    members.map(([name]) => (
      <Tooltip key={name} title={name} placement="bottom">
        <Avatar radius="xl" alt={name} color="red" />
      </Tooltip>
    ));

  const Company = ({ name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <CommentIcon name={name} fontSize="meduim" color="success" />
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  const columns = [
    {
      Header: "Commentary",
      accessor: "commentary",
      width: "45%",
      align: "left",
    },
    { Header: "Name", accessor: "name", width: "10%", align: "left" },
    { Header: "Phone", accessor: "passenger_phone", align: "center" },
    {
      Header: "Booking",
      accessor: "departure_address",
      align: "center",
    },
  ];

  const rows = fetchData.map((data, index) => ({
    commentary: <Company index={index} name={data.commentary} />,
    name: (
      <MDBox display="flex" py={1}>
        {avatars([[`${data.passenger_firstname} ${data.passenger_lastname}`]])}
      </MDBox>
    ),
    passenger_phone: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {data.passenger_phone}
      </MDTypography>
    ),
    departure_address: (
      <MDBox width="20rem" textAlign="center">
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {data.departure_address}
        </MDTypography>
        {"    "}
        <DriveEtaIcon color="warning" fontSize="inherit" sx={{ mx: 2 }} />
        {"    "}
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {data.arrival_address}
        </MDTypography>
      </MDBox>
    ),
  }));

  return { columns, rows };
}
