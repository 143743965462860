import CartBtn from "../../Components/DataTableBtns/CartBtn";
import PdfBtn from "../../Components/DataTableBtns/PdfBtn";
import { fullName } from "../../helper/fullName";
import { getCodeLabel, getOptionNumber } from "../../helper/getOptions";

// Urls
export const API = {
  create: "/back/rawmaterial",
  get: "/back/rawmaterial/categories",
  list: "/back/rawmaterials",
};

// Columns
export const COLUMNS = [
  { name: "Category", selector: "category" },
  { name: "Model", selector: "model" },
  { name: "Quantity", selector: "amount" },
  { name: "Quantity used", selector: "amount_used" },
  { name: "Created at", selector: "created_at" },

  {
    name: "PDF",
    cell: (row) => (
      <PdfBtn row={row} extra={fullName(row.category, row.model)} />
    ),
    button: "true",
  },
  {
    name: "Purchase order",
    cell: (row) => <CartBtn row={row} />,
    button: "true",
  },
];

// Search
export const SEARCH_ITEMS = [
  {
    title: "Category",
    name: "category",
    inputForm: "SelectApiInput",
    optionsApi: "/back/rawmaterial/categories",
    getOptions: getCodeLabel,
  },
];
// Form
export const CREATE_ITEMS = [
  {
    title: "Amount",
    name: "amount",
    inputForm: "SelectInput",
    options: getOptionNumber(1, 10000),
    number: true,
  },

  {
    title: "Category",
    name: "category",
    inputForm: "MultiSelectApiInput",
    optionsApi: "/back/rawmaterial/categories",
    getOptions: getCodeLabel,
    secondSelect: (param) => {
      return {
        title: "Model",
        name: "model",
        optionsApi: `/back/rawmaterial/${param}/models`,
        getOptions: getCodeLabel,
      };
    },
  },
];

export const EDIT_ITEMS = [
  {
    title: "Amount",
    name: "amount",
    inputForm: "SimpleInput",
  },
  {
    title: "Category",
    name: "category",
    inputForm: "MultiSelectApiInput",
    optionsApi: "/back/rawmaterial/categories",
    getOptions: getCodeLabel,
  },
];

export const CART_ITEMS = [
  {
    title: "Quantity",
    placeholder: "quantity",
    name: "quantity",
    inputForm: "SimpleInput",
  },
];
