import { Group } from "@mantine/core";
import { activeObj } from "../../Constant/objs";
import { getOptionNumber } from "../../helper/getOptions";
import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";
import EditBtn from "Components/MantineDataTableBtns/EditBtn";

// Urls
export const API = {
  list: "/back/holidays",
  get: "/back/holiday",
  create: "/back/holiday",
  delete: "/back/holiday/",
};

// Columns
export const COLUMNS = [
  {
    name: "Label",
    selector: "label",
  },
  {
    name: "Date",
    selector: "h_date",
  },

  {
    name: "Year",
    selector: "year",
  },

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 70,
    Cell: ({ row }) => {
      console.log();

      const { code, active, label } = row.original;
      return (
        <Group gap="xs">
          <ActiveBtn patch={API.get + "/" + row.code} state={active} />
          <EditBtn read={API.get + "/" + code} extra={label} />
        </Group>
      );
    },
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Label",
    placeholder: "Label",
    name: "label",
    inputForm: "SimpleInput",
  },

  {
    title: "Year",
    name: "year",
    inputForm: "SelectInput",
    options: getOptionNumber(2024, 2034),
    number: true,
  },

  {
    title: "Date",
    placeholder: "Date",
    name: "h_date",
    inputForm: "DateInput",
  },
];

export const EDIT_ITEMS = [
  {
    title: "Label",

    placeholder: "Label",
    name: "label",
    inputForm: "SimpleInput",
  },
  {
    title: "Date",
    placeholder: "Date",
    name: "h_date",
    inputForm: "DateInput",
  },
  {
    title: "Year",
    name: "year",
    inputForm: "SelectInput",
    options: getOptionNumber(2024, 2034),
    number: true,
  },
];
