import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import useFetchData from "hooks/useFetchDataWithDep";
import L from "leaflet";
import { getData } from "helper/getData";
import generateSearchLink from "Services/generateSearchLink";
import NotFound from "Components/NotFound";
import { Card, CardBody } from "reactstrap";
import FilterByCategoryModel from "pages/StatStockPage/FilterByCategoryModel";
import StatisticLoading from "Components/Loading/StatisticLoading";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
});

const isValidLatLng = (lat, lng) => {
  return lat !== null && lng !== null && !isNaN(lat) && !isNaN(lng);
};

const createCustomIcon = (color) => {
  return L.divIcon({
    className: "custom-icon",
    html: `<div style="background-color:${color}; width: 12px; height: 12px; border-radius: 50%;"></div>`,
  });
};

const MapChart = () => {
  const [inputs, setInputs] = useState({
    status: "",
    dateValue: "",
    viewMode: "both",
  });

  const params = {
    date: true,
    statusBooking: true,
    bookingChoices:true,
  };

  const handleChange = (event) => {
    setInputs((prev) => ({
      ...prev,
      viewMode: event.target.value,
    }));
  };

  const searchItems = {
    date: inputs.dateValue,
    status: inputs.status,
  };

  const url = generateSearchLink(
    `/stat/reservation-client/reservation-chart`,
    searchItems
  );

  const { data, isLoading, isError } = useFetchData(url, inputs);

  if (isError) {
    return <NotFound />;
  }

  const reservations = getData(data) ?? [];

  return (
    <>
      <Card>
        <CardBody>
          <h4>Booking Map</h4>
          <FilterByCategoryModel
            params={params}
            inputs={inputs}
            setInputs={setInputs}
            handleChange={handleChange}
          />
          {isLoading ? (
            <StatisticLoading />
          ) : (
            <MapContainer
              center={[0, 0]}
              zoom={5}
              scrollWheelZoom={true}
              style={{ height: "50vh", width: "100%" }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              {reservations.map((reservation, index) => {
                const departureLat = parseFloat(reservation.departure_lat);
                const departureLng = parseFloat(reservation.departure_lng);
                const arrivalLat = parseFloat(reservation.arrival_lat);
                const arrivalLng = parseFloat(reservation.arrival_lng);

                const departureMarker = isValidLatLng(
                  departureLat,
                  departureLng
                ) && (
                  <Marker
                    key={`departure-${index}`}
                    position={[departureLat, departureLng]}
                    icon={createCustomIcon("blue")}
                  >
                    <Popup>
                      Departure: {reservation.departure_lat},{" "}
                      {reservation.departure_lng}
                    </Popup>
                  </Marker>
                );

                const arrivalMarker = isValidLatLng(arrivalLat, arrivalLng) && (
                  <Marker
                    key={`arrival-${index}`}
                    position={[arrivalLat, arrivalLng]}
                    icon={createCustomIcon("red")}
                  >
                    <Popup>
                      Arrival: {reservation.arrival_lat},{" "}
                      {reservation.arrival_lng}
                    </Popup>
                  </Marker>
                );

                return (
                  <React.Fragment key={index}>
                    {(inputs.viewMode === "both" && (
                      <>
                        {departureMarker}
                        {arrivalMarker}
                      </>
                    )) ||
                      (inputs.viewMode === "departure" && departureMarker) ||
                      (inputs.viewMode === "arrival" && arrivalMarker)}
                  </React.Fragment>
                );
              })}
              <FitBounds
                reservations={reservations}
                viewMode={inputs.viewMode}
              />
            </MapContainer>
          )}
        </CardBody>
      </Card>
    </>
  );
};

const FitBounds = ({ reservations, viewMode }) => {
  const map = useMap();

  useEffect(() => {
    if (reservations.length > 0) {
      const bounds = L.latLngBounds(
        reservations
          .map((res) => {
            if (viewMode === "both" || viewMode === "departure") {
              return [
                parseFloat(res.departure_lat),
                parseFloat(res.departure_lng),
              ];
            }
            if (viewMode === "arrival") {
              return [parseFloat(res.arrival_lat), parseFloat(res.arrival_lng)];
            }
            return null;
          })
          .filter((coords) => isValidLatLng(coords[0], coords[1]))
      );
      map.fitBounds(bounds);
    }
  }, [reservations, viewMode, map]);

  return null;
};

export default MapChart;
