import { Fragment } from "react";

import { Card, CardBody } from "reactstrap";
import { CREATE_BADGE, MY_BADGES } from "../../Constant";

import DataTables from "../../Components/DataTables";
import GenerateSerials from "../../Components/GenerateSerials";
import DataTableHeader from "../../Components/DataTableHeader";
import Breadcrumbs from "../../Components/Breadcrumbs";
import FilterBar from "../../Components/FilterBar";
import ModalApp from "../../Components/ModalApp";
import AddItem from "../../Components/AddItem";

import { API, COLUMNS, CREATE_ITEMS, SEARCH_ITEMS } from "./data/Badge";

const Badge = () => {
  const list = API.list;

  const breadCrumbsProps = [MY_BADGES];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTableHeader createBtn={true} serialBtn={true} />

          <FilterBar searchByItems={SEARCH_ITEMS} />

          <DataTables newColumns={COLUMNS} link={list} />
        </CardBody>
      </Card>
      <ModalApp type="create" title={CREATE_BADGE}>
        <AddItem addURL={API.create} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="QRcode">
        <GenerateSerials serialURL={API.Serial} />
      </ModalApp>
    </Fragment>
  );
};

export default Badge;
