import React, { Fragment } from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";

const TextareaInput = ({ register, inputName, oldValue }) => {
  const { name, type, title, placeholder, required } = inputName;
  const defaultValue = oldValue;

  const isReq = required === undefined ? true : false;

  return (
    <Fragment>
      <Row>
        <Col>
          <FormGroup>
            {title} {isReq && <span className="text-danger">*</span>}
            <textarea
              autoComplete="off"
              className="form-control input-style"
              type={type}
              name={name}
              placeholder={placeholder}
              onChange={register}
              defaultValue={defaultValue}
              required
            />
          </FormGroup>
        </Col>
      </Row>
    </Fragment>
  );
};

export default TextareaInput;
