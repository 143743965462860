import React, { useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { ArrowLeft } from "react-feather";
import styles from "../InputQuery/Table.module.css";
import { useParams } from "react-router-dom";
import Loading from "../Loading/Loading";
import usePostData from "../../hooks/usePostData";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { darken, lighten } from "@mui/material";

const TableDataBase = () => {
  const { TableName } = useParams();
  const { data, loading, error, columns } = usePostData(
    "/stat/query/select-table"
  );

  const handleExportRowsPdf = (rows) => {
    const doc = new jsPDF();
    const tableData = rows.map((row) => Object.values(row.original));
    const tableHeaders = columns.map((c) => c.header);

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    doc.save(`${TableName}.pdf`);
  };
  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });
  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };
  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const globalTheme = useTheme();
  const theme = useTheme();

  const baseBackgroundColor =
    theme.palette.mode === "dark"
      ? "rgba(0, 0, 0, 1)"
      : "rgba(255, 255, 255, 1)";

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          info: {
            main: "rgb(255,122,0)",
          },
          background: {
            default: "white", // Set the default background to white
          },
        },
        typography: {
          button: {
            textTransform: "none",
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem",
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "blue",
              },
            },
          },
          MuiTable: {
            styleOverrides: {
              root: {
                backgroundColor: "white", // Set the table background to white
              },
            },
          },
          MuiTableRow: {
            styleOverrides: {
              root: {
                "&.Mui-selected": {
                  backgroundColor: "blue", // Set the selected row background to blue
                  "&:hover": {
                    backgroundColor: "blue", // Maintain blue background on hover
                  },
                },
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableDensityToggle: false,
    initialState: { density: "compact" },
    defaultColumn: {
      minSize: 30,
      //maxSize: 50,
      size: 120,
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: "0",
      },
    },
    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          {
            backgroundColor: darken(baseBackgroundColor, 0.1),
          },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          {
            backgroundColor: darken(baseBackgroundColor, 0.2),
          },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          {
            backgroundColor: lighten(baseBackgroundColor, 0.1),
          },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          {
            backgroundColor: darken(baseBackgroundColor, 0.2),
          },
      }),
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: baseBackgroundColor,
      draggingBorderColor: theme.palette.secondary.main,
    }),
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          padding: "0px",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <Button className={styles.button} onClick={() => window.history.back()}>
          <ArrowLeft size={29} />
        </Button>
        <Button
          className={styles.button}
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() =>
            handleExportRowsPdf(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          PDF
        </Button>
        <Button
          className={styles.button}
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Excel
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Selected Rows
        </Button>
      </Box>
    ),
  });

  if (loading)
    return (
      <center>
        <Loading />
      </center>
    );
  if (error) return <center>Please Select a Table </center>;

  return (
    <ThemeProvider theme={tableTheme}>
      <MaterialReactTable table={table} />
    </ThemeProvider>
  );
};

export default TableDataBase;
