import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Card } from "reactstrap";

import { CREATE } from "../../Constant";
import axiosClient from "../../Services/axiosClient";
import { addAlert } from "../../Components/Alerts/Alerts";
import errorMsg from "../../Services/errorMsg";
import LoadingBtn from "../../Components/LoadingBtn";
import { useDispatch } from "react-redux";
import { toggleRefresh } from "../../redux/searchLink";
import { toggleModal } from "../../redux/modal";

const CreateWorkOrder = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const userData = JSON.parse(localStorage.getItem("userData"));

  const fullName = ` ${userData.firstname} ${userData.lastname}`;

  const addedWithSuccess = () => {
    dispatch(toggleRefresh());
    dispatch(toggleModal());
  };
  const handleCreate = () => {
    setIsLoading(true);
    const url = "/back/workOrder";
    const newData = {
      executor: userData.code,
    };
    axiosClient
      .post(url, newData)
      .then((res) => {
        addAlert();
        setIsLoading(false);
        addedWithSuccess();
      })
      .catch((error) => {
        errorMsg(error);
        setIsLoading(false);
      });
  };
  return (
    <Fragment>
      <Card style={{ border: "1px solid black" }}>
        <Alert color="worning" fade={true}>
          <h4>{t("Are you sure you want to create work order from")}</h4>
          <hr />
          <p className="mb-0"> {fullName} </p>
        </Alert>

        {/* <Row>
          <Col>  </Col>
        </Row> */}
      </Card>

      <LoadingBtn
        attrBtn={{ color: "success", onClick: handleCreate }}
        isLoading={isLoading}
        content={{ done: t(CREATE), loading: "sending" }}
      />
    </Fragment>
  );
};

export default CreateWorkOrder;
