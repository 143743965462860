import React from "react";
import { Card, CardBody,Table } from "reactstrap";
import styles from "./Header.module.css";
import HeaderResult from "./HeaderResult";

const Header = ({ objectData, requiredKeys }) => {
  if (!objectData || !requiredKeys || requiredKeys.length === 0) {
    return null;
  }

  return (
    <div>
      <Card>
        <CardBody className={styles["card"]}>
          <Table bordered responsive>
            <thead className={styles["table"]}>
              <tr className={styles["table-tr"]}>
                {requiredKeys.map(({ name }, i) => (
                  <th key={i}>{name}</th>
                ))}
              </tr>
            </thead>
            <tbody className={styles["tbody-table"]}>
              <tr className={styles["tbody-tr"]}>
                {requiredKeys.map((elem, i) => {
                  console.log(objectData);
                  return (
                    <td key={i}>
                      <HeaderResult objectData={objectData} elem={elem} />
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
};

export default Header;
