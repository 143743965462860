
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";

// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography";
import MDBox from "Components/MDBox";
import MDTypography from "Components/MDTypography";

function Footer({ light }) {
  const { size } = typography;

  return (
    <MDBox position="absolute" bottom={0} py={4} mr={-20}  width="100%">
      <Container>
        <MDBox
          width="100%"
          display="flex"
          flexDirection={{ xs: "column", lg: "row" }}
          justifyContent="space-between"
          alignItems="center"
          px={1}
        >
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            ml={-20}
            color={light ? "white" : "text"}
            fontSize={size.sm}
          >
            &copy; {new Date().getFullYear()}, made with ❤️
            <MDBox
              fontSize={size.md}
              color={light ? "white" : "dark"}
              mb={-0.5}
              mx={0.25}
            >
              <Icon color="inherit" fontSize="inherit">
                <VolunteerActivismIcon />
              </Icon>
            </MDBox>
            by
            <Link href="https://taxiora.tn/" target="_blank">
              <MDTypography
                variant="button"
                fontWeight="medium"
                color={light ? "white" : "dark"}
              >
                &nbsp;Taxiora&nbsp;
              </MDTypography>
            </Link>
            for a better future for taxis.
          </MDBox>
          <MDBox
            component="ul"
            sx={({ breakpoints }) => ({
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              listStyle: "none",
              mt: 3,
              mb: 0,
              p: 0,

              [breakpoints.up("lg")]: {
                mt: 0,
              },
            })}
          >
            <MDBox component="li" pr={2} lineHeight={1}>
              <Link href="https://taxiora.tn/" target="_blank">
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color={light ? "white" : "dark"}
                >
                  Taxiora
                </MDTypography>
              </Link>
            </MDBox>
            <MDBox component="li" px={2} lineHeight={1}>
              <Link href="https://taxiora.tn/" target="_blank">
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color={light ? "white" : "dark"}
                >
                  About Us
                </MDTypography>
              </Link>
            </MDBox>
            <MDBox component="li" px={2} lineHeight={1}>
              <Link href="https://taxiora.tn/contact.html" target="_blank">
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color={light ? "white" : "dark"}
                >
                  Contact Us
                </MDTypography>
              </Link>
            </MDBox>
          </MDBox>
        </MDBox>
      </Container>
    </MDBox>
  );
}


Footer.propTypes = {
  light: PropTypes.bool,
};

export default Footer;
