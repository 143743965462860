import React, { Fragment, useState } from "react";
import { formItems, optionalItems } from "./data";
import FilterInput from "../../Components/Inputs";
import handleInputs from "../../helper/handleInputs";
import Product from "./Product";

const BonDeCommandPage = () => {
  const [inputs, setInputs] = useState({});
  const requiredItems = [
    { title: "titleVal", name: "nameVal", price: 3, number: 1 },
  ];

  return (
    <Fragment>
      {formItems.map((item, i) => (
        <FilterInput
          key={i}
          item={item}
          handleChange={(e) => handleInputs(e, setInputs)}
        />
      ))}

      <h2>required items </h2>

      <div
        style={{
          border: "2px solid black",
          opacity: 0.5,
          pointerEvents: "none",
          marginBottom: "50px",
        }}
      >
        {requiredItems.map((elem, i) => (
          <Product key={i} obj={elem} />
        ))}
      </div>

      <h2>optional items </h2>

      <div
        style={{
          border: "2px solid black",
        }}
      >
        {optionalItems.map((elem, i) => (
          <Product key={i} obj={elem} />
        ))}
      </div>
    </Fragment>
  );
};

export default BonDeCommandPage;
