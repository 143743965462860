import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  Breadcrumbs as BreadcrumbsMantine,
  Anchor,
  Paper,
} from "@mantine/core";

const Breadcrumbs = ({ breadCrumbsProps }) => {
  const { t } = useTranslation();
  const title = t(breadCrumbsProps[0]);
  const elements = breadCrumbsProps.slice(1);
  const items = [
    { title: "Mantine", href: "#" },
    { title: "Mantine hooks", href: "#" },
    { title: "use-id", href: "#" },
  ].map((item, index) => (
    <Anchor href={item.href} key={index}>
      {item.title}
    </Anchor>
  ));

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col sm="6">
              <h3>{title}</h3>
            </Col>
            <Col sm="6">
              <ol className="breadcrumb">
                {elements.map((elem, i) => (
                  <li className="breadcrumb-item" key={i}>
                    {t(elem)}
                  </li>
                ))}
              </ol>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default Breadcrumbs;
