import { Group } from "@mantine/core";

import EditBtn from "Components/MantineDataTableBtns/EditBtn";
import DeleteBtn from "Components/MantineDataTableBtns/DeleteBtn";

export const API = {
  list: "/back/faqs",
  edit: "/back/faq/",
  delete: "/back/faq/",
  create: "/back/faqs",
};

export const COLUMNS = [
  {
    selector: "question",
    name: "Question",
  },
  {
    selector: "answer",
    name: "Answer",
  },

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 30,
    Cell: ({ row }) => {
      const { question, code } = row.original;
      return (
        <Group gap="xs">
          <EditBtn edit={API.edit + code} extra={question} />
          <DeleteBtn delete={API.delete + code} />
        </Group>
      );
    },
  },
];

export const EDIT_ITEMS = [
  {
    title: "Question",
    placeholder: "question",
    name: "question",
    inputForm: "SimpleInput",
  },
  {
    title: "Answer",
    placeholder: "answer",
    name: "answer",
    inputForm: "SimpleInput",
  },
];
