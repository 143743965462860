import { civilityObj } from "../../Constant/objs";
import { getCodeLabel } from "../../helper/getOptions";

// Urls
export const API = {
  list: (code) => `/back/collaborator/${code}/users`,
  create: (code) => `/back/collaborator/${code}/user`,
  patch: (code, user) => `/back/collaborator/${code}/user/${user}`,
  get: (collab, user) => `/back/collaborator/${collab}/user/${user}`,
  delete: "/back/collaborator/",
};

// Form
export const CREATE_ITEMS = [
  {
    title: "Firstname",
    placeholder: "Firstname",
    name: "firstname",
    inputForm: "SimpleInput",
  },
  {
    title: "Lastname",
    placeholder: "Lastname",
    name: "lastname",
    inputForm: "SimpleInput",
  },
  civilityObj,
  {
    title: "Phone",
    placeholder: "Phone",
    name: "phone",
    inputForm: "SimpleInput",
  },
  {
    title: "Role",
    name: "role",
    inputForm: "SelectApiInput",
    optionsApi: "/back/collaborator-roles",
    getOptions: getCodeLabel,
  },
  {
    title: "mail",
    placeholder: "mail",
    name: "mail",
    inputForm: "SimpleInput",
  },
];

export const EDIT_ITEMS = [
  {
    title: "Firstname",
    placeholder: "Firstname",
    name: "firstname",
    inputForm: "SimpleInput",
  },
  {
    title: "Lastname",
    placeholder: "Lastname",
    name: "lastname",
    inputForm: "SimpleInput",
  },
  {
    title: "Phone",
    placeholder: "Phone",
    name: "phone",
    inputForm: "SimpleInput",
  },
  {
    title: "Role",
    name: "role",
    inputForm: "SelectApiInput",
    optionsApi: "/back/collaborator-roles",
    getOptions: getCodeLabel,
  },
  {
    title: "mail",
    placeholder: "mail",
    name: "mail",
    inputForm: "SimpleInput",
  },
  civilityObj,
];
