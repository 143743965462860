import { Card, CardBody } from "reactstrap";
import { Fragment } from "react";

import DataTables from "Components/DataTables/DataTable";
import Breadcrumbs from "Components/Breadcrumbs";
import EditItem from "Components/EditItem";
import ModalApp from "Components/ModalApp";
import AddItem from "Components/AddItem";

import { API, COLUMNS, CREATE_ITEMS, EDIT_ITEMS } from "./data";

import { CREATE_PROFILES, EDIT_PROFILES, MY_PROFILES } from "Constant";

const ProfilesPage = () => {
  const list = API.list;

  const breadCrumbsProps = [MY_PROFILES];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTables
            newColumns={COLUMNS}
            link={list}
            columnSearch={false}
            globalSearch={false}
          />
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_PROFILES}>
        <AddItem
          addURL={API.create}
          items={CREATE_ITEMS}
          defVal={{ pause: true }}
        />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_PROFILES}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default ProfilesPage;
