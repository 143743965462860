import { getCodeLabel } from "../../helper/getOptions";

// API
export const API = {
  list: "/back/primitives",
  get: "/back/primitive/",
};

// Columns
export const COLUMNS = [
  {
    name: "Number",
    selector: "pr_number",
  },

  {
    name: "Status",
    selector: "status",
  },

  {
    name: "Company",
    selector: "company",
  },

  {
    name: "Complete name",
    selector: "complete_name",
  },

  {
    name: "Address",
    selector: "address",
  },

  {
    name: "Creator",
    selector: "creator",
  },

  {
    name: "Updator",
    selector: "updator",
  },
];

// Search
const doubleSelectCategoryModel = {
  title: "Category",
  name: "category",
  inputForm: "MultiSelectApiInput",
  optionsApi: "/back/rawmaterial/categories",
  getOptions: getCodeLabel,
  secondSelect: (param) => {
    return {
      title: "Model",
      name: "model",
      optionsApi: `/back/rawmaterial/${param}/models`,
      getOptions: getCodeLabel,
    };
  },
};
export const SEARCH_ITEMS = [doubleSelectCategoryModel];

// Form
export const CREATE_ITEMS = [
  doubleSelectCategoryModel,
  {
    name: "slipType",
    title: "Slip type",
    inputForm: "SelectInput",
    options: [
      {
        value: "repair",
        label: "repair",
      },
      {
        value: "destroy",
        label: "destroy",
      },
      {
        value: "supplier",
        label: "supplier",
      },
    ],
  },

  {
    title: "Commentary",
    placeholder: "commentary",
    name: "commentary",
    inputForm: "TextareaInput",
  },
];

export const EDIT_ITEMS = [
  doubleSelectCategoryModel,
  {
    name: "slipType",
    title: "Slip type",
    inputForm: "SelectInput",
    options: [
      {
        value: "repair",
        label: "repair",
      },
      {
        value: "destroy",
        label: "destroy",
      },
      {
        value: "supplier",
        label: "supplier",
      },
    ],
  },
  {
    title: "Commentary",
    placeholder: "commentary",
    name: "commentary",
    inputForm: "TextareaInput",
  },
];
