// Urls
export const API = {
  list: (code) => `/back/model/${code}/prices`,
  create: (code) => `/back/model/${code}/price`,
};

// Columns
export const COLUMNS = [
  { name: "Price", selector: "price" },
  { name: "Date begin", selector: "date_begin" },
  { name: "Date end", selector: "date_end" },
  { name: "Created at", selector: "created_at" },
];

export const SEARCH_ITEMS = [
  {
    title: "Price",
    placeholder: "price",
    name: "price",
    inputForm: "SimpleInput",
  },
  {
    title: "Date Begin",
    placeholder: "date_begin",
    name: "date_begin",
    inputForm: "DateInput",
  },
  {
    title: "Date End",

    placeholder: "date_end",
    name: "date_end",
    inputForm: "DateInput",
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Price",
    placeholder: "price",
    name: "price",
    inputForm: "SimpleInput",
  },
  {
    title: "TVA",
    placeholder: "tva",
    name: "vat",
    inputForm: "SimpleInput",
  },
  {
    title: "Date Begin",

    placeholder: "date_begin",
    name: "date_begin",
    inputForm: "DateInput",
  },
  {
    title: "Date End",

    placeholder: "date_end",
    name: "date_end",
    inputForm: "DateInput",
  },
];

export const EDIT_ITEMS = [
  {
    title: "Price",
    placeholder: "price",
    name: "price",
    inputForm: "SimpleInput",
  },
  {
    title: "TVA",
    placeholder: "tva",
    name: "vat",
    inputForm: "SimpleInput",
  },
  {
    title: "Date Begin",

    placeholder: "date_begin",
    name: "date_begin",
    inputForm: "DateInput",
  },
  {
    title: "Date End",

    placeholder: "date_end",
    name: "date_end",
    inputForm: "DateInput",
  },
];
