import { Fragment } from "react";
import { Card, CardBody } from "reactstrap";

import DataTables from "Components/DataTables/DataTable";
import Breadcrumbs from "Components/Breadcrumbs";
import EditItem from "Components/EditItem";
import ModalApp from "Components/ModalApp";
import AddItem from "Components/AddItem";

import { API, COLUMNS, CREATE_ITEMS, EDIT_ITEMS } from "./data";

import { CREATE_FIRMWARE, EDIT_FIRMWARE, MY_FIRMWARES } from "Constant/index";
import CreateFirmware from "./CreateFirmware";

const FirmwarePage = () => {
  const list = API.list;

  const breadCrumbsProps = [MY_FIRMWARES];

  const handleFileUpload = () => {};
  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTables newColumns={COLUMNS} link={list} globalSearch={false} />
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_FIRMWARE}>
        <CreateFirmware addURL={API.create} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_FIRMWARE}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default FirmwarePage;
