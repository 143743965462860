import React from "react";
import { Editor } from "@monaco-editor/react";
import { Card, Label } from "reactstrap";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { setEditorContent } from "../../redux/requeteSql";

const EditorInputEdit = ({ register, inputName }) => {
  const { name, title } = inputName;

  const dispatch = useDispatch();
  const editorContent = useSelector((state) => state.requeteSql.editorContent);

  const handleEditorContentChange = (value) => {
    dispatch(setEditorContent(value));
    register({ name, value });
  };

  return (
    <>
      <Label>{title}</Label>
      <Card>
        <CustomCard>
          <Editor
            className="editor-container"
            height="200px"
            width="90%"
            language="sql"
            options={{
              fontSize: 18,
              wordWrap: "on",
            }}
            value={editorContent}
            onChange={handleEditorContentChange}
          />
        </CustomCard>
      </Card>
    </>
  );
};

export default EditorInputEdit;

const CustomCard = styled.div`
  margin: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;
