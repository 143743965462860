import React, { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import useFetchData from "../../hooks/useFetchData";
import { DRIVERS, TAXI } from "../../Constant";
import Drivers from "./Drivers";
import { FaAddressCard } from "react-icons/fa";
import Taximeter from "./Taximeter";
import { useTranslation } from "react-i18next";
import Historique from "./Historique";
import Loading from "../../Components/Loading/Loading";
import NotFound from "../../Components/NotFound";
import { Card, CardBody, Col, Row } from "reactstrap";
import HeaderTabs from "../../Components/Header/HeaderTabs";
import StepsWithIcon from "../../Components/LeftTab/StepsWithIcon";
import { BiUserCircle } from "react-icons/bi";
import Breadcrumbs from "../../Components/Breadcrumbs/SheetOf";

const ReadClientTaxiPage = () => {
  const [state, setState] = useState(0);

  const { t } = useTranslation();
  const { code } = useParams();

  let taxiData;

  const { data, isLoading, isError } = useFetchData(
    "/back/taxi/" + code + "/sheet"
  );

  if (isLoading) return <Loading />;

  if (isError) return <NotFound />;

  if (!isLoading && !isError) {
    taxiData = data.data.results.data;
  }
  const { taxi, client, taximeter, users } = taxiData;

  const tabContent = [
    {
      name: t("Taximeter"),
      content: <Taximeter data={taximeter} />,
      icon: <FaAddressCard />,
    },
    {
      name: t(DRIVERS),
      content: <Drivers data={users} />,
      icon: <FaAddressCard />,
    },
    {
      name: t("Historique"),
      content: <Historique data={taxiData.history} />,
      icon: <FaAddressCard />,
    },
  ];
  const requiredKeys = [
    {
      key: "taxi_serial_number",
      name: "Taxi Serial Number",
      icon: <BiUserCircle />,
    },
    {
      key: "registration_number",
      name: "Registration Number",
      icon: <BiUserCircle />,
    },
    { key: "model_taxi", name: "Model Taxi", icon: <BiUserCircle /> },
    { key: "brand_taxi", name: "Brand Taxi", icon: <BiUserCircle /> },
    { key: "active_taxi", name: "Active Taxi", icon: <BiUserCircle /> },
    { key: "sensor", name: "Sensor", icon: <BiUserCircle /> },
    { key: "places", name: "Places", icon: <BiUserCircle /> },

    { key: "licence_got_at", name: "Licence Got At", icon: <BiUserCircle /> },
    {
      key: "licence_expire_at",
      name: "Licence Expire At",
      icon: <BiUserCircle />,
    },
    {
      key: "circulation_date",
      name: "Circulation Date",
      icon: <BiUserCircle />,
    },
  ];
  console.log(taxi);
  const dynamicPart = `${t(TAXI)} ${taxi.taxi_serial_number}`;
  const breadCrumbsProps = [dynamicPart];
  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />
          <Row>
            <Col xs="12" md="3">
              {!isLoading && (
                <HeaderTabs
                  title={breadCrumbsProps}
                  objectData={taxi}
                  requiredKeys={requiredKeys}
                />
              )}
            </Col>
            <Col xs="12" md="9">
              <Card>
                <CardBody>
                  <div style={{ margin: "0px 10px 30px 10px" }}>
                    <StepsWithIcon
                      tabContent={tabContent}
                      state={state}
                      setState={setState}
                    />
                  </div>
                  {tabContent[state].content}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default ReadClientTaxiPage;
