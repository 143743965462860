import React from "react";

const SegmentedControl = ({ options, selectedOption, onChange }) => {
  const handleOptionChange = (option) => {
    onChange(option);
  };

  return (
    <>
      <style>
        {`
          .segmented-control {
            display: flex;
            border-radius: 8px; /* Kept the border radius moderate for a rounded look */
            overflow: hidden;
            background-color: #e0e0e0;
            width: fit-content;
          }

          .segmented-control-button {
            border: none;
            padding: 5px 20px; /* Decreased padding for a thinner height */
            background: none;
            cursor: pointer;
            transition: background-color 0.3s, color 0.3s;
            flex: 1;
            font-weight: bold; /* Optional: keeps the text bold for more emphasis */
          }

          .segmented-control-button.active {
            background-color: orange; /* Active color */
            color: white;
          }

          .segmented-control-button:not(.active):hover {
            background-color: #d0d0d0;
          }
        `}
      </style>

      <div className="segmented-control">
        {options.map((option) => (
          <button
            key={option}
            className={`segmented-control-button ${
              selectedOption === option ? "active" : ""
            }`}
            onClick={() => handleOptionChange(option)}
          >
            {option}
          </button>
        ))}
      </div>
    </>
  );
};

export default SegmentedControl;
