import { Col, FormGroup, Label } from "reactstrap";
import React, { Fragment } from "react";

const SimpleInput = ({ register, inputName, oldValue, isDisable }) => {
  const { name, type, title, placeholder, required } = inputName;

  const handleOnChange = (e) => {
    register(e);
  };
  const isReq = required === undefined ? true : false;
  return (
    <Fragment>
      <Col sm="3">
        <FormGroup>
          <Label>
            {title} {isReq && <span className="text-danger">*</span>}
          </Label>
          <input
            autoComplete="off"
            className="form-control input-style"
            placeholder={placeholder}
            onChange={handleOnChange}
            defaultValue={oldValue}
            type={type}
            name={name}
            required={isReq}
            disabled={isDisable}
          />
        </FormGroup>
      </Col>
    </Fragment>
  );
};

export default SimpleInput;
