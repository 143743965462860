import { Fragment } from "react";

import { Card, CardBody } from "reactstrap";

import DataTables from "../../Components/DataTables";
import DataTableHeader from "../../Components/DataTableHeader";
import Breadcrumbs from "../../Components/Breadcrumbs";
import FilterBar from "../../Components/FilterBar";
import EditItem from "../../Components/EditItem";
import ModalApp from "../../Components/ModalApp";
import AddItem from "../../Components/AddItem";

import {
  API,
  COLUMNS,
  CREATE_ITEMS,
  EDIT_ITEMS,
  SEARCH_ITEMS,
} from "./data/Sunshield";

import {
  CREATE_SUNSHIELDS,
  EDIT_SUNSHIELDS,
  MY_SUNSHIELDS,
} from "../../Constant";
import GenerateSerials from "../../Components/GenerateSerials";

const Sunshield = () => {
  const list = API.list;

  const breadCrumbsProps = [MY_SUNSHIELDS];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTableHeader createBtn={true} serialBtn={true} />

          <FilterBar searchByItems={SEARCH_ITEMS} />

          <DataTables newColumns={COLUMNS} link={list} />
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_SUNSHIELDS}>
        <AddItem addURL={API.create} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_SUNSHIELDS}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>

      <ModalApp type="QRcode">
        <GenerateSerials serialURL={API.Serial} />
      </ModalApp>
    </Fragment>
  );
};

export default Sunshield;
