import { createSlice } from "@reduxjs/toolkit";




export const requeteSqlSlice = createSlice({
  name: "requeteSql",
  initialState : {
  sidebarVisible: true,
  buttonClicked: false,
  editorContent: "",
},
  reducers: {
    setEditorContent: (state, action) => {
      state.editorContent = action.payload;
    },
    toggleSidebar: (state) => {
      state.sidebarVisible = !state.sidebarVisible;
    },
    setButtonClicked: (state, action) => {
      state.buttonClicked = action.payload;
    },
  },
});

export const { setEditorContent, toggleSidebar, setButtonClicked } =
  requeteSqlSlice.actions;

export default requeteSqlSlice.reducer;
