import { IconCircleCheck, IconXboxX } from "@tabler/icons-react";

const HandleResult = ({ data }) => {
  const type = typeof data;
  if (type === "boolean") {
    return type ? <IconCircleCheck color="green" /> : <IconXboxX color="red" />;
  }

  if (type === "null") return "";

  return data;
};

export default HandleResult;
