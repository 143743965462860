import React, { Fragment } from "react";

import DataTableHeader from "../../Components/DataTableHeader";
import DataTables from "../../Components/DataTables";
import { API, COLUMNS_NOTEBOOKS } from "./data";

const Notebook = ({ code }) => {
  const list = API.list(code) + "/notebook";

  return (
    <Fragment>
      <DataTableHeader />
      <DataTables newColumns={COLUMNS_NOTEBOOKS} link={list} />
    </Fragment>
  );
};

export default Notebook;
