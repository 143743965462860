import axiosClient from "../../Services/axiosClient";
import errorMsg from "../../Services/errorMsg";

import { addAlert } from "../Alerts/Alerts";

const importData = async (data, URL_CREATE, handleImport, success, fn) => {
  let newData = data;
  if (handleImport) newData = JSON.parse(handleImport(data));

  axiosClient
    .post(URL_CREATE, newData)
    .then((res) => {
      addAlert();
      success();
      fn(false);
    })
    .catch((error) => {
      console.error("Request failed:", error);
      errorMsg(error);
      fn(false);
    });
};
export default importData;
