import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../Services/axiosClient";
import getRowsFromRes from "../helper/getRowsFromRes";
import errorMsg from "../Services/errorMsg";

export const fetchGetrows = createAsyncThunk("list/fetchRows", async (url) => {
  const axiosUrl = url;
  try {
    const response = await axiosClient.get(axiosUrl);
    return getRowsFromRes(response);
  } catch (error) {
    errorMsg(error);
    throw error;
  }
});

export const getRowsSlice = createSlice({
  name: "getRows",
  initialState: {
    data: {
      rows: [],
    },
    isLoading: false,
    isError: false,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchGetrows.pending, (state) => {
      state.data = {
        rows: [],
      };
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(fetchGetrows.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
    });
    builder.addCase(fetchGetrows.rejected, (state) => {
      state.data = {
        rows: [],
      };
      state.isLoading = false;
      state.isError = true;
    });
  },

  reducers: {
    initData: (state) => {
      state.data = {
        rows: [],
      };
    },
    insertData: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
    },
    pendingData: (state) => {
      state.data = {
        rows: [],
      };
      state.isLoading = true;
      state.isError = false;
    },
    rejectedData: (state) => {
      state.data = {
        rows: [],
      };
      state.isLoading = false;
      state.isError = true;
    },
  },
});

export const { initData, insertData, pendingData, rejectedData } =
  getRowsSlice.actions;

export default getRowsSlice.reducer;
