import ADMINISTRATION_ROUTES from "./Administration.routes";
import COMMERCIAL_ROUTES from "./Commercial.routes";
import PRODUCTION_ROUTES from "./Production.routes";
import STATISTICS_ROUTES from "./Statistics.routes";
import STOCK_ROUTES from "./Stock.routes";

const PAGE_ROUTES = [
  ...ADMINISTRATION_ROUTES,
  ...COMMERCIAL_ROUTES,
  ...PRODUCTION_ROUTES,
  ...STATISTICS_ROUTES,
  ...STOCK_ROUTES,
];

export default PAGE_ROUTES;
