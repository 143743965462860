import { Fragment } from "react";

import { Card, CardBody } from "reactstrap";

import DataTables from "Components/DataTables/DataTable";
import Breadcrumbs from "Components/Breadcrumbs";
import EditItem from "Components/EditItem";
import ModalApp from "Components/ModalApp";
import AddItem from "Components/AddItem";

import { API, COLUMNS, CREATE_ITEMS, EDIT_ITEMS } from "./data";

import { CREATE_MODEL, EDIT_MODEL, MODELS_OF_BRAND } from "Constant";
import { useParams } from "react-router-dom";

const ReadBrandPage = () => {
  const { code } = useParams();

  const list = API.list(code);

  const title = `${MODELS_OF_BRAND} ${code}`;
  const breadCrumbsProps = [title];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTables newColumns={COLUMNS} link={list} />
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_MODEL}>
        <AddItem addURL={API.create(code)} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_MODEL}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default ReadBrandPage;
