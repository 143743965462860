import { useEffect, useState } from "react";

import axiosClient from "../Services/axiosClient";
import clearUserData from "Services/clearUserData";

const useFetchData = (url, dep, dep2) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    axiosClient
      .get(url)
      .then((res) => {
        setData(res);
        setIsLoading(false);
        setIsError(false);
      })
      .catch((err) => {
        setData(err);
        setIsLoading(false);
        setIsError(true);

        try {
          const { value } = err.response.data.messages;
          if (value === "expired_session") clearUserData();
        } catch {}
      });
  }, [dep, dep2]);

  return { data, isLoading, isError };
};
export default useFetchData;
