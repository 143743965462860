import UserPage from "../pages/UserPage";
import PolygonsPage from "../pages/PolygonsPage";
import ProfilesPage from "../pages/ProfilesPage";
import ReadProfile from "../pages/ReadProfile";
import TariffPage from "../pages/TariffPage";
import ReadBrandPage from "../pages/ReadBrandPage";
import BrandsPage from "../pages/BrandsPage";
import HolidaysPage from "../pages/HolidaysPage";
import FirmwarePage from "../pages/FirmwarePage";
import ScreenPage from "../pages/ScreenPage";
import WordingPage from "../pages/WordingPage";
import ReadWordingPage from "../pages/ReadWordingPage";
import ParameterPage from "../pages/ParameterPage";
import ReadParameterPage from "../pages/ReadParameterPage";
import BonDeCommandPage from "../pages/BonDeCommandPage";
import ReadModelPage from "../pages/ReadModelPage";
import ModelPage from "../pages/ModelPage";
import RGPDPage from "../pages/RGPDPage";

import QueryListPage from "../pages/ListQueryPage";
import TableDataBasePage from "../pages/TableDataBasePage";
import RequetePage from "../pages/RequetePage";
import ServicesListPage from "../pages/ServicesListPage";

// import FAQPage from "../pages/FAQPage";
import NewFaqPage from "pages/NewFaqPage";
import TestDataTableV2 from "pages/TestDataTableV2/TestDataTableV2";

const ADMINISTRATION_ROUTES = [
  {
    path: "/users",
    element: <UserPage />,
    roles: ["SUPER_ADMIN"],
  },
  {
    path: "/polygons",
    element: <PolygonsPage />,
    roles: ["SUPER_ADMIN"],
  },
  {
    path: "/profiles",
    element: <ProfilesPage />,
    roles: ["SUPER_ADMIN"],
  },
  {
    path: "/profiles/:code",
    element: <ReadProfile />,
    roles: ["SUPER_ADMIN"],
  },
  {
    path: "/tariff/:code",
    element: <TariffPage />,
    roles: ["SUPER_ADMIN"],
  },
  {
    path: "/brands/:code",
    element: <ReadBrandPage />,
    roles: ["SUPER_ADMIN"],
  },
  {
    path: "/brands",
    element: <BrandsPage />,
    roles: ["SUPER_ADMIN"],
  },
  {
    path: "/holidays",
    element: <HolidaysPage />,
    roles: ["SUPER_ADMIN"],
  },
  {
    path: "/firmware",
    element: <FirmwarePage />,
    roles: ["SUPER_ADMIN"],
  },
  {
    path: "/screen",
    element: <ScreenPage />,
    roles: ["SUPER_ADMIN"],
  },
  {
    path: "/wording",
    element: <WordingPage />,
    roles: ["SUPER_ADMIN"],
  },
  {
    path: "/wording/:code",
    element: <ReadWordingPage />,
    roles: ["SUPER_ADMIN"],
  },
  {
    path: "/parameter",
    element: <ParameterPage />,
    roles: ["SUPER_ADMIN"],
  },
  {
    path: "/parameter/:code",
    element: <ReadParameterPage />,
    roles: ["SUPER_ADMIN"],
  },
  {
    path: "/bon-de-command",
    element: <BonDeCommandPage />,
    roles: ["SUPER_ADMIN"],
  },
  {
    path: "/model",
    element: <ModelPage />,
    roles: ["SUPER_ADMIN"],
  },
  {
    path: "/model/:code",
    element: <ReadModelPage />,
    roles: ["SUPER_ADMIN"],
  },

  {
    path: "/services-list",
    element: <ServicesListPage />,
    roles: ["SUPER_ADMIN", "ADMIN_COMR"],
  },

  {
    path: "statistics-requests",
    element: <RequetePage />,
    roles: ["SUPER_ADMIN"],
    name: "true",
  },
  {
    path: "table-list/:TableName",
    element: <TableDataBasePage />,
    roles: ["SUPER_ADMIN"],
    name: "true",
  },
  {
    path: "/statistics-requests/requests-list",
    element: <QueryListPage />,
    roles: ["SUPER_ADMIN"],
  },

  { path: "/RGPD", element: <RGPDPage />, roles: ["SUPER_ADMIN"] },

  // {
  //   path: "/faq",
  //   element: <FAQPage />,
  //   roles: ["SUPER_ADMIN"],
  // },
  // {
  //   path: "/faq",
  //   element: <NewFaqPage />,
  //   roles: ["SUPER_ADMIN"],
  // },

  {
    path: "/faq",
    element: <TestDataTableV2 />,
    roles: ["SUPER_ADMIN"],
  },
];

export default ADMINISTRATION_ROUTES;
