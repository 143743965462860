import { areYouSureAlert, changedAlert } from "../Components/Alerts/Alerts";
import { toggleRefresh } from "../redux/searchLink";
import { store } from "../redux/store";

import axiosClient from "./axiosClient";

import errorMsg from "./errorMsg";

const createItemWithPopUp = (url) => {
  areYouSureAlert().then((result) => {
    if (result.isConfirmed) {
      axiosClient
        .post(url)
        .then(() => {
          changedAlert();
          store.dispatch(toggleRefresh());
        })
        .catch((error) => {
          console.error("Request failed:", error);
          errorMsg(error);
        });
    }
  });
};
export default createItemWithPopUp;
