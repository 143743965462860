import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";

import useFetchData from "hooks/useFetchDataWithDep";
import CustomActions from "./CustomActions";

const DataTable = ({
  newColumns,
  link,
  createBtn,
  globalSearch,
  columnSearch,
}) => {
  const { refresh } = useSelector((state) => state.searchLink);
  const { t } = useTranslation();
  const columns = transferColumns(newColumns, t);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);

  const url = useMemo(() => {
    const params = new URLSearchParams();
    params.append("size", pagination.pageSize);
    params.append("index", pagination.pageIndex + 1);
    if (globalFilter) {
      params.append("search", globalFilter);
    }

    columnFilters.forEach((filter) => {
      if (filter.value) {
        params.append(filter.id, filter.value);
      }
    });

    const result = `${link}?${params.toString()}`;

    return result;
  }, [pagination.pageSize, pagination.pageIndex, globalFilter, columnFilters]);

  const { data, isLoading, isError } = useFetchData(url, url, refresh);

  const { total: paginationTotalRows, rows: listOfData } = getRows(data);

  const onGlobalFilterChange = (event) => {
    setGlobalFilter(event);
  };

  const table = useMantineReactTable({
    columns: columns,
    data: listOfData,
    rowCount: paginationTotalRows,
    initialState: { pagination: pagination },
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    getRowId: (row) => row.id,
    onPaginationChange: setPagination,
    manualPagination: true,
    mantinePaginationProps: {
      showRowsPerPage: true,
    },
    enableStickyHeader: true,
    mantineTableProps: {
      highlightOnHover: true,
      striped: "odd",
      withColumnBorders: true,
      withRowBorders: true,
      withTableBorder: true,
    },
    enableColumnResizing: true,
    layoutMode: "grid",
    paginationDisplayMode: "pages",

    renderTopToolbarCustomActions: ({ table }) => {
      if (createBtn === undefined) return <CustomActions table={table} />;
      if (createBtn) return createBtn;
    },
    onGlobalFilterChange: onGlobalFilterChange,
    onColumnFiltersChange: setColumnFilters,
    enableGlobalFilter: globalSearch === undefined ? true : globalSearch,
    enableColumnFilters: columnSearch === undefined ? true : globalSearch,
    state: {
      isLoading: isLoading,
      showAlertBanner: false,
      showProgressBars: false,
      pagination,
    },
  });

  return <MantineReactTable table={table} />;
};

export default DataTable;

const transferColumns = (list, t) => {
  const res = list.map((col, i) => {
    if (col.button) return { ...col, id: i };

    const header = typeof col.name === "string" ? t(col.name) : col.name;

    const newCol = !col.Cell
      ? {
          accessorKey: col.selector,
          header: header,
        }
      : col;
    return newCol;
  });

  return res;
};
const getRows = (data) => {
  try {
    return data.data.results.data;
  } catch {
    return { total: 1, rows: [] };
  }
};
