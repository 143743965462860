import { ShowErrorMsg, addAlert } from "Components/Alerts/Alerts";
import { FILL_OUT_THIS_FIELDS } from "Constant";
import axiosClient from "Services/axiosClient";

import { Toast } from "primereact/toast";
import Swal from "sweetalert2";

const onSubmit = async (newData, url, changeLoadingState, setErrors) => {
  console.log(newData);

  const errors = [];
  let text = "";

  if (!newData.client) {
    errors.push("client");
    setErrors((prevState) => ({ ...prevState, client: true }));
  }
  if (!newData.taxi) {
    errors.push("taxi");
    setErrors((prevState) => ({ ...prevState, taxi: true }));
  }

  errors.forEach((element) => {
    text = text + ` ${element}`;
  });

  if (errors.length)
    return Swal.fire({
      title: FILL_OUT_THIS_FIELDS,
      text: text,
      icon: "warning",
    });

  await axiosClient
    .put(url, newData)
    .then((res) => {
      addAlert();

      const href = `/estimate/${res.data.results.data.object}`;
      setTimeout(() => {
        window.location.href = href;
      }, 500);
    })
    .catch((error) => {
      ShowErrorMsg(error);
    });
  if (changeLoadingState) changeLoadingState(false);
};

export default onSubmit;
