import React, { Fragment } from "react";
import useFetchData from "hooks/useFetchDataWithDep";
import { getData } from "helper/getData";
import generateSearchLink from "Services/generateSearchLink";
import NotFound from "Components/NotFound";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import StatisticLoading from "Components/Loading/StatisticLoading";

const GrowthRate = ({ inputs, inputs1 }) => {

  const searchItems = {
    date: inputs.dateValue,
    reference_date: inputs1.dateValue,
  };


  const url = generateSearchLink("/stat/sales/growth-rate", searchItems);
  const { data, isLoading, isError } = useFetchData(url, inputs1,inputs);
  if (isError) return <NotFound />;

  const productStatus = getData(data) ?? [];
  const totalAmount =
  productStatus ? productStatus.growth_rate : 0;
  const message = productStatus ? productStatus.message : "";
  const color = productStatus.growth_rate > 0 ? "success" : "error";


  return (
    <Fragment>
      {!isLoading && (
        <ComplexStatisticsCard
          color="dark"
          icon={<TrendingUpIcon />}
          title="Growth Rate"
          count={`${totalAmount === null ? "0" :totalAmount}%`}
          percentage={{
            color: color,
            label: inputs.dateValue ? inputs.dateValue : "2024",
            amount: message,
          }}
        />
      )}
      {isLoading && <StatisticLoading />}
    </Fragment>
  );
};

export default GrowthRate;
