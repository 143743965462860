import { Badge, Grid, Group } from "@mantine/core";

import DownloadPDF from "Components/MantineDataTableBtns/DownloadPDF";

import {
  getCodeLabel,
  getModelsLabel,
  getServiceLabel,
} from "helper/getOptions";

import EditBtn from "./EditBtn";
import { CancelEstimate, ConfirmEstimate } from "./OptionsBtns";

// Urls
export const API = {
  list: "/back/estimates",
  get: (p) => `/back/estimate/${p}`,
  create: "/back/estimate",
};

// Columns
export const COLUMNS = [
  {
    name: "N°estimate",
    selector: "estimate_number",
  },
  {
    name: "Complete name",
    selector: "complete_name",
  },
  {
    name: "Taxi",
    selector: "taxi",
  },
  {
    name: "Price TTC",
    selector: "price_ttc",
  },

  {
    name: "Creator",
    selector: "creator",
  },
  {
    name: "Created at",
    selector: "created_at",
  },
  {
    header: "Status",
    Cell: ({ row }) => {
      const { status } = row.original;
      let color = "gray";

      if (status === "Annulé") color = "red";
      if (status === "Crée") color = "indigo";
      if (status === "Confirmé") color = "teal";

      return (
        <Badge color={color} size="lg">
          {status}
        </Badge>
      );
    },
  },

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 120,
    Cell: ({ row }) => {
      const { code, to_confirm, to_cancel, to_edit } = row.original;
      const urlCancel = `/back/estimate/${code}/cancel`;
      const urlConfirm = `/back/estimate/${code}/confirm`;
      return (
        <Group gap="xs">
          <Grid>
            <Grid.Col span={4}>
              <DownloadPDF read={`/estimate/${code}`} />
            </Grid.Col>
            <Grid.Col span={4}>
              <EditBtn read={`/edit-estimate/${code}`} disabled={!to_edit} />
            </Grid.Col>
            <Grid.Col span={4}>
              <CancelEstimate
                url={urlCancel}
                disabled={!to_cancel}
                row={row.original}
              />
            </Grid.Col>
          </Grid>

          <ConfirmEstimate url={urlConfirm} disabled={!to_confirm} />
        </Group>
      );
    },
  },
];

export const DETAIL_ESTIMATE = [
  {
    title: "Client",
    name: "client",
    inputForm: "MultiSelectApiInput",
    optionsApi: "/back/client",
    getOptions: getCodeLabel,
    secondSelect: (param) => {
      return {
        title: "Taxi",
        name: "taxi",
        optionsApi: `/back/client/${param}/taxis/choices`,
        getOptions: getCodeLabel,
      };
    },
  },
  {
    title: "Deposit",
    name: "deposit",
    inputForm: "NumberInput",
  },
  {
    title: "Time scale",
    name: "timescale",
    inputForm: "NumberInput",
  },
  {
    title: "Remaining amount",
    name: "remainingAmount",
    inputForm: "NumberInput",
  },
];

export const CATEGORY_MODEL = {
  title: "Category",
  name: "category",
  inputForm: "MultiSelectApiInput",
  optionsApi: "/back/inventory-categories",
  getOptions: getCodeLabel,
  secondSelect: (param) => {
    return {
      title: "Model",
      name: "model",
      optionsApi: `/back/models/choices/${param}`,
      getOptions: getModelsLabel,
    };
  },
};

export const CREATE_SERVICE = [
  {
    title: "Service",
    name: "service",
    inputForm: "SelectApiInput",
    optionsApi: "/back/services",
    getOptions: getServiceLabel,
  },

  {
    title: "Date begin",
    name: "dateBegin",
    inputForm: "DateInput",
  },
  {
    title: "Date end",
    name: "dateEnd",
    inputForm: "DateInput",
  },

  {
    title: "Quantity",
    name: "quantity",
    inputForm: "NumberInput",
  },
  {
    title: "Description",
    placeholder: "Description",
    name: "description",
    inputForm: "TextareaInput",
  },
];

export const EDIT_ITEMS = [];

export const PRODUCT_STATIC_INPUT = [
  { title: "price HT", name: "price_ht" },
  { title: "VAT (%)", name: "vat" },
  { title: "price TTC", name: "price_ttc" },
];

export const ESTIMATE_STATIC_INPUT = [
  { title: "vat amount", name: "vatAmount" },
  { title: "price", name: "price" },
  { title: "price vat", name: "priceVat" },
];

// Form
export const CREATE_PRODUCT = [
  CATEGORY_MODEL,
  {
    title: "Quantity",
    name: "quantity",
    inputForm: "NumberInput",
  },

  {
    title: "Discount",
    name: "discount",
    inputForm: "NumberInput",
  },

  {
    title: "Description",
    placeholder: "Description",
    name: "description",
    inputForm: "TextareaInput",
  },
];
