import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Main from "./Main";

import Loading from "Components/Loading/Loading";
import NotFound from "Components/NotFound";

import { useDispatch } from "react-redux";
import clearUserData from "Services/clearUserData";
import axiosClient from "Services/axiosClient";
import { fillOldData } from "../../redux/estimateSlicer";

const EditEstimatePage = () => {
  const { code } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});

  const url = `/back/estimate/${code}`;
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    axiosClient
      .get(url)
      .then((res) => {
        setIsLoading(false);
        setIsError(false);

        setData(oldData(res));
        dispatch(fillOldData(oldData(res)));
      })
      .catch((err) => {
        setIsLoading(false);
        setIsError(true);

        try {
          const { value } = err.response.data.messages;
          if (value === "expired_session") clearUserData();
        } catch {}
      });
  }, [code]);

  console.log(data);

  if (isLoading) return <Loading />;
  if (isError) return <NotFound />;

  if (!isLoading && !isError) return <Main oldData={data} code={code} />;
};

export default EditEstimatePage;

const oldData = (res) => {
  const data = res.data.results.data;
  console.log(data);

  const products = data.products.map((elem) => ({
    category: elem.category,
    model: elem.model,
    description: elem.description,
    discount: elem.discount,
    price: elem.unit_price,
    quantity: elem.quantity,
    vat: elem.vat,
  }));

  const services = data.services.map((elem) => ({
    service: elem.service,
    description: elem.description,
    price: elem.unit_price,
    quantity: elem.quantity,
    vat: elem.vat,
  }));

  const result = {
    timescale: data.timescale,
    remainingAmount: data.remainingAmount,
    deposit: data.deposit,
    number: data.estimateNumber,
    taxi: data.taxi,
    client: data.client,
    vatAmount: data.vatAmount,
    price: data.price,
    priceVat: data.priceVat,
    products: products,
    services: services,
  };
  return result;
};
