import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as XLSX from "xlsx";

import { Btn } from "../../AbstractElements";
import DownloadBtn from "../DownloadBtn";
import importData from "./importData";
import { toggleRefresh } from "../../redux/searchLink";
import { toggleModal } from "../../redux/modal";
import { useDispatch } from "react-redux";
import { cleanKeys } from "../../helper/csv";
import { useTranslation } from "react-i18next";
import { SEND } from "../../Constant";
import LoadingBtn from "../LoadingBtn";

const ImportExcel = ({ importApi, file_name, handleImport }) => {
  const { t } = useTranslation();
  const [sending, setSending] = useState(false);
  const filePath = require(`../../templates/${file_name}.xlsx`);
  const [file, setFile] = useState(null);
  const [notImpoted, setNotImpoted] = useState(true);
  const dispatch = useDispatch();

  const importedWithSuccess = () => {
    dispatch(toggleRefresh());
    dispatch(toggleModal());
  };

  const handleConvert = async () => {
    setSending(true);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);

        const cleanedJson = cleanKeys(json);
        const finalJSON = JSON.stringify(cleanedJson);

        importData(
          finalJSON,
          importApi,
          handleImport,
          importedWithSuccess,
          setSending
        );
        setFile(null);
      };
      reader.readAsBinaryString(file);
    }
  };

  const handleDownload = () => {
    const a = document.createElement("a");
    a.href = filePath;
    a.download = file_name + ".xlsx";
    a.click();
  };

  const handleImportFile = (e) => {
    setFile(e.target.files[0]);
    setNotImpoted(false);
  };

  return (
    <>
      <CardBody>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Row style={{ marginBottom: "4%" }}>
                    <Col>Download template</Col>
                    <Col>
                      <DownloadBtn onChange={handleDownload} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <input
                        autoComplete="off"
                        type="file"
                        accept=".xlsx"
                        onChange={(e) => handleImportFile(e)}
                      />
                    </Col>
                    <Col className="d-flex justify-content-center">
                      {notImpoted ? (
                        <Btn
                          attrBtn={{
                            onClick: handleConvert,
                            color: "success",
                            className: "me-4",
                            disabled: notImpoted,
                          }}
                        >
                          {t(SEND)}
                        </Btn>
                      ) : (
                        <LoadingBtn
                          isLoading={sending}
                          content={{ loading: "Sending", done: "Send" }}
                          attrBtn={{
                            onClick: handleConvert,
                            color: "success",
                            className: "me-4",
                            disabled: notImpoted,
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </CardBody>
    </>
  );
};

export default ImportExcel;
