import { Fragment } from "react";
import Select from "react-select";
import { Col, FormGroup, Label } from "reactstrap";
import SelectSpinner from "../SelectSpinner";
import useFetchData from "../../hooks/useFetchData";
import { getData } from "../../helper/getData";

const SelectIsMultiApi = ({ register, inputName, oldValue, isDisabled }) => {
  const { name, title, optionsApi, getOptions, extrOptions } = inputName;
  const { data, isLoading, isError } = useFetchData(optionsApi);
  let options = [];
  const handleOnChange = (e) => {
    register({ name: name, value: e });
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#fbdc10" : "#ccc",
      boxShadow: state.isFocused ? "0 0 0 0.2rem #fbdc10" : "none",
    }),
  };

  if (isError) options = [];
  if (!isLoading && !isError) options = getOptions(getData(data));

  if (extrOptions) options.push(extrOptions);

  const defaultVal = getLabel(options, oldValue);

  return (
    <Fragment>
      <Col sm="3">
        <FormGroup>
          <Label>{title}</Label>
          <Select
            isDisabled={isDisabled}
            styles={customStyles}
            placeholder={defaultVal}
            onChange={handleOnChange}
            options={options}
            name={name}
            required
            isMulti
            components={
              isLoading
                ? {
                    DropdownIndicator: SelectSpinner,
                  }
                : {}
            }
          />
        </FormGroup>
      </Col>
    </Fragment>
  );
};
export default SelectIsMultiApi;

function getLabel(options, oldValue) {
  for (const option of options) {
    if (option.value === oldValue) {
      return option.label;
    }
  }
  return "";
}
