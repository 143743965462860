import Translate from "Components/Translate";
import { CANCEL } from "Constant";
import { Button } from "primereact/button";
import React from "react";

import { toggleModal } from "../../redux/modal";
import { useDispatch } from "react-redux";

const CancelModalBtn = () => {
  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(toggleModal());
  };
  return (
    <Button onClick={handleCancel} severity="secondary" type="button">
      <Translate>{CANCEL}</Translate>
    </Button>
  );
};

export default CancelModalBtn;
