import { useState, useEffect } from "react";
import { createMRTColumnHelper } from "material-react-table";
import getRowsFromRes from "../helper/getRowsFromRes";
import axiosClient from "../Services/axiosClient";
import { useParams } from "react-router-dom";

const columnHelper = createMRTColumnHelper();

const usePostData = (url) => {
  const [columns, setColumns] = useState([]);
  
   let { TableName } = useParams();
   if (TableName === ":TableName") {
     TableName = "tx_purchase_order";
   }
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
         setLoading(true);
        const response = await axiosClient.post(url, {
          table_name: TableName,
        });

          const responseData = getRowsFromRes(response);

        if (responseData.length > 0) {
          setColumns(
            Object.keys(responseData[0]).map((key) =>
              columnHelper.accessor(key, {
                header: key,
              })
            )
          );
          setData(responseData);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:");
        setData([]);
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [url,TableName ]);

  return { data, loading, error, columns };
};

export default usePostData;
