import { Fragment } from "react";

import { Card, CardBody } from "reactstrap";

import DataTables from "../../Components/DataTables";
import DataTableHeader from "../../Components/DataTableHeader";
import Breadcrumbs from "../../Components/Breadcrumbs";
import FilterBar from "../../Components/FilterBar";
import EditItem from "../../Components/EditItem";
import ModalApp from "../../Components/ModalApp/index";

import { API, COLUMNS, CREATE_ITEMS, EDIT_ITEMS } from "./data";

import {
  CREATE_SALES_VOUCHER,
  EDIT_SALES_VOUCHER,
  MY_SALES_VOUCHERS,
} from "../../Constant";
import CreateSalesVoucher from "./CreateSalesVoucher";

const SalesVouchersPage = () => {
  const list = API.list;

  const breadCrumbsProps = [MY_SALES_VOUCHERS];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTableHeader createBtn={true} />

          {/* <FilterBar searchByItems={SEARCH_ITEMS} /> */}

          <DataTables newColumns={COLUMNS} link={list} />
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_SALES_VOUCHER}>
        <CreateSalesVoucher />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_SALES_VOUCHER}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default SalesVouchersPage;
