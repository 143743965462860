import { Group } from "@mantine/core";
import EditBtn from "Components/MantineDataTableBtns/EditBtn";
import ReadPageBtn from "Components/MantineDataTableBtns/ReadPageBtn";
import { Category, CATEGORY, CREATED_AT, LABEL } from "Constant";
import { getCodeLabel } from "helper/getOptions";

export const API = {
  list: (brand) => `/back/brand/${brand}/models`,
  create: (brand) => `/back/brand/${brand}/model`,
  get: "/back/model/",
  edit: "/back/model/",
};

export const COLUMNS = [
  {
    name: LABEL,
    selector: "label",
  },
  {
    name: CATEGORY,
    selector: "category",
  },
  {
    name: CREATED_AT,
    selector: "created_at",
  },

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 30,
    Cell: ({ row }) => {
      const { code, label } = row.original;
      return (
        <Group gap="xs">
          {/* <ReadPageBtn read={`/model/${code}`} /> */}
          <EditBtn read={API.get + code} extra={label} />
        </Group>
      );
    },
  },
];
export const CREATE_ITEMS = [
  {
    title: LABEL,
    placeholder: "label",
    name: "label",
    inputForm: "SimpleInput",
  },
  {
    title: CATEGORY,
    name: "category",
    inputForm: "SelectApiInput",
    optionsApi: "/back/categories",
    getOptions: getCodeLabel,
  },
];

export const EDIT_ITEMS = [
  {
    title: LABEL,
    placeholder: "label",
    name: "label",
    inputForm: "SimpleInput",
  },
  {
    title: CATEGORY,
    name: "category",
    inputForm: "SelectApiInput",
    optionsApi: "/back/categories",
    getOptions: getCodeLabel,
  },
];
