import React, { Fragment } from "react";
import { Alert, Card } from "reactstrap";

const Commentry = ({ props }) => {
  console.log(props);

  const text = `Created at ${props.created_at}  by ${props.creator}`;
  return (
    <Fragment>
      <Card style={{ border: "1px solid black" }}>
        <Alert color="worning" fade={true}>
          <h4>{props.commentary}</h4>
          <hr />
          <p className="mb-0">{text}</p>
        </Alert>
      </Card>
    </Fragment>
  );
};

export default Commentry;
