import React, { Fragment } from "react";

import { getData } from "helper/getData";
import useFetchData from "hooks/useFetchDataWithDep";

import Loading from "Components/Loading/Loading";
import Translate from "Components/Translate";

import { List } from "@mantine/core";
import { Skeleton } from "primereact/skeleton";

const DisplayClientDetails = ({ clientCode }) => {
  const url = `/back/client/${clientCode}`;
  const { data, isLoading, isError } = useFetchData(url, clientCode);
  const clientData = getData(data);

  if (isLoading)
    return (
      <p className="m-0">
        <Skeleton className="mb-2"></Skeleton>
        <Skeleton width="10rem" className="mb-2"></Skeleton>
        <Skeleton width="5rem" className="mb-2"></Skeleton>
        <Skeleton height="2rem" className="mb-2"></Skeleton>
        <Skeleton width="10rem" height="4rem"></Skeleton>
      </p>
    );

  const fullName = ` ${clientData.civility} ${clientData.firstname} ${clientData.lastname}`;
  const detailList = [
    {
      title: "Phone",
      value: ` ${clientData.phone} `,
    },
    {
      title: "Company",
      value: ` ${clientData.company}`,
    },
    {
      title: "Registry",
      value: ` ${clientData.registry}`,
    },
  ];
  return (
    <Fragment>
      <List withPadding size="l">
        <List.Item key={fullName}>{fullName}</List.Item>

        {detailList.map((elem, i) => (
          <Fragment key={i}>
            <List.Item>
              <Translate>{elem.title}</Translate>:{elem.value}
            </List.Item>
          </Fragment>
        ))}
      </List>
    </Fragment>
  );
};

export default DisplayClientDetails;
