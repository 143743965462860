// Urls
const GET_VALUES = (code) => `/back/tariff/${code}/values`;
const CREATE_VALUE = (code) => `/back/tariff/${code}/value`;

export const API = {
  get: GET_VALUES,
  create: CREATE_VALUE,
};

// Columns
export const COLUMNS = [
  {
    name: "Distance",
    selector: "fall_distance",
  },
  {
    name: "Time",
    selector: "fall_time",
  },
  {
    name: "Time Price",
    selector: "fall_time_price",
  },
  {
    name: "Distance Price",
    selector: "fall_distance_price",
  },
  {
    name: "Date Begin",
    selector: "date_begin",
  },
];

// Search
export const SEARCH_ITEMS = [
  {
    title: "Fall distance",
    placeholder: "fall_distance",
    name: "fall_distance",
    inputForm: "SimpleInput",
  },
  {
    title: "Fall time",
    placeholder: "fall_time",
    name: "fall_time",
    inputForm: "SimpleInput",
  },
  {
    title: "Fall time_price",
    placeholder: "fall_time_price",
    name: "fall_time_price",
    inputForm: "SimpleInput",
  },
  {
    title: "Fall distance price",
    placeholder: "fall_distance_price",
    name: "fall_distance_price",
    inputForm: "SimpleInput",
  },
  {
    title: "Start ride price",

    placeholder: "start_ride_price",
    name: "start_ride_price",
    inputForm: "SimpleInput",
  },
  {
    title: "Date begin",

    placeholder: "date_begin",
    name: "date_begin",
    inputForm: "DateInput",
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Fall distance",

    placeholder: "fall_distance",
    name: "fall_distance",
    inputForm: "SimpleInput",
  },
  {
    title: "Fall time",

    placeholder: "fall_time",
    name: "fall_time",
    inputForm: "SimpleInput",
  },
  {
    title: "Fall time_price",

    placeholder: "fall_time_price",
    name: "fall_time_price",
    inputForm: "SimpleInput",
  },
  {
    title: "Fall distance price",

    placeholder: "fall_distance_price",
    name: "fall_distance_price",
    inputForm: "SimpleInput",
  },
  {
    title: "Start ride price",

    placeholder: "start_ride_price",
    name: "start_ride_price",
    inputForm: "SimpleInput",
  },
  {
    title: "Date begin",

    placeholder: "date_begin",
    name: "date_begin",
    inputForm: "DateInput",
  },
];

export const EDIT_ITEMS = [
  {
    title: "Fall distance",
    placeholder: "fall_distance",
    name: "fall_distance",
    inputForm: "SimpleInput",
  },
  {
    title: "Fall time",

    placeholder: "fall_time",
    name: "fall_time",
    inputForm: "SimpleInput",
  },
  {
    title: "Fall time price",

    placeholder: "fall_time_price",
    name: "fall_time_price",
    inputForm: "SimpleInput",
  },
  {
    title: "Fall distance price",

    placeholder: "fall_distance_price",
    name: "fall_distance_price",
    inputForm: "SimpleInput",
  },
  {
    title: "Start ride price",
    placeholder: "start_ride_price",
    name: "start_ride_price",
    inputForm: "SimpleInput",
  },
  {
    title: "Date begin",
    placeholder: "date_begin",
    name: "date_begin",
    inputForm: "DateInput",
  },
];
