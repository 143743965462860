import React from "react";
import { Button } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useDispatch } from "react-redux";
import { changeModalType } from "../../redux/modal";
import Translate from "Components/Translate";
import { CREATE } from "Constant";
import { useNavigate } from "react-router-dom";

const CustomActions = () => {
  const navigate = useNavigate();

  const changeState = (type) => {
    navigate("/create-estimate");
  };

  return (
    <Button
      leftSection={<IconPlus size={14} />}
      variant="filled"
      color="teal"
      onClick={() => changeState("create")}
    >
      <Translate>{CREATE}</Translate>
    </Button>
  );
};

export default CustomActions;
