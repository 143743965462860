import { Navigate } from "react-router-dom";
// import getRolesFromJWT from "../helper/getRolesFromJWT";

const ProtectedRoute = ({ allowedRoles, children }) => {
  const userData = localStorage.getItem("userData");
  const { role } = userData ? JSON.parse(userData) : { role: "" };
  // const roles = getRolesFromJWT();

  return allowedRoles.includes(role) ? children : <Navigate to="/home" />;
};

export default ProtectedRoute;
