import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { ThemeProvider } from "styled-components";
import { Save, PlayCircle, List } from "react-feather";
import { Editor } from "@monaco-editor/react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { createTheme } from "@mui/material/styles";
import styles from "../InputQuery/Table.module.css";
import { changeModalType } from "../../redux/modal";
import { Spinner } from "reactstrap";
import {
  setEditorContent,
  setButtonClicked,
} from "../../redux/requeteSql";
import { StyledNavLink } from "../DataTableHeader/StyledNavLink";
import ModalApp from "../ModalApp";
import { API,CREATE_ITEMS} from "../../pages/ListQueryPage/data";
import AddItem from "../AddItem";



const theme = createTheme();

const InputRequete = ({ loading }) => {
  const dispatch = useDispatch();
    const editorContent = useSelector((state) => state.requeteSql.editorContent);
 const sidebarVisible = useSelector((state) => state.requeteSql.sidebarVisible);


  const [editorTheme, setEditorTheme] = useState("light");

  const handleThemeChange = () => {
    setEditorTheme(editorTheme === "vs-dark" ? "light" : "vs-dark");
  };

  const handleButtonClick = () => {
    dispatch(setButtonClicked(true));
  };

  const changeState = (type) => {
    dispatch(changeModalType(type));
  };
  const handleEditorContentChange = (value) => {
    dispatch(setEditorContent(value));
  };


  return (
    <ThemeProvider theme={theme}>
      <Content $sidebarVisible={sidebarVisible}>
        <Box>
          <Editor
            className="editor-container"
            height="44vh"
            width="90%"
            language="sql"
            theme={editorTheme}
            options={{
              minimap: { enabled: false },
              fontSize: 18,
              wordWrap: "on",
            }}
            value={editorContent}
            onChange={handleEditorContentChange}
          />
          <BoxButton>
            <div>
              <Button
                className={styles.button}
                onClick={handleButtonClick}
                // disabled={!editorContent.trim()}
              >
                {loading ? (
                  <>
                    <Spinner style={{ width: "25px", height: "25px" }}>
                      Loading...
                    </Spinner>
                    <span>
                      <span style={{ paddingLeft: "5px" }}>Executing...</span>
                    </span>
                  </>
                ) : (
                  <>
                    <PlayCircle /> Execute
                  </>
                )}
              </Button>
              <Button
                className={styles.button}
                onClick={() => changeState("create")}
              >
                <Save /> Create
              </Button>
              <ModalApp type="create">
                <AddItem addURL={API.CREATE_QUERY} items={CREATE_ITEMS} />
              </ModalApp>
              <Button className={styles.button}>
                <StyledNavLink
                  id="nav-link"
                  className={styles.button}
                  to="/statistics-requests/requests-list"
                >
                  <List /> View list
                </StyledNavLink>
              </Button>
            </div>
            <CustomizedSwitches toggleTheme={handleThemeChange} />
          </BoxButton>
        </Box>
      </Content>
    </ThemeProvider>
  );
};

const Button = styled.button`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    margin: 10px;
  }
`;

const Content = styled.div`
  padding: 10px;
  width: ${({ $sidebarVisible }) => ($sidebarVisible ? "75%" : "96%")};
  @media (max-width: 768px) {
    width: ${({ $sidebarVisible }) => ($sidebarVisible ? "65%" : "85%")};
  }
  @media (max-width: 500px) {
    width: ${({ $sidebarVisible }) => ($sidebarVisible ? "68%" : "0%")};
  }
`;

const Box = styled.div`
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  position: relative;
`;

const BoxButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 17px;
`;

const CustomizedSwitches = ({ toggleTheme }) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <MaterialUISwitch
            sx={{ m: 1 }}
            defaultChecked
            onChange={toggleTheme}
          />
        }
      />
    </FormGroup>
  );
};
const MaterialUISwitch = styled(Switch)`
  width: 62px;
  height: 34px;
  padding: 7px;

  & .MuiSwitch-switchBase {
    margin: 1px;
    padding: 0;
    transform: translateX(6px);

    &.Mui-checked {
      color: #fff;
      transform: translateX(22px);

      & .MuiSwitch-thumb:before {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="%23fff" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>');
      }

      & + .MuiSwitch-track {
        opacity: 1;
        background-color: ${(props) =>
          props.theme.palette.mode === "dark" ? "#8796A5" : "#aab4be"};
      }
    }
  }

  & .MuiSwitch-thumb {
    background-color: ${(props) =>
      props.theme.palette.mode === "dark" ? "#003892" : "#001e3c"};
    width: 32px;
    height: 32px;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="%23fff" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>');
    }
  }

  & .MuiSwitch-track {
    opacity: 1;
    background-color: ${(props) =>
      props.theme.palette.mode === "dark" ? "#8796A5" : "#aab4be"};
    border-radius: 20px;
  }
`;

export default InputRequete;
