import { createTheme, MantineProvider, rem } from "@mantine/core";
import { generateColors } from "@mantine/colors-generator";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "./index.css";
import "./i18n";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MaterialUIControllerProvider } from "./helperContext/context";

import "@mantine/core/styles/global.css";

// ReactDOM.render(<App />, document.getElementById("root"));

const root = ReactDOM.createRoot(document.getElementById("root"));

const customThem = {
  primaryColor: "yellow",
};

const theme = createTheme(customThem);

root.render(
  <MantineProvider theme={theme}>
    <MaterialUIControllerProvider>
      <App />
    </MaterialUIControllerProvider>
  </MantineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
