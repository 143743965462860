import * as React from "react";
import OneTest from "./OneTest";
import { useState } from "react";
import { Fragment } from "react";

export default function Tests() {
  const [state, setState] = useState([""]); // Initialize state with one element

  const addTest = () => {
    setState((prevState) => [...prevState, ""]); // Add a new element to the state array
  };

  const deleteTest = (index) => {
    setState((prevState) => prevState.filter((_, i) => i !== index)); // Remove the element at the specified index
  };

  return (
    <>
      {state.map((elem, i) => (
        <Fragment key={i}>
          <OneTest />
          <button onClick={() => deleteTest(i)}>
            <h2>Delete One</h2>
          </button>
        </Fragment>
      ))}

      <button onClick={addTest}>
        <h2>Add One</h2>
      </button>
    </>
  );
}
