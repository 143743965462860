import React from "react";
import HeaderTabs from "../../Components/Header/HeaderTabs";
import { AiOutlineIdcard } from "react-icons/ai";

const Taximeter = ({ data }) => {
  const requiredKeys = [
    { key: "imei", name: "IMEI", icon: <AiOutlineIdcard /> },
    { key: "meid", name: "MEID", icon: <AiOutlineIdcard /> },

    { key: "serial_number", name: "Serial number", icon: <AiOutlineIdcard /> },
    {
      key: "model_taximeter",
      name: "Model Taximeter",
      icon: <AiOutlineIdcard />,
    },
    {
      key: "brand_taximeter",
      name: "Brand Taximeter",
      icon: <AiOutlineIdcard />,
    },
    {
      key: "active_taximeter",
      name: "Active Taximeter",
      icon: <AiOutlineIdcard />,
    },
    { key: "locked", name: "Locked", icon: <AiOutlineIdcard /> },
    { key: "sticker", name: "Sticker", icon: <AiOutlineIdcard /> },
    {
      key: "sticker_got_at",
      name: "Sticker Got At",
      icon: <AiOutlineIdcard />,
    },
    {
      key: "sticker_expire_at",
      name: "Sticker Expire At",
      icon: <AiOutlineIdcard />,
    },
  ];

  return (
    <HeaderTabs
      objectData={data}
      requiredKeys={requiredKeys}
      title={data.serial_number}
    />
  );
  // return <Header objectData={data} requiredKeys={requiredKeys} />;
};

export default Taximeter;
