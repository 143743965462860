import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { Button } from "primereact/button";

import { Divider, Space } from "@mantine/core";

import Translate from "Components/Translate";
import Breadcrumbs from "Components/Breadcrumbs";
import SegmentedControl from "Components/HorizontalBar/HorizontalBar";

import { EDIT_ESTIMATE, PRODUCT, SERVICE, SUBMIT } from "Constant";

import { refreshEstimate } from "../../redux/estimateSlicer";

import { AddProductBtn, AddServiceBtn } from "./EstimatesBtns";
import EstimateDetails from "./EstimateDetails";
import OneProduct from "./OneProduct";
import OneService from "./OneService";
import onSubmit from "./onSubmitEstimate";
import calculatePrice from "./calculatePrice";
import DisplayClientDetails from "./DisplayClientDetails";
import { clientInp } from "./data";
import TotalResultArray from "./TotalResultArray";
import SelectApiInput from "Components/Inputs/SelectApiInput";

const Main = ({ oldData, code }) => {
  const initState = {
    timescale: oldData.timescale,
    remainingAmount: oldData.remainingAmount,
    deposit: oldData.deposit,
    taxi: oldData.taxi,
  };

  const dispatch = useDispatch();
  const breadCrumbsProps = [`${EDIT_ESTIMATE} N°${oldData.number}`];

  const [client, setClient] = useState(oldData.client || "");
  const [option, setOption] = useState(PRODUCT);
  const [isLoading, setIsLoading] = useState(false);
  const [estimateD, setEstimateD] = useState(initState);
  const [errors, setErrors] = useState({ client: false, taxi: false });

  const { products, services } = useSelector((state) => state.estimate);

  const res = calculatePrice(products, services);

  useEffect(() => {
    return () => {
      dispatch(refreshEstimate());
    };
  }, [dispatch]);

  const remainingAmount = (estimateD.deposit - res.priceVat).toFixed(1);

  const getSendData = () => {
    const sendProd = products
      .map((elem) => {
        if (elem.category && elem.model) {
          return {
            category: elem.category,
            model: elem.model,
            description: elem.description,
            price: elem.price_ht,
            quantity: elem.quantity,
            vat: elem.vat,
            discount: elem.discount,
          };
        }
        return null; // Return null for items that don't meet the condition
      })
      .filter((item) => item !== null); // Filter out null values

    const sendServ = services
      .map((elem) => {
        if (elem.service) {
          return {
            service: elem.service,
            description: elem.description,
            price: elem.price_ht,
            quantity: elem.quantity,
            vat: elem.vat,
          };
        }
        return null; // Return null for items that don't meet the condition
      })
      .filter((item) => item !== null); // Filter out null values

    return {
      client: client,
      taxi: estimateD.taxi,
      price: res.price.toFixed(1),
      vatAmount: res.vatAmount.toFixed(1),
      priceVat: res.priceVat.toFixed(1),
      deposit: estimateD.deposit,
      timescale: estimateD.timescale,
      remainingAmount: remainingAmount,
      products: sendProd,
      services: sendServ,
    };
  };
  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />
          <Divider my="lg" />

          <SelectApiInput
            register={(e) => {
              setErrors((prevState) => ({ ...prevState, client: false }));
              setClient(e.value);
            }}
            inputName={clientInp}
            oldValue={client}
          />
          {errors.client && (
            <span className="text-danger">Client should not be empty </span>
          )}

          {client && <DisplayClientDetails clientCode={client} />}

          <Divider my="lg" />

          <EstimateDetails
            client={client}
            inputs={estimateD}
            register={setEstimateD}
            errors={errors}
            setErrors={setErrors}
            remainingAmount={remainingAmount}
          />

          <Divider my="lg" />

          <SegmentedControl
            options={[PRODUCT, SERVICE]}
            selectedOption={option}
            onChange={setOption}
          />

          <Space h="lg" />

          {option === PRODUCT ? (
            <Fragment>
              {products.map((_, i) => (
                <Fragment key={i}>
                  <OneProduct index={i} />
                </Fragment>
              ))}

              <AddProductBtn />
            </Fragment>
          ) : (
            <Fragment>
              {services.map((_, i) => (
                <Fragment key={i}>
                  <OneService index={i} />
                </Fragment>
              ))}

              <AddServiceBtn />
            </Fragment>
          )}

          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <TotalResultArray />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div>
                  <Button
                    type="submit"
                    label={<Translate>{SUBMIT} </Translate>}
                    severity="success"
                    loading={isLoading}
                    onClick={() => {
                      // setIsLoading(true);
                      console.log(getSendData());

                      onSubmit(
                        getSendData(),
                        `/back/estimate/${code}`,
                        setIsLoading,
                        setErrors
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Main;
