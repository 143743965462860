import { createSlice } from "@reduxjs/toolkit";

export const formInputsSlice = createSlice({
  name: "formInputs",

  initialState: {
    form: {},
    completeData: {},
  },

  reducers: {
    handleChangeFrom: (state, action) => {
      const newInput = action.payload;
      state.form = { ...state.form, ...newInput };
    },

    storeData: (state, action) => {
      const newInput = action.payload;
      state.form = { ...state.form, ...newInput };
    },

    clearForm: (state) => {
      state.form = {};
    },
  },
});

export const { handleChangeFrom, clearForm, storeData } =
  formInputsSlice.actions;

export default formInputsSlice.reducer;
