import { createSlice } from "@reduxjs/toolkit";

const defProd = {
  category: "",
  codes: [],
};

const initialState = {
  client: "",
  taxi: "",
  type: "",
  products: [
    {
      category: "",
      codes: [],
    },
  ],
};

export const salesSlicer = createSlice({
  name: "sales",

  initialState: initialState,

  reducers: {
    addProductForSales: (state) => {
      state.products.push(defProd);
    },

    editProductForSales: (state, action) => {
      const { data, index } = action.payload;
      state.products[index] = { ...state.products[index], ...data };
    },

    editClientData: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },

    clearSale: (state) => {
      state.client = initialState.client;
      state.taxi = initialState.taxi;
      state.type = initialState.type;
      state.products = [...initialState.products];
    },
  },
});

export const {
  addProductForSales,
  editProductForSales,
  editClientData,
  clearSale,
} = salesSlicer.actions;

export default salesSlicer.reducer;
