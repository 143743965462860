import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import AlignHorizontalRightRoundedIcon from "@mui/icons-material/AlignHorizontalRightRounded";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import { ChevronRight, ChevronDown } from "react-feather";
import Loading from "../Loading/Loading";
import Recherche from "./Recherche";
import useFetchData from "../../hooks/useFetchData";
import getRowsFromRes from "../../helper/getRowsFromRes";
import { useSelector } from "react-redux";

const SideBar = () => {
  const sidebarVisible = useSelector(
    (state) => state.requeteSql.sidebarVisible
  );
  const [selectedTable, setSelectedTable] = useState(null);
  const url = "/stat/query/schema";
  const { data, isLoading, isError } = useFetchData(url);
  const [searchQuery, setSearchQuery] = useState("");

  const dataSchema = getRowsFromRes(data);

  const filteredData = Array.isArray(dataSchema)
    ? dataSchema.filter((table) =>
        table.tableName.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  const toggleSelectedTable = (item) => {
    setSelectedTable(selectedTable === item ? null : item);
  };

  return (
    <ContainerDiv>
      <RechercheWrapper $sidebarVisible={sidebarVisible}>
        <Recherche setSearchQuery={setSearchQuery} />
      </RechercheWrapper>
      <SidebarWrapper $sidebarVisible={sidebarVisible}>
        {isLoading && (
          <Center>
              <Loading />
          </Center>
        )}
        {isError && (
          <center>
            <p>Not Found :try again {isError}</p>
          </center>
        )}
        {Array.isArray(filteredData) && filteredData.length > 0 ? (
          <Wrapper>
            {filteredData.map((table, index) => (
              <div key={index}>
                <SidebarItem>
                  <Button1 onClick={() => toggleSelectedTable(table.tableName)}>
                    {selectedTable === table.tableName ? (
                      <ChevronDown size={20} />
                    ) : (
                      <ChevronRight size={20} />
                    )}
                  </Button1>
                  <IconWrapper>
                    <BackupTableIcon fontSize="small" />
                  </IconWrapper>
                  <Link
                    to={`/table-list/${table.tableName}`}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <SpanItem>{table.tableName}</SpanItem>
                  </Link>
                </SidebarItem>
                {selectedTable === table.tableName && (
                  <ColumnList>
                    {table.columns.map((column, index) => (
                      <Container1 key={index}>
                        <SidebarItem>
                          <AlignHorizontalRightRoundedIcon
                          />
                          <SpanColumn>{column}</SpanColumn>
                        </SidebarItem>
                      </Container1>
                    ))}
                  </ColumnList>
                )}
              </div>
            ))}
          </Wrapper>
        ) : (
          <center>
            <p style={{ marginTop: "50%", fontSize: "7px" }}>No results</p>
          </center>
        )}
      </SidebarWrapper>
    </ContainerDiv>
  );
};

export default SideBar;

const Container1 = styled.div`
  display: flex;
  align-items: center;
  padding-top: 0px;
  margin-left: 60px;
`;

const Button1 = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  &:hover {
    background-color: #c8c8c8;
  }
`;

const SidebarItem = styled.div`
  padding: 4px 10px;
  margin-left: 10px;
  cursor: pointer;
  flex-wrap: nowrap;
  font-size: 5px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
`;

const ContainerDiv = styled.div`
  display: flex;
`;

const RechercheWrapper = styled.div`
  background-color: white;
  overflow-y: auto;
  width: ${({ $sidebarVisible }) => ($sidebarVisible ? "24%" : "0%")};
  margin-top: 91px;
  margin-right: 8px;
  position: absolute;
  top: -50px;
  right: 1px;
  transition: width 0.5s;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    width: ${({ $sidebarVisible }) => ($sidebarVisible ? "30%" : "0%")};
  }
  @media (max-width: 500px) {
    width: ${({ $sidebarVisible }) => ($sidebarVisible ? "25%" : "0%")};
    top: -60px;
  }
`;

const ColumnList = styled.ul`
  list-style-type: none;
  padding: 0;
  flex-wrap: nowrap;
  color: #333;
  font-size: 14px;
`;

const SpanColumn = styled.span`
  color: #737373;
  margin-top: 0px;
  padding-left: 5px;
  font-size: 12px;
  padding-right: 5px;
`;

const SpanItem = styled.span`
  color: #3b3b3b;
  padding-right: 10px;
    font-size: 14px;

`;

const IconWrapper = styled.span`
  padding-right: 8px;
  padding-left: 5px;
`;

const SidebarWrapper = styled.div`
  background-color: white;
  overflow-y: auto;
  width: ${({ $sidebarVisible }) => ($sidebarVisible ? "24%" : "0%")};
  margin-top: 91px;
  margin-right: 8px;
  position: absolute;
  top: 32px;
  right: 1px;
  height: 55vh;
  transition: width 0.5s;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    width: ${({ $sidebarVisible }) => ($sidebarVisible ? "30%" : "0%")};
  }
  @media (max-width: 500px) {
    width: ${({ $sidebarVisible }) => ($sidebarVisible ? "25%" : "0%")};
    top: 50px;
  }
`;

const Center = styled.div`
  position: absolute;
  top: 28%;
  left: 30%;
`;

const Wrapper = styled.div``;
