import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { editClientData } from "../../redux/salesSlicer";

import { Group } from "@mantine/core";

import SelectInput from "Components/Inputs/SelectInput";
import MultiSelectApiInput from "Components/Inputs/MultiSelectApiInput";

import { getCodeLabel } from "helper/getOptions";

import { saleOrTry } from "Constant/objs";

const ClientData = () => {
  const dispatch = useDispatch();

  const onChange = (e) => {
    dispatch(editClientData(e));
  };

  return (
    <Fragment>
      <Group mt="xl" grow>
        <MultiSelectApiInput
          register={onChange}
          inputName={{
            title: "Client",
            name: "client",
            inputForm: "MultiSelectApiInput",
            optionsApi: "/back/client",
            getOptions: getCodeLabel,
            secondSelect: (param) => {
              return {
                title: "Taxi",
                name: "taxi",
                optionsApi: `/back/client/${param}/taxis/choices`,
                getOptions: getCodeLabel,
              };
            },
          }}
        />
        <SelectInput inputName={saleOrTry} register={onChange} />
      </Group>
    </Fragment>
  );
};

export default ClientData;
