import { Fragment } from "react";
import { Card, CardBody } from "reactstrap";

import DataTables from "Components/DataTables/DataTable";
import Breadcrumbs from "Components/Breadcrumbs";
import EditItem from "Components/EditItem";
import ModalApp from "Components/ModalApp";
import AddItem from "Components/AddItem";

import { API, COLUMNS, CREATE_ITEMS, EDIT_ITEMS } from "./data";

import { CREATE_SERVICE, EDIT_SERVICE, MY_SERVICES } from "Constant";

const ServicesListPage = () => {
  const list = API.list;
  const breadCrumbsProps = [MY_SERVICES];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTables newColumns={COLUMNS} link={list} globalSearch={true} />
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_SERVICE}>
        <AddItem addURL={API.create} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_SERVICE}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default ServicesListPage;
