import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleRefresh } from "../../redux/searchLink";
import { toggleModal } from "../../redux/modal";

import SelectInput from "../../Components/Inputs/SelectInput";
import { changedAlert } from "../../Components/Alerts/Alerts";

import axiosClient from "../../Services/axiosClient";
import errorMsg from "../../Services/errorMsg";

const SettingsComp = ({ statusFn }) => {
  const [state, setState] = useState("");
  const dispatch = useDispatch();

  const { code } = useSelector((state) => state.currentRow);

  const inputName = {
    title: "Change status",
    name: "status",
    inputForm: "SelectInput",
    options: [
      {
        value: "confirm",
        label: "Confirm",
      },
      {
        value: "prepare",
        label: "Prepare",
      },
      {
        value: "finish",
        label: "Finish",
      },
      {
        value: "close",
        label: "Close",
      },
    ],
  };

  const changedWithSuccess = () => {
    dispatch(toggleRefresh());
    dispatch(toggleModal());
    changedAlert();
  };

  const handleOnChange = (event) => {
    setState(event.value);
  };

  const patchWorkOrder = (newStatus) => {
    const axiosUrl = `/back/workOrder/${code}/${newStatus}`;
    axiosClient
      .patch(axiosUrl)
      .then(() => {
        changedWithSuccess();
      })
      .catch((error) => {
        errorMsg(error);
      });
  };

  useEffect(() => {
    if (state) patchWorkOrder(state);
  }, [state]);

  return <SelectInput register={handleOnChange} inputName={inputName} />;
};

export default SettingsComp;
