import { Fragment } from "react";
import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";
import DeleteBtn from "Components/MantineDataTableBtns/DeleteBtn";
import EditBtn from "Components/MantineDataTableBtns/EditBtn";
import ReadPageBtn from "Components/MantineDataTableBtns/ReadPageBtn";

import { captureObj, civilityObj } from "Constant/objs";
import { fullName } from "helper/fullName";
import { getCodeLabel } from "helper/getOptions";
import { Group } from "@mantine/core";

// Urls
export const API = {
  list: "/back/leads",
  get: "/back/lead/",
  patch: (code) => `/back/lead/${code}/toclient`,
  create: "/back/lead",
  edit: "/back/lead/",
};

// Columns
export const COLUMNS = [
  { name: "First name", selector: "name" },

  { name: "Created at", selector: "created_at" },
  { name: "Capture", selector: "client_capture" },

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 80,
    Cell: ({ row }) => {
      const { name, code } = row.original;
      return (
        <Group gap="xs">
          <ReadPageBtn read={`/leads/${code}`} />
          <EditBtn read={API.get + code} extra={name} />
        </Group>
      );
    },
  },
];

// FB|Instagram|Tiktok|Email|Events|Mouth to ear|Others

// Form
export const CREATE_ITEMS = [
  {
    title: "First Name",
    placeholder: "firstname",
    name: "firstname",
    inputForm: "SimpleInput",
  },
  {
    title: "Last Name",
    placeholder: "lastname",
    name: "lastname",
    inputForm: "SimpleInput",
  },

  civilityObj,
  {
    title: "Birth Date",
    placeholder: "birthDate",
    name: "birthDate",
    inputForm: "DateInput",
  },

  {
    title: "Company",
    placeholder: "company",
    name: "company",
    inputForm: "SimpleInput",
    required: false,
  },
  {
    title: "Registry",
    placeholder: "registry",
    name: "registry",
    inputForm: "SimpleInput",
  },

  {
    title: "Phone",
    placeholder: "phone",
    name: "phone",
    inputForm: "SimpleInput",
  },
  {
    title: "CIN",
    placeholder: "cin",
    name: "cin",
    inputForm: "SimpleInput",
  },
  {
    title: "Address",
    placeholder: "address",
    name: "address",
    inputForm: "SimpleInput",
  },
  {
    title: "City",
    placeholder: "city",
    name: "city",
    inputForm: "SimpleInput",
  },
  {
    title: "Zip Code",
    placeholder: "zipCode",
    name: "zipCode",
    inputForm: "SimpleInput",
  },
  {
    title: "Licence",
    placeholder: "licence",
    name: "licence",
    inputForm: "SimpleInput",
  },

  captureObj,
  {
    title: "Licence Got At",
    placeholder: "licenceGotAt",
    name: "licenceGotAt",
    inputForm: "DateInput",
  },
  {
    title: "Licence Expire At",
    placeholder: "licenceExpireAt",
    name: "licenceExpireAt",
    inputForm: "DateInput",
  },
  {
    title: "Count Driver",
    placeholder: "countDriver",
    name: "countDriver",
    inputForm: "SimpleInput",
  },
  {
    title: "Count Car",
    placeholder: "countCar",
    name: "countCar",
    inputForm: "SimpleInput",
  },

  {
    title: "Commentary",
    placeholder: "commentary",
    name: "commentary",
    inputForm: "TextareaInput",
  },
];

export const EDIT_ITEMS = [
  {
    title: "First Name",
    placeholder: "firstname",
    name: "firstname",
    inputForm: "SimpleInput",
  },
  {
    title: "Last Name",
    placeholder: "lastname",
    name: "lastname",
    inputForm: "SimpleInput",
  },
  {
    title: "Birth Date",
    placeholder: "birthDate",
    name: "birthDate",
    inputForm: "DateInput",
  },
  civilityObj,

  {
    title: "Company",
    placeholder: "company",
    name: "company",
    inputForm: "SimpleInput",
    required: false,
  },
  {
    title: "Registry",
    placeholder: "registry",
    name: "registry",
    inputForm: "SimpleInput",
  },

  {
    title: "Phone",
    placeholder: "phone",
    name: "phone",
    inputForm: "SimpleInput",
  },
  {
    title: "CIN",
    placeholder: "cin",
    name: "cin",
    inputForm: "SimpleInput",
  },
  {
    title: "Address",
    placeholder: "address",
    name: "address",
    inputForm: "SimpleInput",
  },
  {
    title: "City",
    placeholder: "city",
    name: "city",
    inputForm: "SimpleInput",
  },
  {
    title: "Zip Code",
    placeholder: "zipCode",
    name: "zipCode",
    inputForm: "SimpleInput",
  },
  {
    title: "Licence",
    placeholder: "licence",
    name: "licence",
    inputForm: "SimpleInput",
  },

  captureObj,

  {
    title: "Licence Got At",
    placeholder: "licenceGotAt",
    name: "licenceGotAt",
    inputForm: "DateInput",
  },
  {
    title: "Licence Expire At",
    placeholder: "licenceExpireAt",
    name: "licenceExpireAt",
    inputForm: "DateInput",
  },

  {
    title: "Count Driver",
    placeholder: "countDriver",
    name: "countDriver",
    inputForm: "SimpleInput",
  },
  {
    title: "Count Car",
    placeholder: "countCar",
    name: "countCar",
    inputForm: "SimpleInput",
  },

  {
    title: "Commentary",
    placeholder: "commentary",
    name: "commentary",
    inputForm: "TextareaInput",
  },
];
