import { CREATED_AT } from "Constant";
import EditBtn from "../../Components/DataTableBtns/EditBtn";
import { activeObj } from "../../Constant/objs";
import { fullName } from "../../helper/fullName";
import { getCodeLabel } from "../../helper/getOptions";

// API
export const API = {
  list: "/back/salesvouchers",
  get: "/back/salesvoucher",
  create: "/back/salesvoucher",
};

// Columns
export const COLUMNS = [
  {
    name: "Number",
    selector: "number",
  },
  {
    name: "Client",
    selector: "client",
  },
  {
    name: "Taxi",
    selector: "taxi",
  },
  {
    name: "Status",
    selector: "status_label",
  },
  {
    name: CREATED_AT,
    selector: "created_at",
  },

  // {
  //   name: "Edit",
  //   cell: (row) => (
  //     <EditBtn
  //       read={API.get + "/" + row.code}
  //       extra={fullName(row.firstname, row.lastname)}
  //     />
  //   ),
  //   button: "true",
  // },
];

// Search
export const SEARCH_ITEMS = [
  {
    title: "First name",
    placeholder: "First name",
    name: "firstname",
    inputForm: "SimpleInput",
  },
  {
    title: "Last name",
    placeholder: "Last name",
    name: "lastname",
    inputForm: "SimpleInput",
  },
  {
    title: "Email",
    type: "mail",
    placeholder: "Email",
    name: "mail",
    inputForm: "SimpleInput",
  },
  {
    title: "Phone number",
    placeholder: "Phone number",
    name: "phone",
    inputForm: "SimpleInput",
  },
  {
    title: "Groups",
    name: "group",
    inputForm: "SelectInputGroups",
  },
  activeObj,
];

// Form
export const CREATE_ITEMS = [
  {
    title: "First name",
    placeholder: "First name",
    name: "firstname",
    inputForm: "SimpleInput",
  },
  {
    title: "Last name",
    placeholder: "Last name",
    name: "lastname",
    inputForm: "SimpleInput",
  },
  {
    title: "Email",
    type: "mail",
    placeholder: "Email",
    name: "mail",
    inputForm: "SimpleInput",
  },
  {
    title: "Phone number",
    placeholder: "Phone number",
    name: "phone",
    inputForm: "SimpleInput",
  },
  {
    title: "Groups",
    name: "group",
    inputForm: "SelectInputGroups",
  },
  {
    title: "Groups",
    name: "group",
    inputForm: "SelectApiInput",
    optionsApi: "/back/groups",
    getOptions: getCodeLabel,
  },
  {
    title: "active",
    name: "active",
    inputForm: "CheckBox",
  },
];

export const EDIT_ITEMS = [
  {
    title: "First name",
    placeholder: "First name",
    name: "firstname",
    inputForm: "SimpleInput",
  },
  {
    title: "Last name",
    placeholder: "Last name",
    name: "lastname",
    inputForm: "SimpleInput",
  },
  {
    title: "Email",
    type: "mail",
    placeholder: "Email",
    name: "mail",
    inputForm: "SimpleInput",
  },
  {
    title: "Téléphone",
    placeholder: "Téléphone",
    name: "phone",
    inputForm: "SimpleInput",
  },
  {
    title: "Groups",
    name: "group",
    inputForm: "SelectApiInput",
    optionsApi: "/back/groups",
    getOptions: getCodeLabel,
  },
  {
    title: "active",
    name: "active",
    inputForm: "CheckBox",
  },
];
