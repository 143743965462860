import { useParams } from "react-router-dom";
import { Fragment } from "react";
import { Card, CardBody } from "reactstrap";

import DataTables from "../../Components/DataTables";
import DataTableHeader from "../../Components/DataTableHeader";
import Breadcrumbs from "../../Components/Breadcrumbs";
import { COLUMNS } from "./data/users";

import { MY_USERS } from "../../Constant";

const Users = () => {
  const { code } = useParams();

  const list = `/back/distributor/${code}/users`;

  const breadCrumbsProps = [MY_USERS];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTableHeader />

          <DataTables newColumns={COLUMNS} link={list} />
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Users;
