import React from "react";
import { Eye } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Btn } from "../../AbstractElements";

const ReadPageBtn = ({ read: path }) => {
  const navigate = useNavigate();

  return (
    <Btn
      attrBtn={{
        onClick: () => navigate(path),
        color: "success",
        variant: "contained",
        className: "btn btn-secondary btn-sm",
      }}
    >
      <Eye />
    </Btn>
  );
};

export default ReadPageBtn;
