const parsePrice = (price) => parseFloat(price) || 0;
const parseQantity = (price) => parseFloat(price) || 1;

const calculatePrice = (products, services) => {
  const result = {
    vatAmount: 0,
    price: 0,
    priceVat: 0,
  };

  products.forEach((product) => {
    result.price =
      parsePrice(product.price_ht) * parseQantity(product.quantity) +
      result.price;

    result.priceVat =
      parsePrice(product.price_ttc) * parseQantity(product.quantity) +
      result.priceVat;
  });

  services.forEach((service) => {
    result.price =
      parsePrice(service.price_ht) * parseQantity(service.quantity) +
      result.price;

    result.priceVat =
      parsePrice(service.price_ttc) * parseQantity(service.quantity) +
      result.priceVat;
  });

  result.vatAmount = result["priceVat"] - result["price"];

  return result;
};
export default calculatePrice;
