import EditBtn from "../../Components/DataTableBtns/EditBtn";
import DeleteBtn from "../../Components/DataTableBtns/DeleteBtn";
import ExcuteBtn from "../../Components/InputQuery/ExcuteBtn/ExcuteBtn";

export const API = {
  BACKUSERS_LIST: "/stat/query/",
  GET_BACKUSER: "/stat/query/",
  CREATE_QUERY: "/stat/query/create",
  DELETE_QUERY: "/stat/query",
  EDIT_QUERY: "/stat/query",
};

// Columns
export const COLUMNS = [
  {
    name: "Query Name",
    selector: "query_name",
  },
  {
    name: "Description",
    selector: "description",
  },
  {
    name: "Query Code",
    selector: "sql_code",
  },
  {
    name: "Delete",
    cell: (row) => <DeleteBtn delete={API.DELETE_QUERY + "/" + row.code} />,
    button: "true",
  },
  {
    name: "Edit",
    cell: (row) => <EditBtn read={API.EDIT_QUERY + "/" + row.code} />,
    button: "true",
  },
  {
    name: "Excute",
    cell: (row) => <ExcuteBtn sqlQuery={row} />,
    button: "true",
  },
  
];

// Search
export const SEARCH_ITEMS = [
  {
    title: "Query Name",
    placeholder: "Query Name",
    name: "queryName",
    inputForm: "SimpleInput",
  },
  {
    title: "Description",
    placeholder: "description",
    name: "description",
    inputForm: "SimpleInput",
  },
  {
    title: "Query Code",
    placeholder: "Query code ",
    name: "sqlCode",
    inputForm: "SimpleInput",
  }
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Name",
    placeholder: "Name",
    name: "queryName",
    inputForm: "SimpleInput",
  },
  {
    title: "Description",
    placeholder: "description",
    name: "description",
    inputForm: "SimpleInput",
  },
  {
    title: "Query Code",
    placeholder: "Editor",
    name: "sqlCode",
    inputForm: "EditorInput",
  },
];

export const EDIT_ITEMS = [
  {
    title: "Name",
    placeholder: "Name",
    name: "queryName",
    inputForm: "SimpleInput",
  },
  {
    title: "Description",
    placeholder: "description",
    name: "description",
    inputForm: "SimpleInput",
  },
  {
    title: "Editor",
    placeholder: "Editor",
    name: "sqlCode",
    inputForm: "EditorInputEdit",
  },
];

