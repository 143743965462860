import React from "react";
import { FileText } from "react-feather";
import { Btn } from "../../AbstractElements";
import { useDispatch} from "react-redux";
import { changeModalType, changeTitle } from "../../redux/modal";
import { setRowMaterialStock } from "../../redux/currentRow";

const PdfBtn = ({ row, extra }) => {
  const dispatch = useDispatch();

  const handleCart = () => {
    dispatch(changeModalType("PDF"));
    dispatch(changeTitle(extra));
    dispatch(setRowMaterialStock(row));
  };

  return (
    <Btn
      attrBtn={{
        onClick: handleCart,
        color: "success",
        variant: "contained",
        className: "btn btn-secondary btn-sm",
      }}
    >
      <FileText />
    </Btn>
  );
};

export default PdfBtn;
