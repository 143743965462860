export const API = {
  patch: "/back/client/",
};

export const EDIT_ITEMS = [
  {
    title: "First name",
    placeholder: "First name",
    name: "firstname",
    inputForm: "SimpleInput",
  },
  {
    title: "Last name",
    placeholder: "Last name",
    name: "lastname",
    inputForm: "SimpleInput",
  },
  {
    title: "Company",
    placeholder: "Company",
    name: "company",
    inputForm: "SimpleInput",
  },
  {
    title: "Registry",
    placeholder: "Registry",
    name: "registry",
    inputForm: "SimpleInput",
  },
  {
    title: "Phone",
    placeholder: "Phone",
    name: "phone",
    inputForm: "SimpleInput",
  },
  {
    title: "Address",
    placeholder: "Address",
    name: "address",
    inputForm: "SimpleInput",
  },
  {
    title: "Zip code",
    placeholder: "zip_code",
    name: "zip_code",
    inputForm: "SimpleInput",
  },
  {
    title: "City",
    placeholder: "City",
    name: "city",
    inputForm: "SimpleInput",
  },
];
