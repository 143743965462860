export const formItems = [
  {
    title: "First name",
    placeholder: "First name",
    name: "firstname",
    inputForm: "SimpleInput",
  },
  {
    title: "Last name",
    placeholder: "Last name",
    name: "lastname",
    inputForm: "SimpleInput",
  },
  {
    title: "Address",
    placeholder: "Address",
    name: "address",
    inputForm: "SimpleInput",
  },
  {
    title: "Phone",
    placeholder: "Phone",
    name: "phone",
    inputForm: "SimpleInput",
  },
  {
    title: "mail",
    placeholder: "mail",
    name: "mail",
    inputForm: "SimpleInput",
  },
  {
    title: "Taxi number",
    placeholder: "Taxi number",
    name: "taxiNumber",
    inputForm: "SimpleInput",
  },
  {
    title: "Car model",
    placeholder: "Car model",
    name: "carModel",
    inputForm: "SimpleInput",
  },

  {
    title: "Car brand",
    placeholder: "Car brand",
    name: "carBrand",
    inputForm: "SimpleInput",
  },

  {
    title: "Date",
    placeholder: "date",
    name: "date",
    inputForm: "DateInput",
  },
];

export const optionalItems = [
  { title: "titleVal", name: "nameVal", price: 3, number: 1 },
  { title: "titleVal1", name: "nameVal1", price: 23, number: 1 },
];
