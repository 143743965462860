import { Fragment } from "react";
import { Card, CardBody } from "reactstrap";
import {  useSelector } from "react-redux";

import DataTables from "../../Components/DataTables";
import DataTableHeader from "../../Components/DataTableHeader";
import Breadcrumbs from "../../Components/Breadcrumbs";
import FilterBar from "../../Components/FilterBar";
import EditItem from "../../Components/EditItem";
import ModalApp from "../../Components/ModalApp";

import { API, COLUMNS, EDIT_ITEMS, SEARCH_ITEMS } from "./data";

import generateSearchLink from "../../Services/generateSearchLink";
import { QUERY } from "../../Constant";

const QueryListPage = () => {
  const { searchItem } = useSelector((state) => state.searchLink);

  const list = API.BACKUSERS_LIST;
  const searchLink = generateSearchLink(list, searchItem);

  const breadCrumbsProps = [QUERY];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />
          <DataTableHeader createBtnLink={true} retourBtnLink={true} />
          <FilterBar searchByItems={SEARCH_ITEMS} />
          <DataTables newColumns={COLUMNS} link={searchLink} />
        </CardBody>
      </Card>

      <ModalApp type="edit">
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default QueryListPage;