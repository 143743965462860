import React, { Fragment, useState } from "react";
import DataNotFound from "Components/NotFound/DataNotFound";
import { getData } from "helper/getData";
import generateSearchLink from "Services/generateSearchLink";
import useFetchData from "hooks/useFetchDataWithDep";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import CategoryIcon from "@mui/icons-material/Category";
import FilterByCategoryModel from "pages/StatStockPage/FilterByCategoryModel";


const BarChartCategorySales = () => {
  const [inputs, setInputs] = useState({
    dateValue: "",
  });



  const searchItems = {
    date: inputs.dateValue,
  };
  const url = generateSearchLink("/stat/sales/product-turnover", searchItems);
  let labels = [];
  let labelRes = [];
  const { data, isLoading, isError } = useFetchData(url, inputs);

  const params = {
    date: true,
  };

  let dataPie = {};

  const productStatus = getData(data);
  productStatus.forEach((elem) => {
    labels.push(elem.label);
    labelRes.push(elem.data);
  });

  dataPie = {
    labels: labels,
    datasets: [
      {
        label: "Revenue in TND",
        data: labelRes,
        borderWidth: 1,
      },
    ],
  };

  if (isError) return <DataNotFound />;


  return (
    <Fragment>
      <>
        <VerticalBarChart
          icon={{ color: "warning", component: <CategoryIcon /> }}
          height="17rem"
          title="Sales Of Finished Product "
          description="By Product"
          chart={dataPie}
          isLoading={isLoading}
          customComponent={
            <FilterByCategoryModel
              params={params}
              inputs={inputs}
              setInputs={setInputs}
            />
          }
        />
      </>
    </Fragment>
  );
};

export default BarChartCategorySales;
