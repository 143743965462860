import { Fragment } from "react";

import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import DataTables from "../../Components/DataTables";
import DataTableHeader from "../../Components/DataTableHeader";
import Breadcrumbs from "../../Components/Breadcrumbs/SheetOf";
import FilterBar from "../../Components/FilterBar";
import EditItem from "../../Components/EditItem";
import ModalApp from "../../Components/ModalApp";
import AddItem from "../../Components/AddItem";

import { API, COLUMNS, CREATE_ITEMS, EDIT_ITEMS, SEARCH_ITEMS } from "./data";

import { CREATE_MODEL, EDIT_MODEL } from "../../Constant";
import useFetchData from "../../hooks/useFetchData";
import { BiUserCircle } from "react-icons/bi";
import Loading from "../../Components/Loading/Loading";
import NotFound from "../../Components/NotFound";
import HeaderTabs from "../../Components/Header/HeaderTabs";
import { AiOutlineIdcard } from "react-icons/ai";
import ActiveBtn from "../../Components/DataTableBtns/ActiveBtn";

const ReadModelPage = () => {
  const { code } = useParams();
  const list = API.list(code);
  let modelData = {};

  const { data, isLoading, isError } = useFetchData(API.get(code));

  if (isLoading) return <Loading />;

  if (isError) return <NotFound />;

  if (!isLoading && !isError) {
    modelData = data.data.results.data;
  }

  const breadCrumbsProps = [modelData.label];

  const requiredKeys = [
    { key: "label", name: "Label", icon: <BiUserCircle /> },
    { key: "category", name: "Category", icon: <BiUserCircle /> },

    { key: "brand", name: "Brand", icon: <BiUserCircle /> },

    {
      key: "active",
      name: "Active",
      icon: <AiOutlineIdcard />,
      btn: <ActiveBtn patch={API.patch(code)} state={modelData.active} />,
    },

    { key: "external", name: "External", icon: <BiUserCircle /> },
  ];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <Row>
            <Col xs="12" md="3">
              {!isLoading && (
                <HeaderTabs
                  title={modelData.label}
                  objectData={modelData}
                  requiredKeys={requiredKeys}
                />
              )}
            </Col>
            <Col xs="12" md="9">
              <Card>
                <CardBody>
                  <DataTableHeader createBtn={true} />

                  <FilterBar searchByItems={SEARCH_ITEMS} />

                  <DataTables newColumns={COLUMNS} link={list} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_MODEL}>
        <AddItem addURL={API.create(code)} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_MODEL}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default ReadModelPage;
