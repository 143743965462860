import React, { useEffect, useState } from "react";
import { Input, Label, Media } from "reactstrap";
import { areYouSureAlert, changedAlert } from "../Alerts/Alerts";
import axiosClient from "../../Services/axiosClient";
import errorMsg from "../../Services/errorMsg";
import { Switch } from "@mantine/core";

const ActiveBtn = ({ patch, state }) => {
  const [isChecked, setIsChecked] = useState(state);

  useEffect(() => {
    setIsChecked(state);
  }, [state]);

  const changedWithSuccess = () => setIsChecked((prevState) => !prevState);

  const changeState = () => {
    // dispatch(toggleRefresh());
    areYouSureAlert().then((result) => {
      if (result.isConfirmed) {
        const newState = { active: !state };

        axiosClient
          .patch(patch, newState)
          .then((res) => {
            changedAlert();
            changedWithSuccess();
          })
          .catch((error) => {
            console.error("Request failed:", error);
            errorMsg(error);
          });
      }
    });
  };

  return (
    <Switch
      onChange={changeState}
      checked={isChecked}
      defaultChecked
      color="yellow"
    />
  );
};

export default ActiveBtn;
