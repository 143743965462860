import React from "react";
import { Trash } from "react-feather";
import { Btn } from "../../AbstractElements";
import deleteItem from "../../Services/deleteItem";

const DeleteBtn = ({ delete: deleteApi }) => {
  return (
    <Btn
      attrBtn={{
        onClick: () => deleteItem(deleteApi),
        color: "error",
        variant: "contained",
        className: "btn btn-danger btn-sm",
      }}
    >
      <Trash />
    </Btn>
  );
};

export default DeleteBtn;
