import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";

const StepsWithIcon = ({ tabContent, setState, state }) => {
  const { t } = useTranslation();

  const handleClick = (newState) => {
    setState(newState);
  };
  return (
    <Fragment>
      <Row>
        {tabContent.map((elem, i) => {
          return (
            <Fragment key={i}>
              <Col
                onClick={() => handleClick(i)}
                md="4"
                className={i === state ? "u-step current" : "u-step"}
                style={{ cursor: "pointer" }}
              >
                <span className="u-step-icon icon">{elem.icon}</span>
                <div className="u-step-desc">
                  <span className="u-step-title"> {t(elem.name)} </span>
                </div>
              </Col>
            </Fragment>
          );
        })}
      </Row>
    </Fragment>
  );
};

export default StepsWithIcon;
