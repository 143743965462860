import { activeObj } from "../../../Constant/objs";
import { getCodeLabel } from "../../../helper/getOptions";

// Urls
export const API = {
  get: (p) => `/back/profile/${p}/flatfees`,
  create: (p) => `/back/profile/${p}/flatfee`,
  // EDIT_FLATFEE: (p, f) => `/back/profile/${p}/flatfee/${f}`,
  patch: (p) => `/back/flatfee/${p}`,
  // GET_FLATFEE: (p) => `/back/flatfee/${p}`,
  // IMPORT_FLATFEE: (p) => `/back/profile/${p}/flatfees`,
};

// Columns

// Search
export const SEARCH_ITEMS = [
  {
    title: "Label",
    placeholder: "label",
    name: "label",
    inputForm: "TitleInput",
  },
  {
    title: "Price",
    placeholder: "Price",
    name: "price",
    inputForm: "SimpleInput",
  },
  {
    title: "Polygon begin",
    name: "start_polygon",
    inputForm: "SelectApiInput",
    optionsApi: "/back/polygons/choices",
    getOptions: getCodeLabel,
  },
  {
    title: "Polygon end",
    name: "end_polygon",
    inputForm: "SelectApiInput",
    optionsApi: "/back/polygons/choices",
    getOptions: getCodeLabel,
  },
  activeObj,
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Label",
    placeholder: "label",
    name: "label",
    inputForm: "SimpleInput",
  },
  {
    title: "Price",
    placeholder: "Price",
    name: "price",
    inputForm: "NumberInput",
  },
  {
    title: "Polygon begin",
    name: "start_polygon",
    inputForm: "SelectApiInput",
    optionsApi: "/back/polygons/choices",
    getOptions: getCodeLabel,
  },
  {
    title: "Polygon end",
    name: "end_polygon",
    inputForm: "SelectApiInput",
    optionsApi: "/back/polygons/choices",
    getOptions: getCodeLabel,
  },
];

export const EDIT_ITEMS = [
  {
    title: "Label",
    placeholder: "label",
    name: "label",
    inputForm: "SimpleInput",
  },
  {
    title: "Price",
    placeholder: "Price",
    name: "price",
    inputForm: "NumberInput",
  },
  {
    title: "Polygon begin",
    name: "start_polygon",
    inputForm: "SelectApiInput",
    optionsApi: "/back/polygons/choices",
    getOptions: getCodeLabel,
  },
  {
    title: "Polygon end",
    name: "end_polygon",
    inputForm: "SelectApiInput",
    optionsApi: "/back/polygons/choices",
    getOptions: getCodeLabel,
  },
];
