import React from "react";

import Translate from "Components/Translate";
import { Controller } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import useFetchData from "hooks/useFetchData";
import { getData } from "helper/getData";

const SelectApiInput = ({ register, inputName, errors, oldValue, control }) => {
  let options = [];
  const { name, title, optionsApi, getOptions, extrOptions, required, width } =
    inputName;
  const isReq = required === undefined ? true : false;
  const { data, isLoading, isError } = useFetchData(optionsApi);

  if (!isLoading && !isError) options = getOptions(getData(data));
  if (extrOptions) options.push(extrOptions);

  return (
    <div className="col-3">
      <div className="flex flex-column ">
        <label>
          <Translate>{title}</Translate>
          {isReq && <span className="text-danger">*</span>}
        </label>

        <Controller
          name={name}
          control={control}
          defaultValue={oldValue}
          render={({ field }) => (
            <Dropdown
              filter
              filterBy="label"
              loading={false}
              id={field.name}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={options}
              optionLabel="label"
              invalid={errors[name]}
            />
          )}
        />
        {errors[name] && (
          <small className="text-danger">
            <Translate>{errors[name].message}</Translate>
          </small>
        )}
      </div>
    </div>
  );
};

export default SelectApiInput;
