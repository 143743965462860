import React from "react";
import { Btn } from "../../AbstractElements";
import patchItem from "../../Services/patchItem";
import { Row } from "reactstrap";
import { FINISH, VALIDATE } from "../../Constant";
import DoneBtn from "./DoneBtn";

const ChangeStatusPurchaseRequests = ({ row }) => {
  const { to_finish, code, status } = row;
  const isDisbale = status === "CANCELED";

  const isFinish = status === "FINISHED";

  const handleToFinish = () => {
    patchItem(`/back/rawmaterialpr/${code}/finish`);
  };

  const handleToValidate = () => {
    patchItem(`/back/rawmaterialpr/${code}/validate`);
  };

  if (isFinish) return <DoneBtn />;

  if (to_finish)
    return (
      <Btn
        attrBtn={{
          onClick: handleToFinish,
          color: "success",
          variant: "contained",
          disabled: isDisbale,
          className: "btn btn-secondary btn-sm",
        }}
      >
        <Row>{FINISH}</Row>
      </Btn>
    );
  else
    return (
      <Btn
        attrBtn={{
          onClick: handleToValidate,
          color: "secondary",
          variant: "contained",
          disabled: isDisbale,
          className: "btn btn-secondary btn-sm",
        }}
      >
        <Row> {VALIDATE} </Row>
      </Btn>
    );
};

export default ChangeStatusPurchaseRequests;
