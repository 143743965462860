import React, { Fragment, useState } from "react";
import { Card, CardHeader, CardBody, Row } from "reactstrap";
import { Btn, H5, LI, P, UL } from "../../AbstractElements";
import HeaderResult from "./HeaderResult";
import styles from "./Header.module.css";
import { useTranslation } from "react-i18next";

const HeaderTabs = ({ title, objectData, requiredKeys }) => {
  const [isShow, setIsShow] = useState(true);
  const toggleClick = () => {
    setIsShow(!isShow);
  };
  const { t } = useTranslation();
  return (
    <Fragment>
      {/* <Col xl="3" lg="12" md="5" className="xl-35"> */}
      <Row
        className={`${"default-according style-1 faq-accordion job-accordion user-profile"} `}
      >
        <div className={` ${"col-xl-12"} `}>
          <Card data-intro="This is your Your detail" className={`"step4"`}>
            <CardHeader>
              <H5 attrH5={{ className: "p-0" }}>
                <Btn
                  attrBtn={{
                    className: "btn btn-link ps-0",
                    color: "transperant",
                    onClick: toggleClick,
                  }}
                >
                  {t(title)}
                </Btn>
              </H5>
            </CardHeader>
            <div
              className={`collapse ${isShow ? "show" : ""} ${styles["card"]}`}
              id="collapseicon2"
              aria-labelledby="collapseicon2"
              data-parent="#accordion"
            >
              <CardBody className="post-about">
                <UL>
                  {requiredKeys.map((item, i) => (
                    <LI key={i}>
                      <div className="icon">{item.icon}</div>
                      <div>
                        <H5>{t(item.name)}</H5>
                        <P>
                          <HeaderResult objectData={objectData} elem={item} />
                        </P>
                      </div>
                    </LI>
                  ))}
                </UL>
              </CardBody>
            </div>
          </Card>
        </div>
      </Row>
      {/* </Col> */}
    </Fragment>
  );
};

export default HeaderTabs;
