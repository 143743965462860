import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Input } from "reactstrap";
import { addCodes } from "../../redux/selectedRows";

const CheckBox = ({ rowCode }) => {
  const { listOfCodes } = useSelector((state) => state.selectedRows);

  // const [isChecked, setIsChecked] = useState(listOfCodes.includes(rowCode));
  const isChecked = listOfCodes.includes(rowCode);

  const dispatch = useDispatch();

  const handleOnClick = () => {
    dispatch(addCodes(rowCode));
    // setIsChecked((prevState) => !prevState);
  };

  //   useEffect(() => {
  // setIsChecked(listOfCodes.includes(rowCode));
  //   }, [listOfCodes]);
  return (
    <Input
      className="checkbox_animated"
      type="checkbox"
      onClick={handleOnClick}
      defaultChecked={isChecked}
    />
  );
};

export default CheckBox;
