import { Group } from "@mantine/core";

import ReadPageBtn from "Components/MantineDataTableBtns/ReadPageBtn";
import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";
import DeleteBtn from "Components/MantineDataTableBtns/DeleteBtn";
import EditBtn from "Components/MantineDataTableBtns/EditBtn";

import { activeObj, civilityObj } from "Constant/objs";

import { fullName } from "helper/fullName";

// Urls
export const API = {
  list: "/back/collaborators",
  create: "/back/collaborator",
  get: "/back/collaborator/",
  edit: "/back/collaborator/",
  patch: "/back/collaborator/",
  delete: "/back/collaborator/",
};

// Columns
export const COLUMNS = [
  { name: "First name", selector: "firstname" },
  { name: "Last name", selector: "lastname" },
  { name: "Company", selector: "company" },
  { name: "Created at", selector: "created_at" },

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 80,
    Cell: ({ row }) => {
      const { lastname, code, active, firstname } = row.original;
      return (
        <Group gap="xs">
          <ActiveBtn patch={API.patch + code} state={active} />
          <ReadPageBtn read={`/collaborator/${code}`} />
          <EditBtn
            read={API.get + code}
            extra={fullName(firstname, lastname)}
          />
          <DeleteBtn delete={API.delete + code} />
        </Group>
      );
    },
  },
];

// Form

export const CREATE_ITEMS = [
  {
    title: "First name",
    placeholder: "First name",
    name: "firstname",
    inputForm: "SimpleInput",
  },
  {
    title: "Last name",
    placeholder: "Last name",
    name: "lastname",
    inputForm: "SimpleInput",
  },
  {
    title: "Company",
    placeholder: "Company",
    name: "company",
    inputForm: "SimpleInput",
  },
  {
    title: "Phone",
    placeholder: "Phone",
    name: "phone",
    inputForm: "SimpleInput",
  },
  {
    title: "Mail",
    placeholder: "Mail",
    name: "mail",
    inputForm: "SimpleInput",
  },
  {
    title: "Registry",
    placeholder: "Registry",
    name: "registry",
    inputForm: "SimpleInput",
  },
  {
    title: "Address",
    placeholder: "Address",
    name: "address",
    inputForm: "SimpleInput",
  },
  {
    title: "City",
    placeholder: "City",
    name: "city",
    inputForm: "SimpleInput",
  },
  {
    title: "Zip code",
    placeholder: "Zip code",
    name: "zipCode",
    inputForm: "SimpleInput",
  },
  civilityObj,
];
export const EDIT_ITEMS = [
  {
    title: "First name",
    placeholder: "First name",
    name: "firstname",
    inputForm: "SimpleInput",
  },
  {
    title: "Last name",
    placeholder: "Last name",
    name: "lastname",
    inputForm: "SimpleInput",
  },
  {
    title: "Company",
    placeholder: "Company",
    name: "company",
    inputForm: "SimpleInput",
  },
  {
    title: "Phone",
    placeholder: "Phone",
    name: "phone",
    inputForm: "SimpleInput",
  },
  {
    title: "Mail",
    placeholder: "Mail",
    name: "mail",
    inputForm: "SimpleInput",
  },
  {
    title: "Registry",
    placeholder: "Registry",
    name: "registry",
    inputForm: "SimpleInput",
  },
  {
    title: "Address",
    placeholder: "Address",
    name: "address",
    inputForm: "SimpleInput",
  },
  {
    title: "City",
    placeholder: "City",
    name: "city",
    inputForm: "SimpleInput",
  },
  {
    title: "Zip code",
    placeholder: "Zip code",
    name: "zipCode",
    inputForm: "SimpleInput",
  },
  civilityObj,
];
