import React, { Fragment } from "react";
import Header from "../../Components/Header/Header";
import ActiveBtn from "../../Components/DataTableBtns/ActiveBtn";

const Drivers = ({ data }) => {
  const requiredKeys = [
    { key: "full_name", name: "Full name" },
    { key: "user_phone", name: "Phone" },
    { key: "user_mail", name: "Mail" },
    { key: "rfid", name: "RFID" },
    { key: "rfid_serial_number", name: "RFID Serial Number" },
    { key: "enabled", name: "Enabled" },
    { key: "usr_owner", name: "Owner" },
    { key: "usr_licence", name: "Licence" },
    { key: "usr_licence_got_at", name: "Licence Got At" },
    { key: "usr_licence_expire_at", name: "Licence Expire At" },
  ];
  return (
    <Fragment>
      {data.map((elem, i) => {
        const { usr_owner, image } = elem;
        console.log(elem);
        const requiredKeysWithActive = usr_owner
          ? requiredKeys
          : [
              ...requiredKeys,
              {
                key: "active",
                name: "Active",
                btn: (
                  <ActiveBtn
                    patch={`/back/user/${elem.code_user}`}
                    state={elem.user_active}
                  />
                ),
              },
            ];

        const requiredKeysWithImg = image
          ? [
              ...requiredKeysWithActive,
              { key: "image", name: "Image", img: true },
            ]
          : requiredKeysWithActive;

        return (
          <Fragment key={i}>
            {/* <HeaderTabs objectData={elem} requiredKeys={requiredKeys} /> */}
            <Header objectData={elem} requiredKeys={requiredKeysWithImg} />
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export default Drivers;
