import { createSlice } from "@reduxjs/toolkit";
import removeEmptyValues from "../helper/removeEmptyValues";

const indexSize = { index: 1, size: 10 };

export const searchLinkSlice = createSlice({
  name: "searchLink",

  initialState: {
    defaultItems: {
      local: "fr",
    },
    searchItem: {
      index: 1,
      size: 10,
    },
    refresh: false,
    renderDataTable: 0,
  },

  reducers: {
    handleChangeIndex: (state, action) => {
      state.searchItem.index = action.payload;
    },

    handleChangeSize: (state, action) => {
      state.searchItem.size = action.payload.size;
      state.searchItem.index = action.payload.index;
    },

    addSearchItems: (state, action) => {
      const newInput = { ...state.searchItem, ...action.payload, ...indexSize };
      state.renderDataTable = state.renderDataTable + 1;
      state.searchItem = removeEmptyValues(newInput);
    },

    initializeSearchItems: (state) => {
      // Reset the state to its initial values
      state.defaultItems = {
        local: "fr",
      };
      state.searchItem = {
        index: 1,
        size: 10,
      };
      state.refresh = false;
      state.renderDataTable = 0;
    },

    toggleRefresh: (state) => {
      state.refresh = !state.refresh;
    },
  },
});

export const {
  handleChangeIndex,
  handleChangeSize,
  addSearchItems,
  initializeSearchItems,
  toggleRefresh,
  // removeSearchItems,
} = searchLinkSlice.actions;

export default searchLinkSlice.reducer;
