import { useParams } from "react-router-dom";
import { Fragment } from "react";
import { Card, CardBody } from "reactstrap";

import DataTables from "../../Components/DataTables";
import DataTableHeader from "../../Components/DataTableHeader";
import Breadcrumbs from "../../Components/Breadcrumbs";
import EditItem from "../../Components/EditItem";
import ModalApp from "../../Components/ModalApp";
import AddItem from "../../Components/AddItem";
import ActiveBtn from "../../Components/DataTableBtns/ActiveBtn";
import EditBtn from "../../Components/DataTableBtns/EditBtn";

import { API, CREATE_ITEMS, EDIT_ITEMS} from "./data/agency";

import { CREATE_AGENCIES, EDIT_AGENCIES, MY_AGENCIES } from "../../Constant";

const Agency = () => {
  const { code: agencyCode } = useParams();

  const list = API.list(agencyCode);

  const breadCrumbsProps = [MY_AGENCIES];

  const COLUMNS = [
    {
      name: "Phone",
      selector: "phone",
    },
    {
      name: "address",
      selector: "address",
    },
    {
      name: "company",
      selector: "company",
    },
    {
      name: "Active",
      cell: (row) => (
        <ActiveBtn patch={API.get(agencyCode, row.code)} state={row.active} />
      ),
      button: "true",
    },
    {
      name: "Edit",
      cell: (row) => (
        <EditBtn read={API.get(agencyCode, row.code)} extra={row.address} />
      ),
      button: "true",
    },
  ];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTableHeader createBtn={true} />

          {/* <FilterBar searchByItems={SEARCH_ITEMS} /> */}

          <DataTables newColumns={COLUMNS} link={list} />
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_AGENCIES}>
        <AddItem addURL={API.create(agencyCode)} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_AGENCIES}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default Agency;
