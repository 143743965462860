import { Group } from "@mantine/core";

import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";
import ReadPageBtn from "Components/MantineDataTableBtns/ReadPageBtn";

import { civilityObj } from "Constant/objs";

import { getCodeLabel } from "helper/getOptions";

// Urls
export const API = {
  list: "/back/distributors",
  create: "/back/distributor",
};

// Columns
export const COLUMNS = [
  {
    name: "First name",
    selector: "firstname",
  },
  {
    name: "Last name",
    selector: "lastname",
  },
  {
    name: "City",
    selector: "city",
  },
  {
    name: "Phone",
    selector: "phone",
  },
  {
    name: "Address",
    selector: "address",
  },

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 80,
    Cell: ({ row }) => {
      const { code, active } = row.original;
      return (
        <Group gap="xs">
          <ReadPageBtn read={`/distributor/${code}`} />
          <ActiveBtn patch={API.get + code} state={active} />
        </Group>
      );
    },
  },
];

// Search
export const SEARCH_ITEMS = [
  {
    title: "First name",
    placeholder: "First name",
    name: "firstname",
    inputForm: "SimpleInput",
  },
  {
    title: "Last name",
    placeholder: "Last name",
    name: "lastname",
    inputForm: "SimpleInput",
  },
  {
    name: "active",
    title: "Active",
    inputForm: "SelectInput",
    options: [
      {
        value: "",
        label: "all",
        name: "active",
      },
      {
        value: "true",
        label: "active",
        name: "active",
      },
      {
        value: "false",
        label: "not active",
        name: "active",
      },
    ],
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "First name",
    placeholder: "First name",
    name: "firstname",
    inputForm: "SimpleInput",
  },
  {
    title: "Last name",
    placeholder: "Last name",
    name: "lastname",
    inputForm: "SimpleInput",
  },
  {
    title: "Company",
    placeholder: "Company",
    name: "company",
    inputForm: "SimpleInput",
  },
  {
    title: "Phone",
    placeholder: "Phone",
    name: "phone",
    inputForm: "SimpleInput",
  },
  {
    title: "Registry",
    placeholder: "Registry",
    name: "registry",
    inputForm: "SimpleInput",
  },
  {
    title: "Address",
    placeholder: "Address",
    name: "address",
    inputForm: "SimpleInput",
  },
  {
    title: "City",
    placeholder: "City",
    name: "city",
    inputForm: "SimpleInput",
  },
  {
    title: "Zip code",
    placeholder: "Zip code",
    name: "zipCode",
    inputForm: "SimpleInput",
  },
  civilityObj,
];

export const EDIT_ITEMS = [
  {
    title: "First name",

    placeholder: "First name",
    name: "firstname",
    inputForm: "TitleInput",
  },
  {
    title: "Last name",

    placeholder: "Last name",
    name: "lastname",
    inputForm: "TitleInput",
  },
  {
    title: "Email",
    type: "mail",
    placeholder: "Email",
    name: "mail",
    inputForm: "SimpleInput",
  },
  {
    title: "Téléphone",

    placeholder: "Téléphone",
    name: "phone",
    inputForm: "SimpleInput",
  },
  {
    title: "active",
    name: "active",
    inputForm: "CheckBox",
  },
  {
    title: "Groups",
    name: "group",
    inputForm: "SelectApiInput",
    optionsApi: "/back/groups",
    getOptions: getCodeLabel,
  },
  civilityObj,
];
