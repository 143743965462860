import { Fragment } from "react";

import { Card, CardBody } from "reactstrap";

import DataTables from "Components/DataTables/DataTable";
import DataTableHeader from "Components/DataTableHeader";
import EditItem from "Components/EditItem";
import ModalApp from "Components/ModalApp";
import AddItem from "Components/AddItem";

import { API, COLUMNS, CREATE_ITEMS, EDIT_ITEMS } from "./data";

import { CREATE_MODEL, EDIT_MODEL } from "Constant";

const ModelPage = ({ brand }) => {
  const list = API.list(brand);

  const handleImport = (data) => `{"data":${data}}`;

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <DataTables newColumns={COLUMNS} link={list} />
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_MODEL}>
        <AddItem addURL={API.create} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_MODEL}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default ModelPage;
