import { getCarModel, getCodeLabel } from "helper/getOptions";
import ReadPageBtn from "Components/DataTableBtns/ReadPageBtn";
import { N_DRIVER, PROFILE, TAXI, TAXIMETER } from "Constant";
import { z } from "zod";

// Urls
export const API = {
  list: (p) => `/back/client/${p}/taxis`,
  get: (p) => `/back/client/${p}/taxi`,
  edit: (p) => `/back/client/${p}/taxi`,
  create: (p) => `/back/client/${p}/taxi`,
  patch: (p, u) => `/back/client/${p}/taxi/${u}/sell`,
};

// Columns
export const COLUMNS = [
  { name: TAXI, selector: "complete_label" },
  { name: N_DRIVER, selector: "count_drivers" },
  { name: TAXIMETER, selector: "taximeter" },
  { name: PROFILE, selector: "profile" },
  { name: "meid", selector: "meid" },

  {
    name: "Read",
    cell: (row) => <ReadPageBtn read={`/client/taxi/${row.code}`} />,
    button: "true",
  },
];

// Search
export const SEARCH_ITEMS = [
  {
    title: "First name",
    placeholder: "First name",
    name: "firstname",
    inputForm: "SimpleInput",
  },
  {
    title: "Last name",
    placeholder: "Last name",
    name: "lastname",
    inputForm: "SimpleInput",
  },
];

// Form
export const CREATE_ITEMS_TAXI = [
  {
    title: "Registration number",
    placeholder: "Registration number",
    name: "registrationNumber",
    inputForm: "SimpleInput",
    required: false,
  },
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInputGroup",
    optionsApi: "/back/models/choices/car",
    getOptions: getCarModel,
  },
  {
    title: "Profile",
    name: "profile",
    inputForm: "SelectApiInput",
    optionsApi: "/back/profiles/choices",
    getOptions: getCodeLabel,
  },
  {
    title: "Places",
    placeholder: "Places",
    name: "places",
    inputForm: "SimpleInput",
  },
  {
    title: "Serial number",
    placeholder: "serial number",
    name: "serialNumber",
    inputForm: "SimpleInput",
    required: false,
  },
  {
    title: "Licence",
    placeholder: "Licence",
    name: "licenceNumber",
    inputForm: "SimpleInput",
    required: false,
  },
  {
    title: "Licence got at",
    placeholder: "licenceGotAt",
    name: "licenceGotAt",
    inputForm: "DateInput",
    required: false,
  },
  {
    title: "Licence expire at",
    placeholder: "licenceExpireAt",
    name: "licenceExpireAt",
    inputForm: "DateInput",
    required: false,
  },
  {
    title: "Circulation date",
    placeholder: "circulationDate",
    name: "circulationDate",
    inputForm: "DateInput",
    required: false,
  },
];
