import RMStockPage from "../pages/RMStockPage";
import RMInventoryPage from "../pages/RMInventoryPage";
import FPIPage from "../pages/FPIPage";
import FPSPage from "../pages/FPSPage";
import PurchaseRequestsPage from "../pages/PurchaseRequestsPage";

import ReadRMInventory from "../pages/ReadRMInventory";
import ReadWordingPage from "../pages/ReadWordingPage";
import ReadService from "../pages/ReadService";
import ReadModelPage from "../pages/ReadModelPage";
import ReadTaximeterPage from "../pages/ReadTaximeterPage";
import ReadParameterPage from "../pages/ReadParameterPage";
import RMIssues from "../pages/RMIssues";
import RMSlipPage from "../pages/RMSlipPage";
import RMPrimitivesPage from "../pages/RMPrimitivesPage";

const STOCK_ROUTES = [
  {
    path: "/raw-material-stocks",
    element: <RMStockPage />,
    roles: [
      "SUPER_ADMIN",
      "AGENT_PRODUCTION",
      "AGENT_PCB_CONTROL",
      "AGENT_SOFTWARE_CONTROL",
      "AGENT_PACKAGING",
      "ADMIN_PRODUCTION",
      "AGENT_STOCK",
      "ADMIN_STOCK",
      "ADMIN_COMR",
      "ADMIN_STOCK_PRODUCTION",
    ],
  },
  {
    path: "/raw-material-inventory",
    element: <RMInventoryPage />,
    roles: [
      "SUPER_ADMIN",
      "AGENT_PRODUCTION",
      "AGENT_PCB_CONTROL",
      "AGENT_SOFTWARE_CONTROL",
      "AGENT_PACKAGING",
      "ADMIN_PRODUCTION",
      "AGENT_STOCK",
      "ADMIN_STOCK",
      "ADMIN_COMR",
      "ADMIN_STOCK_PRODUCTION",
    ],
  },
  {
    path: "/finished-products-stocks",
    element: <FPSPage />,
    roles: [
      "SUPER_ADMIN",
      "AGENT_PRODUCTION",
      "AGENT_PCB_CONTROL",
      "AGENT_SOFTWARE_CONTROL",
      "AGENT_PACKAGING",
      "ADMIN_PRODUCTION",
      "AGENT_STOCK",
      "ADMIN_STOCK",
      "ADMIN_COMR",
      "ADMIN_STOCK_PRODUCTION",
    ],
  },
  {
    path: "/finished-products-inventory",
    element: <FPIPage />,
    roles: [
      "SUPER_ADMIN",
      "AGENT_PRODUCTION",
      "AGENT_PCB_CONTROL",
      "AGENT_SOFTWARE_CONTROL",
      "AGENT_PACKAGING",
      "ADMIN_PRODUCTION",
      "AGENT_STOCK",
      "ADMIN_STOCK",
      "ADMIN_COMR",
      "ADMIN_STOCK_PRODUCTION",
    ],
  },
  {
    path: "/finished-products-inventory/:code",
    element: <FPIPage />,
    roles: [
      "SUPER_ADMIN",
      "AGENT_PRODUCTION",
      "AGENT_PCB_CONTROL",
      "AGENT_SOFTWARE_CONTROL",
      "AGENT_PACKAGING",
      "ADMIN_PRODUCTION",
      "AGENT_STOCK",
      "ADMIN_STOCK",
      "ADMIN_COMR",
      "ADMIN_STOCK_PRODUCTION",
    ],
  },
  {
    path: "/purchase-requests",
    element: <PurchaseRequestsPage />,
    roles: [
      "SUPER_ADMIN",
      "AGENT_PRODUCTION",
      "AGENT_PCB_CONTROL",
      "AGENT_SOFTWARE_CONTROL",
      "AGENT_PACKAGING",
      "ADMIN_PRODUCTION",
      "AGENT_STOCK",
      "ADMIN_STOCK",
      "ADMIN_COMR",
      "ADMIN_STOCK_PRODUCTION",
    ],
  },
  {
    path: "/raw-material-inventory/:code",
    element: <ReadRMInventory />,
    roles: [
      "SUPER_ADMIN",
      "AGENT_PRODUCTION",
      "AGENT_PCB_CONTROL",
      "AGENT_SOFTWARE_CONTROL",
      "AGENT_PACKAGING",
      "ADMIN_PRODUCTION",
      "AGENT_STOCK",
      "ADMIN_STOCK",
      "ADMIN_COMR",
      "ADMIN_STOCK_PRODUCTION",
    ],
  },
  {
    path: "/wording/:code",
    element: <ReadWordingPage />,
    roles: [
      "SUPER_ADMIN",
      "AGENT_PRODUCTION",
      "AGENT_PCB_CONTROL",
      "AGENT_SOFTWARE_CONTROL",
      "AGENT_PACKAGING",
      "ADMIN_PRODUCTION",
      "AGENT_STOCK",
      "ADMIN_STOCK",
      "ADMIN_COMR",
      "ADMIN_STOCK_PRODUCTION",
    ],
  },

  {
    path: "/model/:code",
    element: <ReadModelPage />,
    roles: [
      "SUPER_ADMIN",
      "AGENT_PRODUCTION",
      "AGENT_PCB_CONTROL",
      "AGENT_SOFTWARE_CONTROL",
      "AGENT_PACKAGING",
      "ADMIN_PRODUCTION",
      "AGENT_STOCK",
      "ADMIN_STOCK",
      "ADMIN_COMR",
      "ADMIN_STOCK_PRODUCTION",
    ],
  },
  {
    path: "/taximeter/:code",
    element: <ReadTaximeterPage />,
    roles: [
      "SUPER_ADMIN",
      "AGENT_PRODUCTION",
      "AGENT_PCB_CONTROL",
      "AGENT_SOFTWARE_CONTROL",
      "AGENT_PACKAGING",
      "ADMIN_PRODUCTION",
      "AGENT_STOCK",
      "ADMIN_STOCK",
      "ADMIN_COMR",
      "ADMIN_STOCK_PRODUCTION",
    ],
  },
  {
    path: "/parameter/:code",
    element: <ReadParameterPage />,
    roles: [
      "SUPER_ADMIN",
      "AGENT_PRODUCTION",
      "AGENT_PCB_CONTROL",
      "AGENT_SOFTWARE_CONTROL",
      "AGENT_PACKAGING",
      "ADMIN_PRODUCTION",
      "AGENT_STOCK",
      "ADMIN_STOCK",
      "ADMIN_COMR",
      "ADMIN_STOCK_PRODUCTION",
    ],
  },

  {
    path: "/raw-material-issues",
    element: <RMIssues />,
    roles: [
      "SUPER_ADMIN",
      "AGENT_PRODUCTION",
      "AGENT_PCB_CONTROL",
      "AGENT_SOFTWARE_CONTROL",
      "AGENT_PACKAGING",
      "ADMIN_PRODUCTION",
      "AGENT_STOCK",
      "ADMIN_STOCK",
      "ADMIN_COMR",
      "ADMIN_STOCK_PRODUCTION",
    ],
  },

  {
    path: "/raw-material-slip",
    element: <RMSlipPage />,
    roles: [
      "SUPER_ADMIN",
      "AGENT_PRODUCTION",
      "AGENT_PCB_CONTROL",
      "AGENT_SOFTWARE_CONTROL",
      "AGENT_PACKAGING",
      "ADMIN_PRODUCTION",
      "AGENT_STOCK",
      "ADMIN_STOCK",
      "ADMIN_COMR",
      "ADMIN_STOCK_PRODUCTION",
    ],
  },

  {
    path: "/raw-material-primitives",
    element: <RMPrimitivesPage />,
    roles: [
      "SUPER_ADMIN",
      "AGENT_PRODUCTION",
      "AGENT_PCB_CONTROL",
      "AGENT_SOFTWARE_CONTROL",
      "AGENT_PACKAGING",
      "ADMIN_PRODUCTION",
      "AGENT_STOCK",
      "ADMIN_STOCK",
      "ADMIN_COMR",
      "ADMIN_STOCK_PRODUCTION",
    ],
  },
];

export default STOCK_ROUTES;
