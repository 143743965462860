import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import styles from "./style.module.css";

const StaticTextTable = ({ requiredData, title }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className={styles["text-table"]}>
        {{ title } && (
          <div className={styles["text-table-title"]}> {t(title)} </div>
        )}
        {requiredData.map((elem, index) => (
          <div key={index} className={styles["text-table-item"]}>
            <span>{t(elem.label)}</span>
            <span>{t(elem.value)}</span>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default StaticTextTable;
