const getRowsFromRes = (data) => {
  try {
    let cleanData = data.data.results.data;
    if ("rows" in cleanData) {
      return {
        total: cleanData.total,
        rows: cleanData.rows,
      };
    } else {
      cleanData = [cleanData];
      return {
        total: cleanData.length,
        rows: cleanData,
      };
    }
  } catch {
    return [];
  }
};
export default getRowsFromRes;
