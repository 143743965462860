import ReadPageBtn from "../../Components/DataTableBtns/ReadPageBtn";
import { getCodeLabel } from "../../helper/getOptions";

// Urls
export const API = {
  list: "/back/rawmaterialinventories",
  // GET_INVENTORY: "/back/rawmaterialinventory/",
  create: "/back/rawmaterialinventory",
  // CANCEL_INVENTORY: "/back/rawmaterialinventory/",
  // FINISH_INVENTORY: "/back/rawmaterialinventory/",
  // CREATE_INVENTORY_ITEM: "/back/rawmaterialinventory/",
  // GET_CATEGORIES: "/back/rawmaterial-inventory-categories",
  // GET_MODELS: "/back/rawmaterial/PCB_SENSOR/models",
  // GET_STATUSES: "/back/rawmaterial-inventory-statuses",
};

// Columns
export const COLUMNS = [
  { name: "Number", selector: "number" },
  { name: "Category", selector: "category" },
  { name: "Models", selector: "models" },
  { name: "Scanned", selector: "scanned" },
  { name: "Created at", selector: "created_at" },
  {
    name: "Read",
    cell: (row) => <ReadPageBtn read={`/raw-material-inventory/${row.code}`} />,
    button: "true",
  },
];

// Search
export const SEARCH_ITEMS = [
  {
    title: "Category",
    name: "category",
    inputForm: "SelectApiInput",
    optionsApi: "/back/inventory-categories",
    getOptions: getCodeLabel,
  },
];
// Form
export const CREATE_ITEMS = [
  {
    title: "Category",
    name: "category",
    inputForm: "SelectApiInput",
    optionsApi: "/back/rawmaterial-inventory-categories",
    getOptions: getCodeLabel,
  },
];
export const EDIT_ITEMS = [
  {
    title: "Category",
    name: "category",
    inputForm: "SelectApiInput",
    optionsApi: "/back/rawmaterial-inventory-categories",
    getOptions: getCodeLabel,
  },
];
