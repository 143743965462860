import React from "react";
import { XCircle } from "react-feather";
import { Btn } from "../../AbstractElements";
import deleteItem from "../../Services/deleteItem";

const CancelBtn = ({ cancel: cancelApi, isDisable }) => {
  return (
    <Btn
      attrBtn={{
        disabled: isDisable,
        onClick: () => deleteItem(cancelApi),
        color: "error",
        variant: "contained",
        className: "btn btn-danger btn-sm",
      }}
    >
      <XCircle />
    </Btn>
  );
};

export default CancelBtn;
