import React, { Fragment } from "react";

import DataTables from "../../../Components/DataTables/DataTable";

import { API, COLUMNS } from "./data";

const DeleteRequests = () => {
  const list = API.list;

  return (
    <Fragment>
      <DataTables
        newColumns={COLUMNS}
        link={list}
        createBtn={false}
        globalSearch={false}
      />
    </Fragment>
  );
};

export default DeleteRequests;
