import { Card, CardBody, Row, Col, Alert } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Fragment, useState } from "react";

import StepsWithIcon from "../../Components/LeftTab/StepsWithIcon";
import ActiveBtn from "../../Components/DataTableBtns/ActiveBtn";
import HeaderTabs from "../../Components/Header/HeaderTabs";
import Loading from "../../Components/Loading/Loading";
import Breadcrumbs from "../../Components/Breadcrumbs/SheetOf";
import NotFound from "../../Components/NotFound";

import useFetchData from "../../hooks/useFetchDataWithDep";
import { DRIVERS, EDIT_CLIENT, TAXIS } from "../../Constant/index";

import { API, EDIT_ITEMS } from "./data/data";

import { RiTaxiFill } from "react-icons/ri";
import { FaAddressCard, FaCity } from "react-icons/fa";
import { AlignCenter, MapPin } from "react-feather";
import { HiHomeModern } from "react-icons/hi2";
import { BiSolidUserDetail, BiUserCircle } from "react-icons/bi";
import { BsFillTelephoneForwardFill } from "react-icons/bs";
import {
  AiOutlineFileDone,
  AiOutlineHome,
  AiOutlineIdcard,
} from "react-icons/ai";
import { fullName } from "../../helper/fullName";
import ModalApp from "../../Components/ModalApp";
import EditItem from "../../Components/EditItem";
import EditBtn from "./EditBtn";
import { useSelector } from "react-redux";
import Commentry from "./Commentry";

const ReadLeadsPage = () => {
  const { t } = useTranslation();
  const { code } = useParams();
  const [state, setState] = useState(0);
  const stat = useSelector((state) => state.modal.isOpen);
  let clientData;

  const url = `/back/lead/${code}`;
  const { data, isLoading, isError } = useFetchData(url, stat);

  if (isLoading) return <Loading />;

  if (isError) return <NotFound />;

  if (!isLoading && !isError) {
    clientData = data.data.results.data;
  }

  const dynamicPart = clientData
    ? fullName(clientData.firstname, clientData.lastname)
    : null;

  const requiredKeys = [
    {
      key: "company",
      name: "Company",
      icon: <HiHomeModern />,
    },
    { key: "firstname", name: "Firstname", icon: <BiUserCircle /> },
    { key: "lastname", name: "Lastname", icon: <BiUserCircle /> },
    { key: "phone", name: "Phone", icon: <BsFillTelephoneForwardFill /> },
    { key: "registry", name: "Registry", icon: <BiSolidUserDetail /> },
    { key: "address", name: "Address", icon: <AiOutlineHome /> },
    { key: "city", name: "City", icon: <FaCity /> },
    { key: "zipCode", name: "ZipCode", icon: <MapPin /> },
  ];

  const editBtn = (
    <EditBtn
      read={url}
      // extra={fullName(row.firstname, row.lastname)}
    />
  );

  const breadCrumbsProps = [dynamicPart, editBtn];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <Row>
            <Col xs="12" md="3">
              {!isLoading && (
                <HeaderTabs
                  title={dynamicPart}
                  objectData={clientData}
                  requiredKeys={requiredKeys}
                />
              )}
            </Col>
            <Col xs="12" md="9">
              <Card>
                <CardBody>
                  {clientData.commentaries.map((elem) => (
                    <Commentry props={elem} />
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <ModalApp type="editClient" title={EDIT_CLIENT}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default ReadLeadsPage;
