import { Fragment, useState } from "react";
import { Form, ModalFooter, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import useFetchData from "../../hooks/useFetchData";
import handleInputs from "../../helper/handleInputs";

import Loading from "../Loading/Loading";
import FilterInput from "../Inputs";
import editData from "./editData";

import { toggleRefresh } from "../../redux/searchLink";
import { toggleModal } from "../../redux/modal";
// import NotFound from "../NotFound";
import { Divider } from "@mantine/core";
import CancelModalBtn from "Components/ModalApp/CancelModalBtn";
import { Button } from "primereact/button";

function deleteNestedObjects(obj) {
  try {
    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        deleteNestedObjects(obj[key]);
        delete obj[key];
      }
    }
  } catch (error) {}
}

const EditItem = ({ itemsUpdate }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getLink, putLink } = useSelector((state) => state.currentRow);
  const { data, isLoading, isError } = useFetchData(getLink);
  const [isSending, setIsSending] = useState(false);
  const [inputs, setInputs] = useState({});
  let oldData = [];

  if (isLoading) return <Loading />;
  else {
    // if (isError) return <NotFound />;

    try {
      oldData = data.data.results.data;
    } catch (error) {
      oldData = [];
    }

    deleteNestedObjects(oldData);

    const editedWithSuccess = () => {
      dispatch(toggleRefresh());
      dispatch(toggleModal());
    };

    const onSubmit = (event) => {
      setIsSending(true);
      event.preventDefault();

      const axiosUrl = putLink || getLink;
      editData(
        inputs,
        oldData,
        itemsUpdate,
        axiosUrl,
        setIsSending,
        editedWithSuccess
      );
    };

    return (
      <>
        <Form onSubmit={onSubmit}>
          <Row>
            {itemsUpdate.map((item, index) => {
              return (
                <Fragment key={index}>
                  <FilterInput
                    item={item}
                    handleChange={(e) => handleInputs(e, setInputs)}
                    oldValue={oldData[item.name]}
                  />
                </Fragment>
              );
            })}
          </Row>

          <Divider my="md" />
          <ModalFooter>
            <CancelModalBtn />
            <Button
              type="submit"
              label="Go"
              severity="success"
              loading={isSending}
              onClick={() => onSubmit}
            />
          </ModalFooter>
        </Form>
      </>
    );
  }
};

export default EditItem;
