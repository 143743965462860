import React, { Fragment } from "react";
import { Card } from "reactstrap";
import { Btn, LI } from "../../../AbstractElements";
import { LogOut } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useSignOut } from "react-auth-kit";
import { LOG_OUT } from "../../../Constant";
import { useTranslation } from "react-i18next";

const LogoutClass = () => {
  const { t } = useTranslation();

  const signOut = useSignOut();
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut();
    navigate("/login");
    localStorage.removeItem("userData");
  };

  return (
    <Fragment>
      <LI attrLI={{ className: "onhover-dropdown p-0" }}>
        <Btn
          attrBtn={{
            onClick: handleLogout,
            as: Card.Header,
            className: "btn btn-primary-light",
            color: "default",
          }}
        >
          <LogOut />
          {t(LOG_OUT)}
        </Btn>
      </LI>
    </Fragment>
  );
};

export default LogoutClass;
