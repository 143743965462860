import { createSlice } from "@reduxjs/toolkit";

export const modalSlice = createSlice({
  name: "modal",

  initialState: {
    isOpen: false,
    modalType: "",
    modalTitle: "",
  },

  reducers: {
    toggleModal: (state) => {
      state.isOpen = !state.isOpen;
    },

    changeModalType: (state, action) => {
      state.isOpen = true;
      state.modalType = action.payload;
    },

    changeTitle: (state, action) => {
      state.modalTitle = action.payload !== undefined ? action.payload : "";
    },

    resetTitle: (state) => {
      state.modalTitle = "";
    },
  },
});

export const { toggleModal, changeModalType, changeTitle, resetTitle } =
  modalSlice.actions;

export default modalSlice.reducer;
