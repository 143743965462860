import { Group } from "@mantine/core";
import ReadPageBtn from "Components/MantineDataTableBtns/ReadPageBtn";
import { CATEGORY, LABEL, PRICE, UPDATED_AT } from "Constant";
import { getCodeLabel } from "helper/getOptions";

// Urls
export const API = {
  list: "/back/models/prices",
  create: "/back/brand/taxiora/model",
};

// Columns
export const COLUMNS = [
  { name: CATEGORY, selector: "model_category" },
  { name: LABEL, selector: "model_label" },
  { name: PRICE, selector: "price" },
  { name: UPDATED_AT, selector: "updated_at" },

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 60,
    Cell: ({ row }) => {
      const { model_code } = row.original;
      return (
        <Group gap="xs">
          <ReadPageBtn read={`/pricing-product/${model_code}`} />
        </Group>
      );
    },
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Label",
    placeholder: "label",
    name: "label",
    inputForm: "SimpleInput",
  },
  {
    title: CATEGORY,
    name: "category",
    inputForm: "SelectApiInput",
    optionsApi: "/back/categories",
    getOptions: getCodeLabel,
  },
];

export const EDIT_ITEMS = [
  {
    title: "Label",
    placeholder: "label",
    name: "label",
    inputForm: "SimpleInput",
  },
  {
    title: "Category",
    placeholder: "category",
    name: "category",
    inputForm: "SimpleInput",
  },
];

// label
// category
