import React from "react";
import useFetchData from "hooks/useFetchData";
import { getData } from "helper/getData";
import ProductCountStock from "./ProductCountStock";
import StatisticLoading from "Components/Loading/StatisticLoading";
import Grid from "@mui/material/Grid";

const Products = () => {
  const url = "/back/product-categories";
  const { data, isLoading, isError } = useFetchData(url);
  const productCategory = getData(data);

  if (isLoading) {
    return <StatisticLoading />;
  }

  if (isError) {
    return <div>Error loading data</div>;
  }

  

  return (
    <Grid container spacing={3}>
      {productCategory.map(
        (elem, i) =>
          elem.code !== "car" && (
              <ProductCountStock key={i} name={elem} />
          )
      )}
    </Grid>
  );
};

export default Products;
