import React, { Fragment, useState } from "react";
import {Row, Col } from "reactstrap";
import MapChart from "./MapChart";
import Grid from "@mui/material/Grid";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";

import { useTranslation } from "react-i18next";
import MDBox from "Components/MDBox";
import ReservationIndicator from "./data/ReservationIndicator";
import FilterByCategoryModel from "pages/StatStockPage/FilterByCategoryModel";
import BarChartStartRate from "./data/BarChartStartRate";
import Reclamation from "./data/Reclamation";

const StatReservationPage = () => {
  const { t } = useTranslation();
  const [inputs, setInputs] = useState({
    statusBook: "",
    dateValue: "",

  });

  const params = {
    date: true,
    statusBooking: true,
  };

  return (
    <>
      <DashboardNavbar />
      <Row>
        <Fragment>
          <Row>
            <Col>
              <FilterByCategoryModel
                params={params}
                inputs={inputs}
                setInputs={setInputs}
              />
            </Col>
          </Row>
        </Fragment>
      </Row>
      <MDBox py={3}>
        <Grid container spacing={2}>
          <ReservationIndicator inputs={inputs} />
        </Grid>
        <Fragment>
          <MapChart />
        </Fragment>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <BarChartStartRate />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
              <MDBox mb={3}>
                <Reclamation />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </>
  );
};

export default StatReservationPage;
