import React, { Fragment } from "react";
import useFetchData from "hooks/useFetchDataWithDep";
import { getData } from "helper/getData";
import generateSearchLink from "Services/generateSearchLink";
import NotFound from "Components/NotFound";
import ConstructionIcon from "@mui/icons-material/Construction";

import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { Codepen } from "react-feather";
import StatisticLoading from "Components/Loading/StatisticLoading";

const CountMaterialStock = ({ inputs, params }) => {

 let category = inputs.category || "";
 let cat = category.toUpperCase();

  const searchItems = {
    product: cat,
    model: inputs.model,
    status: inputs.status,
  };


  const url = generateSearchLink("/stat/stock/count", searchItems);
  const { data, isLoading, isError } = useFetchData(url, inputs);
  if (isError) return <NotFound />;

  console.log(url);

  const productStatus = getData(data) ?? [];

  const totalAmount =
    productStatus.length > 0 ? productStatus[0].count_stock : 0;
  const message = productStatus.length > 0 ? productStatus[0].message : "";
  const color = message.includes("is sufficient") ? "success" : "error";
const messag = `the amount used of ${inputs.category}`;

function getToday() {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const year = today.getFullYear();

  return `${day}/${month}/${year}`;
} 

  return (
    <Fragment>
      {!isLoading && (
        <>
          {params.material && (
            <ComplexStatisticsCard
              color="dark"
              icon={<Codepen />}
              title="Stock Of Finished Product"
              count={totalAmount}
              percentage={{
                color: color,
                label: getToday(),
                amount: message,
              }}
            />
          )}
          {params.materialUsed && (
            <ComplexStatisticsCard
              color="primary"
              icon=<ConstructionIcon />
              title="Stock Of Finished Product "
              count={totalAmount}
              percentage={{
                color: "secondary",
                amount: messag,
                label: getToday(),
              }}
            />
          )}
        </>
      )}
      {isLoading && <StatisticLoading />}
    </Fragment>
  );
};

export default CountMaterialStock;
