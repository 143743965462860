import { z } from "zod";

// Urls
export const API = {
  list: (code) => `/back/service/${code}/prices`,
  create: (code) => `/back/service/${code}/price`,
};

// Columns
export const COLUMNS = [
  { name: "Price", selector: "price" },
  { name: "Date begin", selector: "date_begin" },
  { name: "Date end", selector: "date_end" },
  { name: "Created at", selector: "created_at" },
];

export const SEARCH_ITEMS = [
  {
    title: "Price",
    placeholder: "price",
    name: "price",
    inputForm: "SimpleInput",
  },
  {
    title: "Date Begin",
    placeholder: "date_begin",
    name: "date_begin",
    inputForm: "DateInput",
  },
  {
    title: "Date End",

    placeholder: "date_end",
    name: "date_end",
    inputForm: "DateInput",
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Price",
    placeholder: "price",
    name: "price",
    inputForm: "NumberInput",
    schema: z.number().min(1),
  },
  {
    title: "TVA %",
    placeholder: "tva",
    name: "vat",
    inputForm: "NumberInput",
    schema: z
      .number()
      .min(0, { message: "Le nombre doit être supérieur ou égal à 0." })
      .max(100, { message: "Le nombre doit être inférieur ou égal à 100." }),
  },
  {
    title: "Date Begin",

    placeholder: "date_begin",
    name: "date_begin",
    inputForm: "DateInput",
  },
  {
    title: "Date End",
    placeholder: "date_end",
    name: "date_end",
    inputForm: "DateInput",
    required: false,
  },
];

export const EDIT_ITEMS = [
  {
    title: "Price",
    placeholder: "price",
    name: "price",
    inputForm: "SimpleInput",
  },
  {
    title: "TVA %",
    placeholder: "tva",
    name: "vat",
    inputForm: "SimpleInput",
  },
  {
    title: "Date Begin",

    placeholder: "date_begin",
    name: "date_begin",
    inputForm: "DateInput",
  },
  {
    title: "Date End",

    placeholder: "date_end",
    name: "date_end",
    inputForm: "DateInput",
  },
];
