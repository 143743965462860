import React from "react";
import styles from "./estimate.module.css";
import useFetchData from "../../hooks/useFetchData";

import getRowsFromResClient from "../../helper/getRowsFromResClient";

const ClientsDetails = ({ clientCode }) => {
  const {
    data: clientsData,
    isLoading,
    isError,
  } = useFetchData("/back/client/" + clientCode + "/sheet");

  let clientData;
  if (isLoading) return "...";

  if (isError) return null;

  if (!isLoading && !isError) {
    clientData = getRowsFromResClient(clientsData);
  }
  if (clientsData.length)
    return (
      <div>
        <p className={styles["addressDriver"]}>
          <strong>{clientData.registry}</strong>
          <br />
          <em>
            {clientData.firstname} {clientData.lastname}
          </em>
          <br />
          <span>{clientData.phone}</span>
          <br />
          <span>{clientData.address}</span>
          <br />
          <span>
            {clientData.city} {clientData.zip_code}
          </span>
        </p>
      </div>
    );
};

export default ClientsDetails;
