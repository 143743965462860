import { Fragment } from "react";
import {
  Group,
  Avatar,
  Text,
  Accordion,
  Badge,
  Center,
  Indicator,
  Space,
  Flex,
  Grid,
} from "@mantine/core";

import ListOfData from "./ListOfData";
import { IconCrown } from "@tabler/icons-react";

import { CreateDriver, DriverSettingsBtn } from "./SettingsBtn";
import { fullName } from "helper/fullName";

import HeaderTab from "./HeaderTab";
import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";

function DriversInfo({ data }) {
  const accordion = data.map((elem, i) => {
    const driverName = fullName(elem.user_firstname, elem.user_lastname);

    const { created_at, user_phone, image } = elem;
    const id = i.toString();

    const title = elem.usr_owner ? (
      <>
        <Text fw={700}>
          <Badge
            variant="gradient"
            gradient={{ from: "yellow", to: "orange", deg: 90 }}
            leftSection={<IconCrown size={14} />}
          >
            <Text size="lg">{driverName}</Text>
          </Badge>
        </Text>
      </>
    ) : (
      <Text fw={700}>
        <Badge variant="dot" color="gray">
          <Text size="lg">{driverName}</Text>
        </Badge>
      </Text>
    );

    const indicatorProps = elem.user_active
      ? { color: "green", label: "Active" }
      : { color: "red", label: "Not active" };

    return (
      <Accordion.Item value={id} key={id}>
        <Center>
          <Accordion.Control>
            <Group wrap="nowrap">
              <Indicator
                inline
                // label={indicatorProps.label}
                color={indicatorProps.color}
                // size={16}
              >
                {image && <Avatar src={image} radius="xl" size="lg" />}
                {!image && (
                  <Avatar radius="xl" size="lg" color="orange"></Avatar>
                )}
              </Indicator>

              <div>
                <Text size="xl">{title}</Text>
                <Text size="sm" c="dimmed" fw={400}>
                  {`phone number: ${user_phone} created at: ${created_at}`}
                </Text>
              </div>
              {/* <Grid>
                <Grid.Col>
                  <Text size="xl">{title}</Text>
                </Grid.Col>
                <Grid.Col>
                  <ActiveBtn
                    state={"active_taxi"}
                    patch={"url"}
                    refresh={"refresh"}
                  />
                </Grid.Col>
              </Grid> */}
            </Group>
          </Accordion.Control>
          <DriverSettingsBtn data={elem} />

          <Space w="xl" />
        </Center>

        <Accordion.Panel>
          <ListOfData driverDetails={elem} />
        </Accordion.Panel>
      </Accordion.Item>
    );
  });

  return (
    <Fragment>
      <HeaderTab tab={[<CreateDriver />]} />
      <Accordion chevronPosition="left" variant="contained">
        {accordion}
      </Accordion>
    </Fragment>
  );
}

export default DriversInfo;
