import { Fragment } from "react";
import { Card } from "reactstrap";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";

const StaticDataTable = ({ newColumns, newRows }) => {
  const { t } = useTranslation();
  const columns = transformColumns(newColumns, t);

  const customStyles = {};

  return (
    <Fragment>
      <Card>
        <DataTable
          columns={columns}
          data={newRows}
          striped="true"
          center="true"
          customStyles={customStyles}
        />
      </Card>
    </Fragment>
  );
};

export default StaticDataTable;
function transformColumns(columns, t) {
  if (!columns) return [];
  return columns.map((column) => {
    if (column.button) return column;
    const transformedColumn = {
      name: t(column.name),
      selector: (row) => row[column.selector],
      sortable: "true",
      center: "true",
    };
    return transformedColumn;
  });
}
