import React, { useState, useEffect } from "react";
import { QRCodeSVG } from "qrcode.react";
import { renderToString } from "react-dom/server";
import canvg from "canvg";
import { Image } from "@react-pdf/renderer";

const svgToDataUri = async (svgString) => {
  try {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    if (context) {
      const v = canvg.fromString(context, svgString.trim());

      await v.render();

      const dataUri = canvas.toDataURL("image/png");

      return dataUri;
    }
  } catch (error) {
    console.error("Error occurred:", error);
    return "";
  }
};

const SVGImage = (props) => {
  const [svgDataUri, setSvgDataUri] = useState("");

  useEffect(() => {
    async function convertSvgToDataUri() {
      const svg = <QRCodeSVG value={props.qrString} />;

      const dataUri = await svgToDataUri(renderToString(svg));
      setSvgDataUri(dataUri || "");
    }
    convertSvgToDataUri();
  }, [props.qrString]);

  return <Image src={svgDataUri} style={{ width: "35mm", height: "35mm" }} />;
};

export default SVGImage;
