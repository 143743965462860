import React from "react";
import { InputNumber } from "primereact/inputnumber";
import Translate from "Components/Translate";
import { Controller } from "react-hook-form";

const NumberInput = ({ inputName, errors, oldValue, control }) => {
  const { name, title, placeholder, required } = inputName;
  const isReq = required === undefined ? true : false;

  return (
    <div className="col-3">
      <div className="flex flex-column">
        <label htmlFor={name}>
          <Translate>{title}</Translate>
          {isReq && <span className="text-danger">*</span>}
        </label>
        <Controller
          name={name}
          control={control}
          defaultValue={oldValue || ""} // Set default value from oldValue or empty string
          rules={{
            setValueAs: (value) => (value ? Number(value) : ""), // Convert value to number
            required: isReq && "This field is required", // Apply validation
          }}
          render={({ field }) => (
            <InputNumber
              id={name}
              autoComplete="off"
              placeholder={placeholder}
              value={field.value} // Controlled input for React Hook Form
              onValueChange={(e) => field.onChange(e.value)} // Update value on change
              className={errors[name] ? "p-invalid" : ""} // Add error class if validation fails
              size={10}
            />
          )}
        />
        {errors[name] && (
          <small className="text-danger">
            <Translate>{errors[name].message}</Translate>
          </small>
        )}
      </div>
    </div>
  );
};

export default NumberInput;
