import React from "react";
import { Button } from "@mantine/core";

import Translate from "Components/Translate";

import patchItem from "Services/patchItem";

import { CANCEL, CONFIRM_PAYMENT, CREATE_DELIVERY_FORM, READY } from "Constant";

export const ConfirmPaymentBtn = ({ url, disabled }) => {
  return (
    <Button
      variant="filled"
      color="green"
      size="xs"
      onClick={() => patchItem(url)}
      disabled={disabled}
    >
      <Translate>{CONFIRM_PAYMENT}</Translate>
    </Button>
  );
};

export const CreateDeliveryFormBtn = ({ url, disabled }) => {
  return (
    <Button
      variant="filled"
      color="orange"
      size="xs"
      onClick={() => patchItem(url)}
      disabled={disabled}
    >
      <Translate>{CREATE_DELIVERY_FORM}</Translate>
    </Button>
  );
};
