import { useParams } from "react-router-dom";
import { Fragment } from "react";
import { Card, CardBody } from "reactstrap";

import DataTables from "../../Components/DataTables";
import DataTableHeader from "../../Components/DataTableHeader";

import FilterBar from "../../Components/FilterBar";
import EditItem from "../../Components/EditItem";
import ModalApp from "../../Components/ModalApp";
import AddItem from "../../Components/AddItem";

import { API, COLUMNS, CREATE_ITEMS, EDIT_ITEMS, SEARCH_ITEMS } from "./data";

import { CREATE_SERVICES_LIST, EDIT_SERVICES_LIST } from "../../Constant";
import Breadcrumbs from "../../Components/Breadcrumbs/SheetOf";
import Loading from "../../Components/Loading/Loading";
import NotFound from "../../Components/NotFound";
import useFetchData from "../../hooks/useFetchData";

const ReadService = () => {
  const { code } = useParams();

  const list = API.list(code);

  // const breadCrumbsProps = [MY_SERVICES_LIST];

  let serviceData;
  const { data, isLoading, isError } = useFetchData(
    "/back/service/" + code + "/price"
  );

  if (isLoading) return <Loading />;

  if (isError) return <NotFound />;

  if (!isLoading && !isError) {
    serviceData = data.data.results.data;
  }

  const dynamicPart = ` ${serviceData.service_label} `;

  const breadCrumbsProps = [dynamicPart];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTableHeader createBtn={true} />

          <FilterBar searchByItems={SEARCH_ITEMS} />

          <DataTables newColumns={COLUMNS} link={list} />
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_SERVICES_LIST}>
        <AddItem addURL={API.create(code)} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_SERVICES_LIST}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default ReadService;
