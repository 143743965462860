import React from "react";
import { useDispatch } from "react-redux";

import { toggleSelectAll } from "../../redux/selectedRows";
import { changeModalType } from "../../redux/modal";
import styles from "./DataTableBtnCheck.module.css";
import { useTranslation } from "react-i18next";

const SelectAll = ({ children }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectAllRows = () => {
    dispatch(changeModalType("QRcode"));
    dispatch(toggleSelectAll(true));
  };

  return (
    <button className={styles["buttonSelectAll"]} onClick={selectAllRows}>
      {t(children)}
    </button>
  );
};

export default SelectAll;
