export const API = {
    list: (p) => `/back/taximeter/${p}`,
};

export const COLUMNS_BOXES = [
    { name: "Serial number", selector: "serial_number" },
    { name: "Active" , selector: "active" },
    { name: "Created at" , selector: "created_at" },
  
]
export const COLUMNS_PRINTERS = [
    { name: "Serial number", selector: "serial_number" },
    { name: "Address" , selector: "address" },
    { name: "Model" , selector: "model" },
    {  name: "Brand" , selector: "brand" },
    {  name: "External" , selector: "external" },
    {  name: "Active" , selector: "active" },
    {  name: "Created at" , selector: "created_at" },
]
export const COLUMNS_TAXIS = [

]
export const COLUMNS_LOCATIONS = [

]
export const COLUMNS_NOTEBOOKS = [

]
