import { createSlice } from "@reduxjs/toolkit";

export const currentRowSlice = createSlice({
  name: "currentRow",
  initialState: {
    code: "",
    requiredItems: {},
    getLink: "",
    putLink: "",
    row: {},
    rowMaterialStock: {},
  },

  reducers: {
    changeCurrentRow: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },

    setCurrentCode: (state, action) => {
      state.code = action.payload;
    },

    setRowMaterialStock: (state, action) => {
      state.rowMaterialStock = action.payload;
    },
  },
});

export const { changeCurrentRow, setCurrentCode, setRowMaterialStock } =
  currentRowSlice.actions;

export default currentRowSlice.reducer;
