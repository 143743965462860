export const COLUMNS = [
  {
    name: "First name",
    selector: "firstname",
  },
  {
    name: "Last name",
    selector: "lastname",
  },
  {
    name: "Phone",
    selector: "phone",
  },
  {
    name: "Role",
    selector: "role",
  },
];

export const SEARCH_ITEMS = [];
