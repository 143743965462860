// API
export const API = {
  list: (code) => `/back/distributor/${code}/agencies`,
  create: (code) => `/back/distributor/${code}/agencies`,
  get: (code, a) => `/back/distributor/${code}/agency/${a}`,
};

// Search
export const SEARCH_ITEMS = [];

// Form
export const CREATE_ITEMS = [
  {
    title: "Phone",
    placeholder: "Phone",
    name: "phone",
    inputForm: "SimpleInput",
  },
  {
    title: "Address",
    placeholder: "Address",
    name: "address",
    inputForm: "SimpleInput",
  },
  {
    title: "Zip code",
    placeholder: "zip code",
    name: "zipCode",
    inputForm: "SimpleInput",
  },
  {
    title: "City",
    placeholder: "city",
    name: "city",
    inputForm: "SimpleInput",
  },
];

export const EDIT_ITEMS = [
  {
    title: "Phone",
    placeholder: "Phone",
    name: "phone",
    inputForm: "SimpleInput",
  },
  {
    title: "Address",
    placeholder: "Address",
    name: "address",
    inputForm: "SimpleInput",
  },
  {
    title: "Zip code",
    placeholder: "zip code",
    name: "zipCode",
    inputForm: "SimpleInput",
  },
  {
    title: "City",
    placeholder: "city",
    name: "city",
    inputForm: "SimpleInput",
  },
];
