// Urls
export const API = {
  list: "/back/workOrder/",
  create: (code) => `/back/workOrder/${code}/task`,
  delete: (w, t) => `/back/workOrder/${w}/task/${t}`,
  put: (w, t) => `/back/workOrder/${w}/task/${t}`,
};

// Search
export const SEARCH_ITEMS = [
  {
    title: "Price",

    placeholder: "price",
    name: "price",
    inputForm: "SimpleInput",
  },
  {
    title: "Date Begin",

    placeholder: "date_begin",
    name: "date_begin",
    inputForm: "DateInput",
  },
  {
    title: "Date End",

    placeholder: "date_end",
    name: "date_end",
    inputForm: "DateInput",
  },
];

// Form

export const CREATE_ITEMS = [
  {
    title: "Type",
    placeholder: "type",
    name: "type",
    inputForm: "TextareaInput",
  },
  {
    title: "Description",
    placeholder: "description",
    name: "description",
    inputForm: "TextareaInput",
  },
];

export const EDIT_ITEMS = [
  {
    title: "Type",
    placeholder: "type",
    name: "type",
    inputForm: "TextareaInput",
  },
  {
    title: "Description",
    placeholder: "description",
    name: "description",
    inputForm: "TextareaInput",
  },
];
