import React from "react";
import SideBar from "./SideBar";
import MiniSideBar from "./MiniSideBar";
import { Outlet } from "react-router-dom";

const Sidebar = () => {

  return (
    <>
        <MiniSideBar />
        <SideBar />
      <Outlet />
    </>
  );
};

export default Sidebar;

