import { createSlice } from "@reduxjs/toolkit";

const productData = {
  category: "",
  model: "",
  description: "",
  price: 0,
  quantity: 1,
  vat: 0,
  discount: 0,
};
const servicesData = {
  service: "",
  description: "",
  dateBegin: "",
  dateEnd: "",
  price: 0,
  quantity: 1,
  vat: 0,
};

const initialState = {
  vatAmount: 0,
  price: 0,
  priceVat: 0,
  products: [productData],
  services: [servicesData],
};

export const estimateSlice = createSlice({
  name: "estimate",

  initialState: {
    vatAmount: 0,
    price: 0,
    priceVat: 0,
    products: [productData],
    services: [servicesData],
  },

  reducers: {
    addProduct: (state) => {
      state.products.push(productData);
    },
    addService: (state) => {
      state.services.push(servicesData);
    },

    editProduct: (state, action) => {
      const { data, index } = action.payload;
      state.products[index] = { ...state.products[index], ...data };
    },

    editService: (state, action) => {
      const { data, index } = action.payload;
      state.services[index] = { ...state.services[index], ...data };
    },

    onRemoveProduct: (state, action) => {
      const indexToRemove = action.payload;

      const res = state.products.filter((_, index) => index !== indexToRemove);

      state.products = res;
    },

    onRemoveService: (state, action) => {
      const indexToRemove = action.payload;

      const res = state.services.filter((_, index) => index !== indexToRemove);

      state.services = res;
    },

    fillOldData: (state, action) => {
      state.products = action.payload.products;
      state.services = action.payload.services;
    },

    refreshEstimate: (state) => {
      const { vatAmount, price, priceVat, products, services } = initialState;
      state.vatAmount = vatAmount;
      state.price = price;
      state.priceVat = priceVat;
      state.products = products;
      state.services = services;
    },
  },
});

export const {
  addProduct,
  addService,
  onRemoveService,
  onRemoveProduct,
  editProduct,
  editService,
  refreshEstimate,
  fillOldData,
} = estimateSlice.actions;

export default estimateSlice.reducer;
