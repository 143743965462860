import {
  MissingParameters,
  NotUnique,
  ShowErrorMsg,
} from "../Components/Alerts/Alerts";
import clearUserData from "./clearUserData";

const errorMsg = (error) => {
  try {
    const code = error.response.data.messages.code;
    const { value, parameter } = error.response.data.messages;
    if (value === "expired_session") clearUserData();
    else
      switch (code) {
        case 412:
          MissingParameters(parameter, value);
          break;
        case 409:
          NotUnique(parameter, value);
          break;
        default:
          ShowErrorMsg(value);
      }
  } catch (catchError) {
    console.error("An error occurred:", catchError);
    ShowErrorMsg("Something went wrong");
  }
};
export default errorMsg;
