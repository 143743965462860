import {
  Sunrise,
  Box,
  Award,
  Cast,
  PenTool,
  ShieldOff,
  HardDrive,
  AlertCircle,
  Printer,
  Zap,
  ToggleLeft,
  Triangle,
  Codepen,
} from "react-feather";
import React from "react";
import useFetchData from "hooks/useFetchData";
import { getData } from "helper/getData";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Grid from "@mui/material/Grid";
import MDBox from "Components/MDBox";
import StatisticLoading from "Components/Loading/StatisticLoading";
import {
  Card,
  Header,
  Body,
  Skill,
  SkillName,
  SkillLevel,
  SkillPercent,
  SkillPercentNumber,
} from "Components/AlerteStock/AlerteStock";

const icons = {
  PRINTER: <Printer />,
  BOX: <Box />,
  LUMINOUS: <Zap />,
  SENSOR: <Cast />,
  BADGE: <Award />,
  TAXIMETER: <Codepen />,
  STYLUS: <PenTool />,
  BRACKET: <Triangle />,
  SUNSHIELD: <Sunrise />,
  OBD_ADAPTER: <HardDrive />,
  SOS_BUTTON: <AlertCircle />,
  RELAY_SWITCH: <ToggleLeft />,
  FEMALE_BRACKET: <ShieldOff />,
  default: <Codepen />,
};

const ProductCountStock = ({ name }) => {
  const category = name.code || "";
  const cat = category.toUpperCase();
  const url = `/stat/stock/count?product=${cat}&status=in_stock`;
  const { data, isLoading, isError } = useFetchData(url);

  const productStatus = getData(data) ?? [];
  const totalAmount =
    productStatus.length > 0 ? productStatus[0].count_stock : 0;
  const message = productStatus.length > 0 ? productStatus[0].message : "";
  const color = message.includes("is sufficient") ? "success" : "error";

  const getToday = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const getIcon = (productName) => {
    return icons[productName] || icons.default;
  };

  if (isLoading || isError) {
    return null;
  }

  const sufficientStock = message.includes("is sufficient");
  if (!sufficientStock) {
    return (
      <Grid item xs={12} md={6} lg={3}>
        <MDBox mb={1.5}>
          {!isLoading && (
            <>
              <ComplexStatisticsCard
                color={color}
                icon={getIcon(cat)}
                title={`Stock Of ${name.label}`}
                count={totalAmount}
                percentage={{
                  color: color,
                  label: getToday(),
                  amount: message,
                }}
      //           levelStock={
      //           <MDBox  px={4} >
      //   <Skill style={{ padding: `10` }}>
      //     <SkillName>hello</SkillName>
      //     <SkillLevel>
      //       <SkillPercent style={{ width: `10` }} />
      //     </SkillLevel>
      //     <SkillPercentNumber>EEE</SkillPercentNumber>
      //   </Skill>
      // </MDBox>
      //           }
              />
            </>
          )}
          {isLoading && <StatisticLoading />}
        </MDBox>
      </Grid>
    );
  }

  return null;
};

export default ProductCountStock;
