import { Col, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? "#fbdc10" : "#ccc",
    boxShadow: state.isFocused ? "0 0 0 0.2rem #fbdc10" : "none",
  }),
};

const SelectInput = ({ register, inputName, oldValue }) => {
  console.log("oldValue", oldValue);

  const { name, title, options, number, required } = inputName;

  const handleOnChange = (e) => {
    register({ name: name, value: e.value });
  };
  const { t } = useTranslation();

  const translatedOptions = number
    ? options
    : options.map((option) => ({
        label: t(option.label),
        value: option.value,
      }));

  const isReq = required === undefined ? true : false;

  return (
    <Col sm="3">
      <FormGroup>
        <Label>
          {title} {isReq && <span className="text-danger">*</span>}
        </Label>
        <Select
          defaultValue={getDefaultVal(oldValue, options)}
          placeholder={oldValue}
          styles={customStyles}
          onChange={handleOnChange}
          options={translatedOptions}
          name={name}
          required={isReq}
        />
      </FormGroup>
    </Col>
  );
};
export default SelectInput;

const getDefaultVal = (oldValue, options) => {
  try {
    return options.find((element) => element.value == oldValue) || null;
  } catch {
    return null;
  }
};
