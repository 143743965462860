import { createSlice } from "@reduxjs/toolkit";

export const selectedRowsSlice = createSlice({
  name: "selectedRows",
  initialState: {
    listOfCodes: [],
    selectAll: false,
  },

  reducers: {
    addCodes: (state, action) => {
      const codeToAdd = action.payload;
      let newList = state.listOfCodes;
      if (newList.includes(codeToAdd))
        state.listOfCodes = newList.filter((number) => number !== codeToAdd);
      else newList.push(codeToAdd);
    },

    initializeListOfCodes: (state) => {
      state.listOfCodes = [];
      state.selectAll = false;
    },

    toggleSelectAll: (state, action) => {
      state.selectAll = action.payload;
    },
  },
});

export const { addCodes, initializeListOfCodes, toggleSelectAll } =
  selectedRowsSlice.actions;

export default selectedRowsSlice.reducer;
