import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Translate from "Components/Translate";
import { CANCEL, GO } from "Constant";
import { Button } from "primereact/button";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Label, Row } from "reactstrap";
import sendData from "Services/sendData";

import { toggleModal } from "../../redux/modal";
import { toggleRefresh } from "../../redux/searchLink";
import { useDispatch } from "react-redux";
const CreateRow = () => {
  const dispatch = useDispatch();

  const addedWithSuccess = () => {
    dispatch(toggleRefresh());
    dispatch(toggleModal());
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isSendingData, setIsSendingData] = useState(false);

  const onSubmit = (data) => {
    if (Object.keys(errors).length === 0) {
      setIsSendingData(true);
      sendData(data, "/back/faq", setIsSendingData, addedWithSuccess);
    } else {
      alert("Please fill in all required fields.");
    }
  };

  return (
    <>
      <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
        <Label className="form-label" for="validationCustom01">
          Question <span className="text-danger">*</span>
        </Label>
        <input
          autoComplete="off"
          className={`form-control ${errors.question ? "is-invalid" : ""}`}
          id="validationCustom01"
          type="text"
          name="question"
          {...register("question", { required: "Required" })}
        />
        {errors.question && (
          <span className="text-danger">{errors.question.message} </span>
        )}
        <Label className="form-label" for="validationCustom02">
          Answer <span className="text-danger">*</span>
        </Label>
        <textarea
          autoComplete="off"
          className={`form-control ${errors.answer ? "is-invalid" : ""}`}
          id="validationCustom02"
          type="text"
          name="answer"
          {...register("answer", { required: "Required" })}
        />
        {errors.answer && (
          <span className="text-danger">{errors.answer.message}</span>
        )}

        {/* <Row>
            <Button
              style={{ marginTop: "30px" }}
              type="submit"
              label={isSendingData ? "Loading" : "Go"}
              loading={isSendingData}
              severity="success"
            />
          </Row> */}
      </Form>

      <DialogActions dividers>
        <Button onClick={() => toggleModal()} severity="secondary">
          <Translate>{CANCEL}</Translate>
        </Button>

        <Button severity="success" onClick={handleSubmit(onSubmit)} autoFocus>
          <Translate>{GO}</Translate>
        </Button>
      </DialogActions>
    </>
  );
};

export default CreateRow;
