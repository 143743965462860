import { Col, FormGroup, Label } from "reactstrap";
import React, { Fragment } from "react";
import onChangeInput from "../../helper/onChangeInput";

const NumberInput = ({ register, inputName, oldValue, isDisable }) => {
  const { name, title, placeholder, required } = inputName;

  const handleOnChange = (e) => {
    const number = onChangeInput(e);
    const [name, value] = Object.entries(number)[0];

    const obj = { name, value: parseInt(value, 10) };
    register(obj);
  };

  const isReq = required === undefined ? true : required;

  return (
    <Fragment>
      <Col sm="2">
        <FormGroup>
          <Label>
            {title} {isReq && <span className="text-danger">*</span>}
          </Label>
          <input
            autoComplete="off"
            className="form-control input-style"
            placeholder={placeholder}
            onChange={handleOnChange}
            defaultValue={oldValue}
            type="number"
            name={name}
            required
            disabled={isDisable}
          />
        </FormGroup>
      </Col>
    </Fragment>
  );
};

export default NumberInput;
