import React, { Fragment } from "react";

import { API, COLUMNS_TAXIS } from "./data";
import DataTableHeader from "../../Components/DataTableHeader";
import DataTables from "../../Components/DataTables";

const Taxis = ({ code }) => {
  const list = API.list(code) + "/taxis";
  return (
    <Fragment>
      <DataTableHeader />
      <DataTables newColumns={COLUMNS_TAXIS} link={list} />
    </Fragment>
  );
};

export default Taxis;
