import { Fragment } from "react";

import { Card, CardBody } from "reactstrap";

import DataTables from "Components/DataTables/DataTable";
import Breadcrumbs from "Components/Breadcrumbs";
import EditItem from "Components/EditItem";
import ModalApp from "Components/ModalApp";

import { API, COLUMNS, EDIT_ITEMS } from "./data";

import { CREATE_WORK_ORDERS, EDIT_WORK_ORDER, MY_WORK_ORDERS } from "Constant";

import SettingsComp from "./SettingsComp";
import CreateWorkOrder from "./CreateWorkOrder";

const WorkOrdersPage = () => {
  const list = API.list;

  const breadCrumbsProps = [MY_WORK_ORDERS];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTables newColumns={COLUMNS} link={list} />
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_WORK_ORDERS}>
        <CreateWorkOrder />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_WORK_ORDER}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>

      <ModalApp type="settings">
        <SettingsComp statusFn={API.changeStatus} />
      </ModalApp>
    </Fragment>
  );
};

export default WorkOrdersPage;
