//import { getCodeLabel } from "../../helper/getOptions";

import CheckBox from "../../../Components/DataTableBtns/CheckBox";
import EditBtn from "../../../Components/DataTableBtns/EditBtn";
import SelectAll from "../../../Components/DataTableBtns/SelectAll";
import { getOptionNumber, getTitleCodeLabel } from "../../../helper/getOptions";

// Urls
export const API = {
  list: "/back/femaleBrackets",
  create: "/back/femaleBrackets",
  Serial: "/back/femaleBrackets/serials",
  get: "/back/femaleBracket/",
};

// Columns
export const COLUMNS = [
  {
    name: <SelectAll>generate all</SelectAll>,
    cell: (row) => <CheckBox rowCode={row.code} key={row.code} />,
    button: "true",
  },
  {
    name: "Number",
    selector: "serial_number",
  },
  {
    name: "Status",
    selector: "status",
  },
  { name: "Created at", selector: "created_at" },

  {
    name: "Edit",
    cell: (row) => (
      <EditBtn read={API.get + row.code} extra={row.serial_number} />
    ),
    button: "true",
  },
];

export const SEARCH_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/FEMALE-BRACKET",
    getOptions: getTitleCodeLabel,
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/FEMALE-BRACKET",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "Amount",
    name: "amount",
    inputForm: "SelectInput",
    options: getOptionNumber(1, 100),
    number: true,
  },
];

export const EDIT_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/FEMALE-BRACKET",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "Amount",
    name: "amount",
    inputForm: "SelectInput",
    options: getOptionNumber(1, 100),
    number: true,
  },
];
