import React, { Fragment } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import { H1 } from "../../AbstractElements";

import { WELLCOME_TO_TAXIORA_BACKOFFICE } from "../../Constant";
import { useTranslation } from "react-i18next";

import PieChartStockByCategory from "./PieChartStockByCategory";
import BarChartCountByCategory from "./BarChartCountByCategory";
import BarChartMovement from "./BarChartMovement";
import Products from "./Products";
import TreeOfWorld from "./TreeOfWorld";
import StatStockFinishedProductPage from "pages/StatStockFinishedProductPage";
// import { taxiterByStatus } from "./calculateStats";

// taxiterByStatus();
const HomePage = () => {
  const { t } = useTranslation();

  return <StatStockFinishedProductPage />;
  return (
    <Fragment>
      <div style={{ marginBottom: "20px" }}>
        <Card className="mb-0">
          <CardBody>
            <H1>{t(WELLCOME_TO_TAXIORA_BACKOFFICE)}</H1>
          </CardBody>
        </Card>
      </div>

      <Row>
        {/* <div style={{ width: "400px", height: "400px" }}> */}
        <Col>
          <Card>
            <CardBody>
              <PieChartStockByCategory />
            </CardBody>
          </Card>
        </Col>

        <Col>
          <Card>
            <CardBody>
              <BarChartMovement />
            </CardBody>
          </Card>
        </Col>
        {/* </div> */}
      </Row>

      <Col>
        <Card>
          <CardBody>
            <BarChartCountByCategory />
          </CardBody>
        </Card>
      </Col>
      <Card>
        <CardBody>
          <Products />
        </CardBody>
      </Card>

      <TreeOfWorld />
    </Fragment>
  );
};

export default HomePage;
