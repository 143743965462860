import React from "react";
import { Row } from "reactstrap";

import { useDispatch } from "react-redux";
import { addSearchItems } from "../../redux/searchLink";

import FilterInput from "../Inputs";
import onChangeInput from "../../helper/onChangeInput";

const FilterBar = ({ searchByItems }) => {
  const dispatch = useDispatch();

  const handleChange = (e) => {
    clearTimeout(handleChange.timeout);
    handleChange.timeout = setTimeout(() => {
      const newInput = onChangeInput(e);
      dispatch(addSearchItems(newInput));
    }, 1000);
  };

  return (
    <Row>
      {searchByItems.map((item, index) => (
        <FilterInput
          key={index}
          item={item}
          handleChange={(e) => handleChange(e)}
        />
      ))}
    </Row>
  );
};

export default FilterBar;
