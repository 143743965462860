import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import {
  Database,
  ChevronLeft,
} from "react-feather";
import { toggleSidebar } from "../../redux/requeteSql";


const MiniSideBar = () => {
    const dispatch = useDispatch();
    const sidebarVisible = useSelector(
      (state) => state.requeteSql.sidebarVisible
    );

    const toggleSidebarVisibility = () => {
      dispatch(toggleSidebar());
    };
  
  return (
    <MinimizedSidebar
      $sidebarVisible={sidebarVisible}
      onClick={toggleSidebarVisibility}
    >
      <ChevronLeft size={30} />
      <Database size={30} />
    </MinimizedSidebar>
  );
}
const MinimizedSidebar = styled.div`
  width: ${({ $sidebarVisible }) => ($sidebarVisible ? "0" : "40px")};
  background-color: #f4f0e6;
  overflow-y: hidden;
  margin-top: 10px;
  margin-right: 8px;
  position: absolute;
  top: 100 px;
  right: 1px;
  height: 67vh;
  display: ${({ $sidebarVisible }) => ($sidebarVisible ? "none" : "flex")};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  &:hover {
    background-color: #e2c636;
    color: white;
  }
  &:after {
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }
`;

export default MiniSideBar
