import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export default function StaticTab({ newColumns, newRows }) {
  return (
    <div>
      <div className="card">
        <DataTable
          value={newRows}
          stripedRows
          showGridlines
          tableStyle={{
            width: "100%",
            border: "1px solid #ddd",
          }}
          style={{
            borderCollapse: "collapse", // Ensure table gridlines are visible
          }}
        >
          {newColumns.map((col, i) => (
            <Column
              key={i}
              header={col.header}
              field={col.field}
              style={{ border: "1px solid #ddd", padding: "8px" }}
            />
          ))}
        </DataTable>
      </div>
    </div>
  );
}
