import { DialogActions } from "@mui/material";
import { Btn } from "AbstractElements";
import Translate from "Components/Translate";
import { CANCEL, GO } from "Constant";
import { Button } from "primereact/button";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Form, Label, Row } from "reactstrap";
import updateData from "Services/updateData";

import { toggleModal } from "../../redux/modal";
import { toggleRefresh } from "../../redux/searchLink";
import useFetchData from "hooks/useFetchDataWithDep";
import Loading from "Components/Loading/Loading";

const EditRow = () => {
  const dispatch = useDispatch();
  const [isSendingData, setIsSendingData] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const { getLink, putLink } = useSelector((state) => state.currentRow);
  const { data, isLoading, isError } = useFetchData(getLink, "");

  // Extract oldData even if data is not yet loaded, and handle it properly in useEffect
  const oldData = (() => {
    try {
      return data.data.results.data;
    } catch (error) {
      return {};
    }
  })();

  // useEffect should be outside any conditional rendering
  useEffect(() => {
    if (!isLoading && oldData) {
      setValue("question", oldData.question || "");
      setValue("answer", oldData.answer || "");
    }
  }, [setValue, isLoading, oldData]);

  if (isLoading) return <Loading />;
  // You can also handle the error state here if needed
  // if (isError) return <NotFound />;

  const addedWithSuccess = () => {
    dispatch(toggleRefresh());
    dispatch(toggleModal());
  };

  const onSubmit = (data) => {
    if (Object.keys(errors).length === 0) {
      setIsSendingData(true);
      updateData(data, putLink, setIsSendingData, addedWithSuccess);
    } else {
      alert("Please fill in all required fields.");
    }
  };

  return (
    <>
      <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Label className="form-label" for="validationCustom01">
            Question <span className="text-danger">*</span>
          </Label>
          <input
            autoComplete="off"
            className={`form-control ${errors.question ? "is-invalid" : ""}`}
            id="validationCustom01"
            type="text"
            name="question"
            {...register("question", { required: "Required" })}
          />
          {errors.question && (
            <span className="text-danger">{errors.question.message}</span>
          )}
        </Row>

        <Row>
          <Label className="form-label" for="validationCustom02">
            Answer <span className="text-danger">*</span>
          </Label>
          <textarea
            autoComplete="off"
            className={`form-control ${errors.answer ? "is-invalid" : ""}`}
            id="validationCustom02"
            type="text"
            name="answer"
            {...register("answer", { required: "Required" })}
          />
          {errors.answer && (
            <span className="text-danger">{errors.answer.message}</span>
          )}
        </Row>
      </Form>
      <DialogActions dividers>
        <Button onClick={() => dispatch(toggleModal())} severity="secondary">
          <Translate>{CANCEL}</Translate>
        </Button>

        <Button severity="success" onClick={handleSubmit(onSubmit)} autoFocus>
          <Translate>{GO}</Translate>
        </Button>
      </DialogActions>
    </>
  );
};

export default EditRow;
