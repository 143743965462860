import React from "react";
import { InputText } from "primereact/inputtext";

import Translate from "Components/Translate";

const SimpleInput = ({ register, inputName, errors, oldValue }) => {
  const { name, title, placeholder, required } = inputName;
  const isReq = required === undefined ? true : false;

  return (
    <div className="col-3">
      <div className="flex flex-column ">
        <label>
          <Translate>{title}</Translate>
          {isReq && <span className="text-danger">*</span>}
        </label>
        <InputText
          autoComplete="off"
          {...register(name)}
          invalid={errors[name]}
          size={10}
          placeholder={placeholder}
          defaultValue={oldValue}
        />
        {errors[name] && (
          <small className="text-danger">
            <Translate>{errors[name].message}</Translate>
          </small>
        )}
      </div>
    </div>
  );
};

export default SimpleInput;
