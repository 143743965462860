import React, { Fragment, useState } from "react";
import DataNotFound from "Components/NotFound/DataNotFound";
import { getData } from "helper/getData";
import generateSearchLink from "Services/generateSearchLink";
import useFetchData from "hooks/useFetchDataWithDep";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import FilterByCategoryModel from "pages/StatStockPage/FilterByCategoryModel";

const BarChartSalesByCity = () => {
  const [inputs, setInputs] = useState({
    dateValue: "",
  });

  const searchItems = {
    date: inputs.dateValue,
  };
  const url = generateSearchLink("/stat/sales/city-turnover", searchItems);

  let labels = [];
  let labelRes = [];
  const { data, isLoading, isError } = useFetchData(url, inputs);

  let dataPie = {};

  if (isError) return <DataNotFound />;

  if (!isLoading) {
    const productStatus = getData(data);

    productStatus.forEach((elem) => {
      labels.push(elem.label);
      labelRes.push(elem.data);
    });

    dataPie = {
      labels: labels,
      datasets: [
        {
          label: "Revenue in TND ",
          data: labelRes,
          borderWidth: 1,
        },
      ],
    };
  }
  const params = {
    date: true,
  };
  return (
    <Fragment>
      <>
        <VerticalBarChart
          icon={{ color: "primary", component: <LocationCityIcon /> }}
          height="16rem"
          title="Sales "
          description="By City"
          chart={dataPie}
          isLoading={isLoading}
          customComponent={
            <FilterByCategoryModel
              params={params}
              inputs={inputs}
              setInputs={setInputs}
            />
          }
        />
      </>
    </Fragment>
  );
};

export default BarChartSalesByCity;
