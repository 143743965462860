import { Fragment } from "react";
import {COLUMNS } from "./data";

import { Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../Components/Breadcrumbs";
import { MY_PRODUCTS } from "../../Constant";
import StaticDataTable from "../../Components/DataTables/StaticDataTable";
import { useTranslation } from "react-i18next";

const Products = ({ products }) => {
  const { t } = useTranslation();
  const breadCrumbsProps = [t(MY_PRODUCTS)];
  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />
          <StaticDataTable newColumns={COLUMNS} newRows={products} />
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Products;
