import React, { useState } from "react";
import { useLogin } from "../../hooks/useLogin";
import Loading from "../../Components/Loading/Loading";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import PropTypes from "prop-types";
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import bgImage from "../../assets/images/bg.jpg";
import MDBox from "Components/MDBox";
import MDTypography from "Components/MDTypography";
import MDInput from "Components/MDInput";
import MDButton from "Components/MDButton";
import BasicLayout from "./components/BasicLayout";

const LoginPage = () => {
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { login, isLoading } = useLogin();
  const [inputs, setInputs] = useState({});

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleSetShowPassword = () => setShowPassword(!showPassword);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSignIn = () => {
    if (!inputs.username || !inputs.password) return;
    login(inputs);
  };

  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="warning"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={1}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography variant="button" fontWeight="medium" color="white">
              {/* You leave */}
              <MDTypography
                variant="button"
                color="text"
                fontWeight="medium"
                textGradient
              >
                Taxiora backoffice
              </MDTypography>
              {/* , but
              <MDTypography
                variant="button"
                color="text"
                fontWeight="medium"
                textGradient
              >
                Taxiora
              </MDTypography>
              doesn't leave you. */}
            </MDTypography>
          </MDBox>
        </MDBox>

        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                name="username"
                onChange={handleChange}
                fullWidth
                autoComplete="off"
              />
            </MDBox>
            <MDBox mb={2} position="relative">
              <MDInput
                type={showPassword ? "text" : "password"}
                label="Password"
                name="password"
                onChange={handleChange}
                fullWidth
                autoComplete="off"
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1} mt={2}>
              <Switch checked={showPassword} onChange={handleSetShowPassword} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetShowPassword}
                sx={{ cursor: "pointer", userSelect: "none", ml: 1 }}
              >
                &nbsp;Show Password
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="warning"
                onClick={handleSignIn}
                fullWidth
              >
                sign in
              </MDButton>
            </MDBox>
            <Grid
              container
              spacing={3}
              justifyContent="center"
              sx={{ mt: 1, mb: 2 }}
            >
              <Grid item xs={2}>
                <MDTypography
                  component={MuiLink}
                  href="https://www.facebook.com/taxioratn"
                  variant="body1"
                  target="_blank"
                  color="dark"
                >
                  <FacebookIcon color="inherit" />
                </MDTypography>
              </Grid>
              <Grid item xs={2}>
                <MDTypography
                  component={MuiLink}
                  href="https://www.instagram.com/taxiorapub/?hl=fr"
                  target="_blank"
                  variant="body1"
                  color="dark"
                >
                  <InstagramIcon color="inherit" />
                </MDTypography>
              </Grid>
              <Grid item xs={2}>
                <MDTypography
                  component={MuiLink}
                  href="https://www.linkedin.com/company/taxiora/about/"
                  target="_blank"
                  variant="body1"
                  color="dark"
                >
                  <LinkedInIcon color="inherit" />
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        {isLoading && (
          <div style={overlayStyle}>
            <Loading />
          </div>
        )}
      </Card>
    </BasicLayout>
  );
};

LoginPage.propTypes = {
  light: PropTypes.bool,
};

export default LoginPage;
