import { Fragment } from "react";
import Select from "react-select";

import useFetchData from "hooks/useFetchData";
import { getData } from "helper/getData";
import SelectSpinner from "Components/SelectSpinner";

const SelectApiInput = ({ register, inputName, oldValue, isDisabled }) => {
  const { name, optionsApi, getOptions, extrOptions, required, width } =
    inputName;
  const { data, isLoading, isError } = useFetchData(optionsApi);

  let options = [];

  const handleOnChange = (e) => {
    register({ name, value: e.value });
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#fbdc10" : "#ccc",
      boxShadow: state.isFocused ? "0 0 0 0.2rem #fbdc10" : "none",
    }),
  };

  if (!isLoading && !isError) options = getOptions(getData(data));
  if (extrOptions) options.push(extrOptions);

  const defaultVal = getDefaultVal(oldValue, options);
  const isReq = required === undefined ? true : required;

  return (
    <Fragment>
      {isLoading && (
        <Select
          isDisabled={isDisabled}
          styles={customStyles}
          onChange={handleOnChange}
          options={options}
          name={name}
          required={isReq}
          defaultValue={getDefaultVal(oldValue, options)}
          placeholder={oldValue}
          components={
            isLoading
              ? {
                  DropdownIndicator: SelectSpinner,
                }
              : {}
          }
        />
      )}

      {!isLoading && (
        <Select
          isDisabled={isDisabled}
          styles={customStyles}
          onChange={handleOnChange}
          options={options}
          name={name}
          required={isReq}
          placeholder={oldValue}
          defaultValue={getDefaultVal(oldValue, options)}
          components={
            isLoading
              ? {
                  DropdownIndicator: SelectSpinner,
                }
              : {}
          }
        />
      )}
    </Fragment>
  );
};
export default SelectApiInput;

const getDefaultVal = (oldValue, options) => {
  if (!Array.isArray(options)) {
    console.error("Options is not an array");
    return null;
  }

  const defaultValue = options.find((elem) => elem.value === oldValue);

  if (!defaultValue) {
    console.log("No matching value found in options");
    return null;
  }

  return defaultValue;
};
