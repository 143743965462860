import ReadPageBtn from "../../Components/MantineDataTableBtns/ReadPageBtn";
import EditBtn from "../../Components/MantineDataTableBtns/EditBtn";
import ActiveBtn from "../../Components/MantineDataTableBtns/ActiveBtn";
import { Group } from "@mantine/core";

// Urls
export const API = {
  list: "/back/paramters",
  create: "/back/parameter",
  get: "/back/parameter/",
  edit: "/back/parameter/",
  patch: "/back/parameter/",
};

// Columns
export const COLUMNS = [
  { name: "Label", selector: "label" },
  { name: "Default value", selector: "default_value" },

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 50,
    Cell: ({ row }) => {
      const { code, active, label } = row.original;
      return (
        <Group gap="xs">
          <ActiveBtn patch={API.patch + code} state={active} />
          <ReadPageBtn read={`/parameter/${code}`} />
          <EditBtn read={API.get + code} extra={label} />
        </Group>
      );
    },
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Label",
    placeholder: "Label",
    name: "label",
    inputForm: "SimpleInput",
  },
  {
    title: "Default value",
    placeholder: "Default value",
    name: "default_value",
    inputForm: "SimpleInput",
  },
  {
    title: "active",
    name: "active",
    inputForm: "CheckBox",
  },
];
export const EDIT_ITEMS = [
  {
    title: "Label",

    placeholder: "Label",
    name: "label",
    inputForm: "SimpleInput",
  },
  {
    title: "Default value",

    placeholder: "Default value",
    name: "default_value",
    inputForm: "SimpleInput",
  },
  {
    title: "active",
    name: "active",
    inputForm: "CheckBox",
  },
];
