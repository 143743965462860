import React, { Fragment, useState } from "react";
import DataNotFound from "Components/NotFound/DataNotFound";
import { getData } from "helper/getData";
import generateSearchLink from "Services/generateSearchLink";
import useFetchData from "hooks/useFetchDataWithDep";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import  LeaderboardIcon  from '@mui/icons-material/Leaderboard';
import FilterByCategoryModel from "pages/StatStockPage/FilterByCategoryModel";

const BarChartCountByCategory = () => {
   const [inputs, setInputs] = useState({
     status: "in_stock",
   });

  const searchItems = {
    status: inputs.status,
  };
  const url = generateSearchLink("/stat/stock/count-by-category", searchItems);


  let labels = [];
  let labelRes = [];
  const { data, isLoading, isError } = useFetchData(url, inputs);

  let dataPie = {};

  if (isError) return <DataNotFound />;

  if (!isLoading) {
    const productStatus = getData(data);

    productStatus.forEach((elem) => {
      labels.push(elem.label);
      labelRes.push(elem.data);
    });

    dataPie = {
      labels: labels,
      datasets: [
        {
          label: "Number of categories",
          data: labelRes,
          borderWidth: 1,
        },
      ],
    };
  

  }
 const params = {
   status: true,
 };
  return (
    <Fragment>
  
        <>
          <VerticalBarChart
            icon={{ color: "secondary", component: <LeaderboardIcon /> }}
            height="16rem"
            title="Quantity Of Finished Product "
            description="By Product"
            chart={dataPie}
            isLoading={isLoading}
            customComponent={
              <FilterByCategoryModel
                params={params}
                inputs={inputs}
                setInputs={setInputs}
              />
            }
          />
        </>
        </Fragment>
  );
};

export default BarChartCountByCategory;
