import React from "react";
import BooleanIcon from "../DataTableBtns/BooleanIcon";
import { Image } from "../../AbstractElements";

const HeaderResult = ({ objectData, elem }) => {
  if (elem.img) {
    return (
      <Image
        attrImage={{
          className: "img-90 rounded-circle",
          src: objectData[elem.key],
          alt: "",
        }}
      />
    );
  }
  if (
    typeof objectData[elem.key] === "string" ||
    typeof objectData[elem.key] === "number"
  )
    return objectData[elem.key];

  if (elem.btn) return elem.btn;

  if (
    typeof objectData[elem.key] === "boolean" ||
    objectData[elem.key] === null
  )
    return <BooleanIcon state={objectData[elem.key]} />;
};

export default HeaderResult;
