import {
  Group,
  Avatar,
  Text,
  Accordion,
  Badge,
  ActionIcon,
  Center,
  Indicator,
  Space,
  Tooltip,
} from "@mantine/core";
import { IconCar, IconSettings } from "@tabler/icons-react";

import { SERIAL_NUMBER, SETTINGS } from "Constant";

import Translate from "Components/Translate";

import ListOfTaxiData from "./ListOfTaxiData";
import { CreateTaxi, TaxisSettingsBtn } from "./SettingsBtn";
import { Fragment } from "react";
import HeaderTab from "./HeaderTab";

function TaxisInfo({ data }) {
  return (
    <Fragment>
      <HeaderTab tab={[<CreateTaxi />]} />

      <Accordion chevronPosition="left" variant="contained">
        {data.map((elem, i) => {
          const id = i.toString();
          const { taxi_serial_number, active_taxi } = elem;
          console.log(elem);
          const taxiTitle = `${elem.brand_taxi} / ${elem.model_taxi} / ${elem.taxi_serial_number}`;
          const title = (
            <Text fw={700}>
              {/* <Translate>{SERIAL_NUMBER}: </Translate> */}
              <Badge variant="dot" color="gray">
                <Text size="lg">{taxiTitle}</Text>
              </Badge>
            </Text>
          );

          const indicatorProps = active_taxi
            ? { color: "green", label: "Active" }
            : { color: "red", label: "Not active" };

          return (
            <Accordion.Item value={id} key={taxi_serial_number}>
              <Center>
                <Accordion.Control>
                  <Group wrap="nowrap">
                    <Indicator inline color={indicatorProps.color} size={16}>
                      <Avatar radius="xl" size="lg" color="orange">
                        {<IconCar color="black" />}
                      </Avatar>
                    </Indicator>

                    <Space w="md" />

                    <div>
                      <Text size="xl">{title}</Text>
                      <Text size="sm" c="dimmed" fw={400}>
                        {/* {`phone number: ${user_phone} created at: ${created_at}`} */}
                      </Text>
                    </div>
                  </Group>
                </Accordion.Control>

                <TaxisSettingsBtn data={elem} />

                <Space w="lg" />
              </Center>

              <Accordion.Panel>
                <ListOfTaxiData taxiDetails={elem} />
              </Accordion.Panel>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </Fragment>
  );
}

export default TaxisInfo;
