import React, { Fragment, useState } from "react";
import DataNotFound from "Components/NotFound/DataNotFound";
import { getData } from "helper/getData";
import generateSearchLink from "Services/generateSearchLink";
import useFetchData from "hooks/useFetchDataWithDep";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import CategoryIcon from "@mui/icons-material/Category";
import FilterByCategoryModel from "pages/StatStockPage/FilterByCategoryModel";
// import { Row, Col } from "reactstrap";
// import Pagination from "@mui/material/Pagination";
// import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";

const LineChartTurnover = () => {
  const [inputs, setInputs] = useState({
    dateValue: "",
    index: 1,
    size: 40,
  });

  let totalItems = 0;


  const searchItems = {
    date: inputs.dateValue,
    index: inputs.index,
    size: inputs.size,
  };
  const url = generateSearchLink("/stat/sales/sales-date", searchItems);
  let labels = [];
  let labelRes = [];
  const { data, isLoading, isError } = useFetchData(url, inputs);

  const params = {
    date: true,
  };

  let dataPie = {};

  const productStatus = getData(data);
  totalItems = productStatus.length;
  productStatus.forEach((elem) => {
    labels.push(elem.label);
    labelRes.push(elem.data);
  });

  dataPie = {
    labels: labels,
    datasets: [
      {
        label: "Revenue in TND",
        data: labelRes,
        borderWidth: 1,
      },
    ],
  };
//   const newTotalPages = Math.ceil(totalItems / inputs.size);

  if (isError) return <DataNotFound />;

//   const handleChangePage = (value) => {
//     setInputs((prevState) => ({
//       ...prevState,
//       index: value,
//     }));
//   };

//   const handleChangeSize = (event) => {
//     setInputs((prevState) => ({
//       ...prevState,
//       size: event.target.value,
//       index: 1,
//     }));
//   };

  return (
    <Fragment>
      <>
        <DefaultLineChart
          icon={{ color: "error", component: <TrendingDownIcon /> }}
          height="16rem"
          title="Sales "
          description="By Date"
          chart={dataPie}
          isLoading={isLoading}
          customComponent={
            <FilterByCategoryModel
              params={params}
              inputs={inputs}
              setInputs={setInputs}
            />
          }
          // pagination={
          //   <>
          //     <Row>
          //       <Col>
          //         <FormControl
          //           variant="outlined"
          //           style={{ minWidth: 120, padding: 20, marginRight: 20 }}
          //         >
          //           <InputLabel id="select-size-label">
          //             Items per page
          //           </InputLabel>
          //           <Select
          //             labelId="select-size-label"
          //             value={inputs.size}
          //             onChange={handleChangeSize}
          //             label="Items per page"
          //           >
          //             <MenuItem value={5}>5</MenuItem>
          //             <MenuItem value={10}>10</MenuItem>
          //             <MenuItem value={20}>20</MenuItem>
          //           </Select>
          //         </FormControl>
          //       </Col>
          //       <Col>
          //         <Pagination
          //           count={newTotalPages}
          //           page={inputs.index}
          //           onChange={handleChangePage}
          //         />
          //       </Col>
          //     </Row>
          //   </>
          // }
        />
      </>
    </Fragment>
  );
};

export default LineChartTurnover;
