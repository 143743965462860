import React from "react";
import { PlusCircle, RefreshCw, Link, ArrowLeft } from "react-feather";
import { Row, Col, Card, Nav } from "reactstrap";
import { Btn } from "../../AbstractElements";

import { useDispatch } from "react-redux";
import { changeModalType } from "../../redux/modal";
import { toggleRefresh } from "../../redux/searchLink";
import { useTranslation } from "react-i18next";
import { CREATE, IMPORT, QR_CODE, REFRESH } from "../../Constant";
import { StyledNavLink } from "./StyledNavLink";


const DataTableHeader = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const changeState = (type) => {
    dispatch(changeModalType(type));
  };

  const refreshPage = () => {
    dispatch(toggleRefresh());
  };

  return (
    <Col md="12" className="project-list">
      <Card>
        <Row>
          <Col sm="6">
            <Nav tabs className="border-tab">
              {props.customBtn && <props.customBtn />}

              {props.createBtn && (
                <div className="text-end">
                  <Btn
                    attrBtn={{
                      onClick: () => changeState("create"),
                      color: "success",
                      className: "btn btn-success btn-sm",
                    }}
                  >
                    <PlusCircle /> {t(CREATE)}
                  </Btn>
                </div>
              )}
              {props.retourBtnLink && (
                <div className="text-end">
                  <button
                    onClick={() => window.history.back()}
                    style={{
                      background: "none",
                      border: "none",
                      marginRight: "20px",
                    }}
                  >
                    <ArrowLeft size={25} style={{ color: "black" }} />
                  </button>
                </div>
              )}
              {props.createBtnLink && (
                <div className="text-end">
                  <Btn
                    attrBtn={{
                      color: "success",
                      className: "btn btn-success btn-sm",
                    }}
                  >
                    <StyledNavLink
                      style={{ color: "white" }}
                      to="/statistics-requests"
                    >
                      <PlusCircle /> {t(CREATE)}
                    </StyledNavLink>
                  </Btn>
                </div>
              )}

              <div className="text-end" style={{ marginLeft: "20px" }}>
                <Btn
                  attrBtn={{
                    onClick: refreshPage,
                    color: "info",
                    className: "btn btn-success btn-sm",
                  }}
                >
                  <RefreshCw /> {t(REFRESH)}
                </Btn>
              </div>

              {props.serialBtn && (
                <div className="text-end" style={{ marginLeft: "20px" }}>
                  <Btn
                    attrBtn={{
                      onClick: () => changeState("QRcode"),
                      color: "dark-dark",
                      className: "btn btn-dark btn-sm",
                    }}
                  >
                    <Link /> {t(QR_CODE)}
                  </Btn>
                </div>
              )}

              {props.importBtn && (
                <div className="text-end" style={{ marginLeft: "20px" }}>
                  <Btn
                    attrBtn={{
                      onClick: () => changeState("import"),
                      color: "dark-dark",
                      className: "btn btn-dark btn-sm",
                    }}
                  >
                    <Link /> {t(IMPORT)}
                  </Btn>
                </div>
              )}
            </Nav>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export default DataTableHeader;
