import React from "react";
import { Editor } from "@monaco-editor/react";
import { Card,Label } from "reactstrap";
import styled from "styled-components";

const EditorInput = ({ register, inputName, oldValue }) => {
  const { name, title } = inputName;

  const handleOnChange = (value) => {
    console.log(value);
    register({ name: name, value: value });
    console.log(name);
  };

  return (
    <>
      <Label>{title}</Label>
      <Card>
        <CustomCard>
          <Editor
            className="editor-container"
            height="200px"
            width="90%"
            language="sql"
            options={{
              fontSize: 18,
              wordWrap: "on",
            }}
            value={oldValue}
            onChange={handleOnChange}
          />
        </CustomCard>
      </Card>
    </>
  );
};

export default EditorInput;

const CustomCard = styled.div`
  margin: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;
