import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

import { Row, Col } from "reactstrap";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import MDBox from "Components/MDBox";
import MDTypography from "Components/MDTypography";
import FilterByCategoryModel from "pages/StatStockPage/FilterByCategoryModel";
import useFetchData from "hooks/useFetchDataWithDep";
import generateSearchLink from "Services/generateSearchLink";

import Details from "./data";
import { getData } from "helper/getData";
import StatisticLoading from "Components/Loading/StatisticLoading";

function Reclamation() {
  function getToday() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0"); // January is 0
    const year = today.getFullYear();

    return `${day}/${month}/${year}`;
  } 
  const [inputs, setInputs] = useState({
    dateValue: getToday(),
    index: 1,
    size: 1000000000,
  });

  const params = {
    date: true,
  };
  
  const searchItems = {
    date: inputs.dateValue,
    index: inputs.index,
    size: inputs.size,
  };

  const url = generateSearchLink(
    "/stat/reservation-client/dispute-details",
    searchItems
  );

  const { data:fetch , isLoading, isError } = useFetchData(url, inputs);
    const fetchData = getData(fetch);


  const { columns, rows } = Details({
    inputs,
    fetchData,
    isLoading,
    isError,
  });




  return (
    <Card>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Reclamation
          </MDTypography>
          <MDBox display="flex" alignItems="center" lineHeight={0}>
            <Icon
              sx={{
                fontWeight: "bold",
                color: ({ palette: { info } }) => info.main,
                mt: -0.5,
              }}
            >
              <ReportProblemIcon />
            </Icon>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>{fetchData.length} done</strong> this year
            </MDTypography>
          </MDBox>
        </MDBox>
        <Row>
          <Col>
            <FilterByCategoryModel
              params={params}
              inputs={inputs}
              setInputs={setInputs}
            />
          </Col>
        </Row>
        <MDBox
          color="text"
          px={2}
          sx={{ cursor: "pointer", fontWeight: "bold" }}
        >
          {inputs.dateValue}
        </MDBox>
      </MDBox>
      {!isLoading && (
        <MDBox>
          <DataTable
            table={{ columns, rows }}
            showTotalEntries={false}
            isSorted={false}
            noEndBorder
            entriesPerPage={false}
          />
        </MDBox>
      )}
      {isLoading && <StatisticLoading />}
    </Card>
  );
}

export default Reclamation;
