import React, { useEffect, useState } from "react";
import { Input, Label, Media } from "reactstrap";
import { areYouSureAlert, changedAlert } from "../Alerts/Alerts";
import axiosClient from "../../Services/axiosClient";
import errorMsg from "../../Services/errorMsg";

const ActiveBtn = ({ patch, state }) => {
  const [isChecked, setIsChecked] = useState(state);

  useEffect(() => {
    setIsChecked(state);
  }, [state]);

  const changedWithSuccess = () => setIsChecked((prevState) => !prevState);

  const changeState = () => {
    // dispatch(toggleRefresh());
    areYouSureAlert().then((result) => {
      if (result.isConfirmed) {
        const newState = { active: !state };

        axiosClient
          .patch(patch, newState)
          .then((res) => {
            changedAlert();
            changedWithSuccess();
          })
          .catch((error) => {
            console.error("Request failed:", error);
            errorMsg(error);
          });
      }
    });
  };

  return (
    <Media body className="text-center icon-state">
      <Label className="switch">
        <Input type="checkbox" onChange={changeState} checked={isChecked} />
        <span className="switch-state"></span>
      </Label>
    </Media>
  );
};

export default ActiveBtn;
