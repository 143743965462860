import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import useFetchData from "../../hooks/useFetchData";

import Breadcrumbs from "../../Components/Breadcrumbs/SheetOf";
import Loading from "../../Components/Loading/Loading";
import NotFound from "../../Components/NotFound";

import Users from "./Users";
import Agency from "./Agency";
import { Fragment, useState } from "react";
import HeaderTabs from "../../Components/Header/HeaderTabs";
import StepsWithIcon from "../../Components/LeftTab/StepsWithIcon";
import { BiUserCircle } from "react-icons/bi";
import { FaAddressCard } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { fullName } from "../../helper/fullName";

const ReadDistributorPage = () => {
  const [state, setState] = useState(0);
  let distributorData;
  const requiredKeys = [
    { key: "firstname", name: "Firstname", icon: <BiUserCircle /> },
    { key: "lastname", name: "Lastname", icon: <BiUserCircle /> },
    { key: "registry", name: "Registry", icon: <BiUserCircle /> },
    { key: "city", name: "City", icon: <BiUserCircle /> },
  ];

  const { code } = useParams();
  const { t } = useTranslation();

  const { data, isLoading, isError } = useFetchData(
    "/back/distributors?code=" + code
  );

  const tabContent = [
    {
      name: t("Agency"),
      content: <Agency profileCode={code} />,
      icon: <FaAddressCard />,
    },
    {
      name: t("Users"),
      content: <Users profileCode={code} />,
      icon: <FaAddressCard />,
    },
  ];

  if (isLoading) return <Loading />;

  if (isError) return <NotFound />;

  if (!isLoading && !isError) {
    distributorData = data.data.results.data.rows[0];
  }

  const breadCrumbsProps = [
    fullName(distributorData.firstname, distributorData.lastname),
  ];
  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <Row>
            <Col xs="12" md="3">
              {!isLoading && (
                <HeaderTabs
                  title={distributorData.company}
                  objectData={distributorData}
                  requiredKeys={requiredKeys}
                />
              )}
            </Col>
            <Col xs="12" md="9">
              <Card>
                <CardBody>
                  <div style={{ margin: "0px 10px 30px 10px" }}>
                    <StepsWithIcon
                      tabContent={tabContent}
                      state={state}
                      setState={setState}
                    />
                  </div>
                  {tabContent[state].content}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default ReadDistributorPage;
