import React, { Fragment } from "react";
import useFetchData from "hooks/useFetchData";
import { getData } from "helper/getData";
import NotFound from "Components/NotFound";
import StatisticLoading from "Components/Loading/StatisticLoading";
import {
  Card,
  Header,
  Body,
  Skill,
  SkillName,
  SkillLevel,
  SkillPercent,
  SkillPercentNumber,
} from "Components/AlerteStock/AlerteStock";
import { Row ,Col} from "reactstrap";
import Grid from "@mui/material/Grid";
import MDBox from "Components/MDBox";


const AlerteMaterialStock = ({ inputs, params }) => {
  const { data, isLoading, isError } = useFetchData(
    "/stat/stock-Materials/low-stock"
  );

  if (isError) return <NotFound />;

  const productStatus = getData(data) ?? [];
  console.log(inputs);

  return (
    <Fragment>
      {isLoading && <StatisticLoading />}
      {!isLoading && (
          <Grid container spacing={3}>
            {productStatus.map((category, index) => (
                <Grid item xs={12} md={6} lg={3}>
                  <MDBox mb={1}>
                    <Card key={index}>
                      <Header>{category.category}</Header>
                      <Body>
                        {category.models.map((model, modelIndex) => {
                          const percentage = (model.total_amount / 10) * 100;
                          return (
                            <Skill key={modelIndex}>
                              <SkillName>{model.model_name}</SkillName>
                              <SkillLevel>
                                <SkillPercent
                                  style={{ width: `${percentage}%` }}
                                />
                              </SkillLevel>
                              <SkillPercentNumber>
                                {model.total_amount}
                              </SkillPercentNumber>
                            </Skill>
                          );
                        })}
                      </Body>
                    </Card>
                  </MDBox>
                </Grid>
            ))}
          </Grid>
      )}
    </Fragment>
  );
};

export default AlerteMaterialStock;
