import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Boxes from "./Boxes";
import Locations from "./Locations";
import Notebook from "./Notebook";
import Printers from "./Printers";
import Taxis from "./Taxis";
import { FaAddressCard } from "react-icons/fa";
import useFetchData from "../../hooks/useFetchData";
import Loading from "../../Components/Loading/Loading";
import NotFound from "../../Components/NotFound";
import {
  MY_TAXIMETER_BOXES,
  MY_TAXIMETER_LOCATIONS,
  MY_TAXIMETER_NOTEBOOK,
  MY_TAXIMETER_PRINTER,
  MY_TAXIMETER_TAXIS,
} from "../../Constant";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../../Components/Breadcrumbs/SheetOf";
import { Card, CardBody, Col, Row } from "reactstrap";
import HeaderTabs from "../../Components/Header/HeaderTabs";
import { HiHomeModern } from "react-icons/hi2";
import StepsWithIcon from "../../Components/LeftTab/StepsWithIcon";

const ReadTaximeterPage = () => {
  const { code } = useParams();
  const [state, setState] = useState(0);
  let taximeterData;
  const { data, isLoading, isError } = useFetchData("/back/taximeter/" + code);
  const { t } = useTranslation();
  const tabContent = [
    {
      name: t(MY_TAXIMETER_BOXES),
      content: <Boxes code={code} />,
      icon: <FaAddressCard />,
    },
    {
      name: t(MY_TAXIMETER_PRINTER),
      content: <Printers code={code} />,
      icon: <FaAddressCard />,
    },
    {
      name: t(MY_TAXIMETER_TAXIS),
      content: <Taxis code={code} />,
      icon: <FaAddressCard />,
    },
    {
      name: t(MY_TAXIMETER_LOCATIONS),
      content: <Locations code={code} />,
      icon: <FaAddressCard />,
    },
    {
      name: t(MY_TAXIMETER_NOTEBOOK),
      content: <Notebook code={code} />,
      icon: <FaAddressCard />,
    },
  ];

  if (isLoading) return <Loading />;

  if (isError) return <NotFound />;

  if (!isLoading && !isError) {
    taximeterData = data.data.results.data;
  }
  const dynamicPart = taximeterData ? taximeterData.serialNumber : null;
  const breadCrumbsProps = [dynamicPart];

  const requiredKeys = [
    {
      key: "meid",
      name: "meid",
      icon: <HiHomeModern />,
    },
    {
      key: "status",
      name: "Status",
      icon: <HiHomeModern />,
    },
    {
      key: "createdAt",
      name: "Created at",
      icon: <HiHomeModern />,
    },
    {
      key: "imei2",
      name: "imei2",
      icon: <HiHomeModern />,
    },
    {
      key: "model",
      name: "Model",
      icon: <HiHomeModern />,
    },
    {
      key: "state",
      name: "State",
      icon: <HiHomeModern />,
    },
  ];
  return (
    <>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />
          <Row>
            <Col xs="12" md="3">
              {!isLoading && (
                <HeaderTabs
                  title={dynamicPart}
                  objectData={taximeterData}
                  requiredKeys={requiredKeys}
                />
              )}
            </Col>
            <Col xs="12" md="9">
              <Card>
                <CardBody>
                  <div style={{ margin: "0px 10px 30px 10px" }}>
                    <StepsWithIcon
                      tabContent={tabContent}
                      state={state}
                      setState={setState}
                    />
                  </div>
                  {tabContent[state].content}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {/* <GetTaximeter code={code} /> */}
      {/* <LeftTab tabContent={tabContent} /> */}
    </>
  );
};

export default ReadTaximeterPage;
