import React from "react";
import { ShoppingCart } from "react-feather";
import { useDispatch } from "react-redux";

import { Btn } from "../../AbstractElements";
import { changeModalType } from "../../redux/modal";
import { setRowMaterialStock } from "../../redux/currentRow";

const CartBtn = ({ row }) => {
  const dispatch = useDispatch();

  const handleCart = () => {
    dispatch(changeModalType("cart"));
    dispatch(setRowMaterialStock(row));
  };
  return (
    <Btn
      attrBtn={{
        onClick: () => handleCart(),
        color: "error",
        variant: "contained",
        className: "btn btn-secondary btn-sm",
      }}
    >
      <ShoppingCart />
    </Btn>
  );
};

export default CartBtn;
