const onChangeInput = (event) => {
  if (event.target === undefined) {
    return { [event.name]: event.value };
  } else {
    const name = event.target.name;
    const value = event.target.value;
    return { [name]: value };
  }
};

export default onChangeInput;
