import { Space } from "@mantine/core";
import React, { Fragment, useEffect, useState } from "react";
import { Panel } from "primereact/panel";
import { InputNumber } from "primereact/inputnumber";

import Translate from "Components/Translate";

import {
  CATEGORY,
  DESCRIPTION,
  DISCOUNT,
  MODEL,
  FINAL_PRICE,
  PRODUCT,
  QUANTITY,
  TTC,
  UNITY_PRICE,
  VAT,
} from "Constant";
import { DeleteProductBtn } from "./EstimatesBtns";
import SelectApiInput from "./SelectApiInput";
import {
  calculatePriceAfterDiscount,
  categoryInp,
  getNumber,
  modelInp,
} from "./data";
import { useDispatch, useSelector } from "react-redux";
import { editProduct } from "../../redux/estimateSlicer";
import axiosClient from "Services/axiosClient";
import { getPriceVat } from "helper/getData";
import { InputText } from "primereact/inputtext";

const calculateProduct = (price_ht = 0, vat, quantity) => {
  const calculate_vat = Number(price_ht) * (Number(vat) / 100);
  const price_vat = (Number(price_ht) + calculate_vat).toFixed(4);
  const price_ttc = Number(price_vat * Number(quantity)).toFixed(4);

  return {
    price_ttc,
  };
};

export default function OneProduct({ index }) {
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({});

  const proData = useSelector((state) => state.estimate.products[index]);
  const model = proData.model;

  useEffect(() => {
    if (inputs.quantity > 1)
      dispatch(
        editProduct({
          data: inputs,
          index: index,
        })
      );
    else dispatch(editProduct({ data: inputs, index: index }));
  }, [inputs]);

  useEffect(() => {
    if (model) {
      axiosClient(`/back/model/${model}/price`)
        .then((res) => {
          const result = getPriceVat(res);
          console.log(result);

          dispatch(editProduct({ data: result, index: index }));
        })
        .catch((errror) => {
          console.error(errror);
        });
    }
  }, [model]);

  const productResult = {
    ...proData,
    ...calculateProduct(proData.price_ht, proData.vat, proData.quantity),
  };

  return (
    <Fragment>
      <Panel
        headerTemplate={
          <div className="p-panel-header justify-content-space-between">
            <div className="flex align-items-center ">
              <span className="font-bold">
                <Translate>{PRODUCT}</Translate>: {index + 1}
              </span>
            </div>
            <div>
              <DeleteProductBtn i={index} />
            </div>
          </div>
        }
        toggleable
      >
        <div className="grid">
          <div className="col">
            <div className="flex flex-column " style={{ minWidth: "150px" }}>
              <label htmlFor="discription">
                <Translate>{CATEGORY}</Translate>
              </label>
              <Fragment key={proData["category"]}>
                <SelectApiInput
                  register={(e) => {
                    const newVal = { [e.name]: e.value };
                    setInputs((preState) => ({ ...preState, ...newVal }));
                  }}
                  inputName={categoryInp}
                  oldValue={proData["category"]}
                />
              </Fragment>
            </div>
          </div>
          <div className="col">
            <div className="flex flex-column " style={{ minWidth: "100px" }}>
              <label htmlFor="discription">
                <Translate>{MODEL}</Translate>
              </label>
              <Fragment key={proData["category"]}>
                <SelectApiInput
                  register={(e) => {
                    const newVal = { [e.name]: e.value };
                    setInputs((preState) => ({ ...preState, ...newVal }));
                  }}
                  inputName={modelInp(proData["category"])}
                  oldValue={proData["model"]}
                  isDisabled={proData["category"] === "" ? true : false}
                />
              </Fragment>
            </div>
          </div>
          <div className="col">
            <div className="flex flex-column " style={{ minWidth: "100px" }}>
              <label htmlFor="quantity">{QUANTITY}</label>
              <InputNumber
                value={proData["quantity"]}
                onValueChange={(e) => {
                  const newVal = { quantity: e.value };
                  setInputs((preState) => ({ ...preState, ...newVal }));
                }}
                min={1}
                showButtons
                size={10}
              />
            </div>
          </div>
          <div className="col">
            <div className="flex flex-column " style={{ minWidth: "100px" }}>
              <label htmlFor="discription">{DESCRIPTION}</label>
              <InputText
                value={proData["description"]}
                onChange={(e) => {
                  const newVal = { description: e.target.value };
                  setInputs((preState) => ({ ...preState, ...newVal }));
                }}
              />
            </div>
          </div>
          <div className="col">
            <div className="flex flex-column " style={{ minWidth: "100px" }}>
              <label htmlFor="discription">
                <Translate>{DISCOUNT} %</Translate>
              </label>
              <InputNumber
                value={proData["discount"]}
                onValueChange={(e) => {
                  const newVal = { discount: e.value };
                  setInputs((preState) => ({ ...preState, ...newVal }));
                }}
                mode="decimal"
                minFractionDigits={1}
                maxFractionDigits={2}
                min={0}
                max={100}
                suffix="%"
                showButtons
                step={0.1}
                size={10}
              />
            </div>
          </div>
          <div className="col" style={{ minWidth: "100px", maxWidth: "150px" }}>
            <div className="flex flex-column ">
              <label>
                <Translate>{UNITY_PRICE}</Translate>
              </label>
              <InputNumber
                value={getNumber(productResult["price_ht"])}
                mode="decimal"
                disabled={true}
                variant="filled"
                size={5}
              />
            </div>
          </div>
          <div className="col" style={{ minWidth: "100px", maxWidth: "150px" }}>
            <div className="flex flex-column ">
              <label>
                <Translate>{VAT}</Translate>
              </label>
              <InputNumber
                value={getNumber(productResult["vat"])}
                mode="decimal"
                disabled={true}
                variant="filled"
                size={5}
                suffix="%"
              />
            </div>
          </div>
          <div className="col" style={{ minWidth: "100px", maxWidth: "150px" }}>
            <div className="flex flex-column ">
              <label>
                <Translate>{TTC}</Translate>
              </label>
              <InputNumber
                value={getNumber(productResult["price_ttc"])}
                mode="decimal"
                disabled={true}
                variant="filled"
                size={5}
              />
            </div>
          </div>
          <div className="col" style={{ minWidth: "100px", maxWidth: "200px" }}>
            <div className="flex flex-column ">
              <label>
                <Translate>{FINAL_PRICE}</Translate>
              </label>
              <InputNumber
                value={calculatePriceAfterDiscount(
                  productResult["price_ttc"],
                  proData["discount"]
                )}
                mode="decimal"
                disabled={true}
                variant="filled"
                size={5}
              />
            </div>
          </div>
        </div>
      </Panel>

      <Space h="lg" />
    </Fragment>
  );
}
