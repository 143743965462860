import React, { Fragment } from "react";

import DataTableHeader from "../../Components/DataTableHeader";
import DataTables from "../../Components/DataTables";
import { API, COLUMNS_PRINTERS } from "./data";

const Printers = ({ code }) => {
  const list = API.list(code) + "/printers";

  return (
    <Fragment>
      <DataTableHeader />
      <DataTables newColumns={COLUMNS_PRINTERS} link={list} />
    </Fragment>
  );
};

export default Printers;
