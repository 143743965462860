import { Card, CardBody, Col, Row } from "reactstrap";
import React, { Fragment, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./estimate.module.css";

import logo from "../../assets/images/logo/logo-1.png";

import getRowsFromRes from "../../helper/getRowsFromRes";
import useFetchData from "../../hooks/useFetchData";

import StaticTextTable from "../../Components/StaticData/StaticTextTable";
import StaticDataTable from "../../Components/DataTables/StaticDataTable";
import StaticTextRows from "../../Components/StaticData/StaticTextRows";
import Breadcrumbs from "../../Components/Breadcrumbs";
import Loading from "../../Components/Loading/Loading";
import NotFound from "../../Components/NotFound";
import LoadingBtn from "../../Components/LoadingBtn";

import ClientsDetails from "./ClientsDetails";
import ChooseProducts from "./ChooseProducts";

import postData from "../../Services/postData";

import {
  BILL,
  CATEGORY,
  CHOOSE_YOUR_PRODUCT,
  CREATE_BILL,
  DESCRIPTION,
  PRODUCTS,
  QUANTITY,
  SENDING,
  SERVICES,
  TOTAL,
  TOTAL_PRICE_TND,
  TOTAL_PRICE_WITH_VAT,
  TOTAL_VAT_AMOUNT_TND,
  UNITY_PRICE,
  VAT,
  VAT_PERCENTAGE,
} from "../../Constant";

function getCodes(arr) {
  return arr.map((item) => item.code);
}

const CreateBillPage = () => {
  const { code } = useParams();
  const componentRef = useRef();
  const [inputs, setInputs] = useState({});
  const [isSending, setIsSending] = useState(false);

  const onSelect = (param, selectedItems) => {
    const codes = getCodes(selectedItems);
    const newVal = { [param]: codes };

    setInputs((prevState) => ({ ...prevState, ...newVal }));
  };

  const onRemove = (param, selectedItems) => {
    const codes = getCodes(selectedItems);
    const newVal = { [param]: codes };

    setInputs((prevState) => ({ ...prevState, ...newVal }));
  };

  const {
    data: estimatesData,
    isLoading,
    isError,
  } = useFetchData("/back/estimate/" + code);

  let estimateData;
  if (isLoading) return <Loading />;

  if (isError) return <NotFound />;

  if (!isLoading && !isError) {
    estimateData = getRowsFromRes(estimatesData);
  }

  const columnsProd = [
    { name: CATEGORY, selector: "category" },
    { name: DESCRIPTION, selector: "description" },
    { name: QUANTITY, selector: "quantity" },
    { name: UNITY_PRICE, selector: "unit_price" },
    { name: VAT, selector: "vat" },
  ];

  const columnsServ = [
    { name: "Service", selector: "service" },
    { name: "Description", selector: "description" },
    { name: "Quantity", selector: "quantity" },
    { name: "Unit price (DTN)", selector: "unit_price" },
  ];
  const devisData = [
    { type: "H1", text: BILL },
    { type: "strong", text: estimateData.estimateNumber },
    { type: "", text: estimateData.createdAt },
  ];

  const addressAndDate = [
    { type: "strong", text: estimateData.address },
    { type: "", text: `${estimateData.city} ${estimateData.zipCode}` },
  ];

  const totalData = [
    { label: TOTAL_PRICE_TND, value: estimateData.price },
    { label: TOTAL_VAT_AMOUNT_TND, value: estimateData.vatAmount },
    { label: TOTAL_PRICE_WITH_VAT, value: estimateData.priceVat },
    { label: VAT_PERCENTAGE, value: estimateData.vat },
  ];

  const breadCrumbsProps = [CREATE_BILL];

  const onSubmit = () => {
    setIsSending(true);
    console.log(estimateData);
    let newValue = {
      client: estimateData.client,
      taxi: estimateData.taxi,
      vatAmount: estimateData.vatAmount,
      price: estimateData.price,
      priceVat: estimateData.priceVat,
      estimate: code,
      products: estimateData.products,
      services: estimateData.services,
    };

    for (let i = 0; i < newValue.products.length; i++) {
      newValue.products[i] = {
        ...estimateData.products[i],
        codes: inputs[estimateData.products[i].category],
      };
    }

    postData(newValue, "/back/bill", setIsSending);
  };

  return (
    <Fragment>
      <Card>
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <div ref={componentRef}>
            <Row style={{ margin: "50px" }}>
              <Col className="col-7">
                <img src={logo} className={styles["logo"]} alt="" />
              </Col>
              <Col className="col-5">
                <StaticTextRows requiredData={devisData} />
              </Col>
            </Row>
            <Row style={{ margin: "50px" }}>
              <Col className="col-7">
                <StaticTextRows requiredData={addressAndDate} />
              </Col>
              <Col className="col-5">
                <ClientsDetails clientCode={estimateData.client} />
              </Col>
            </Row>
            <div style={{ margin: "50px" }}>
              <Breadcrumbs breadCrumbsProps={[PRODUCTS]} />
              <StaticDataTable
                newColumns={columnsProd}
                newRows={estimateData.products}
              />

              <Breadcrumbs breadCrumbsProps={[SERVICES]} />
              <StaticDataTable
                newColumns={columnsServ}
                newRows={estimateData.services}
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StaticTextTable requiredData={totalData} title={TOTAL} />
            </div>
          </div>
          {/* CHOOSE_YOUR_PRODUCT */}
          <Breadcrumbs breadCrumbsProps={[CHOOSE_YOUR_PRODUCT]} />

          <ChooseProducts
            products={estimateData.products}
            onSelect={onSelect}
            onRemove={onRemove}
          />
          <div className={styles["print-btn"]}>
            <LoadingBtn
              isLoading={isSending}
              content={{ done: CREATE_BILL, loading: SENDING }}
              attrBtn={{
                onClick: onSubmit,
                color: "success",
              }}
            />
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default CreateBillPage;
