import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import enJSOn from "./locales/en/translations.json";
import tnJSOn from "./locales/tn/translations.json";
import duJSOn from "./locales/du/translations.json";
import esJSOn from "./locales/es/translations.json";
import frJSOn from "./locales/fr/translations.json";
import ptJSOn from "./locales/pt/translations.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: enJSOn,
      },
      tn: {
        translations: tnJSOn,
      },
      du: {
        translations: duJSOn,
      },
      es: {
        translations: esJSOn,
      },
      fr: {
        translations: frJSOn,
      },
      pt: {
        translations: ptJSOn,
      },
    },
    fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
