import React, { Fragment } from "react";
import { Maximize } from "react-feather";
import LanguageClass from "./Language";
import LogoutClass from "./Logout";

import { useSelector } from "react-redux";

import { LI, UL } from "../../../AbstractElements";

const Rightbar = () => {
  const sidebarResponsive = useSelector(
    (state) => state.toggleStyle.sidebarResponsive
  );

  function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  return (
    <Fragment>
      <div className="nav-right col pull-right right-menu p-0">
        <UL
          attrUL={{
            className: `simple-list d-flex flex-row nav-menus ${
              sidebarResponsive ? "open" : ""
            }`,
          }}>
          <LI>
            <div
              style={{ cursor: "pointer" }}
              className="text-dark"
              onClick={goFull}>
              <Maximize />
            </div>
          </LI>
          <LanguageClass />
          {/* <Bookmarks /> */}
          {/* <Notifications /> */}
          {/* <MoonLight /> */}
          {/* <MessageDrop /> */}
          <LogoutClass />
        </UL>
      </div>
    </Fragment>
  );
};

export default Rightbar;
