import { Fragment, useEffect, useState } from "react";
import { Stepper, Button, Group } from "@mantine/core";

import { useDispatch, useSelector } from "react-redux";
import { addProductForSales, clearSale } from "../../redux/salesSlicer";

import ProdData from "./ProdData";
import ClientData from "./ClientData";
import FinalStep from "./FinalStep";
import SendData from "./SendData";

const CreateSalesVoucher = () => {
  const [active, setActive] = useState(0);

  const dispatch = useDispatch();

  const sales = useSelector((state) => state.sales);

  const nextStep = () =>
    setActive((current) => (current < 2 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  useEffect(() => {
    return () => {
      dispatch(clearSale());
    };
  }, []);

  return (
    <Fragment>
      <Stepper active={active} onStepClick={setActive}>
        <Stepper.Step label="First step" description="Client data">
          <ClientData />
        </Stepper.Step>
        <Stepper.Step label="Second step" description="Products data">
          {sales.products.map((_, i) => (
            <Fragment key={i}>
              <ProdData index={i} />
            </Fragment>
          ))}

          <Group justify="center" mt="xl">
            <Button
              onClick={() => {
                dispatch(addProductForSales());
              }}
            >
              Add product
            </Button>
          </Group>
        </Stepper.Step>
        <Stepper.Completed>
          <FinalStep />
        </Stepper.Completed>
      </Stepper>

      <Group justify="flex-end" mt="xl">
        <Button variant="default" onClick={prevStep}>
          Back
        </Button>
        {active === 2 ? (
          <SendData />
        ) : (
          <Button onClick={nextStep}>Next step</Button>
        )}
      </Group>
    </Fragment>
  );
};

export default CreateSalesVoucher;
