import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    info: {
      main: "#FF7A00", // Bright orange for a clear and vibrant info color
    },
    background: {
      default: "#FFFFFF", // Pure white for a clean and minimal background
    },
  },
  typography: {
    button: {
      textTransform: "none", // Maintain natural text casing for buttons
      fontSize: "1.1rem", // Optimal balance for readability and aesthetics
      fontWeight: 500, // Medium weight for a professional look
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1rem", // Standardize to 1rem for consistency across components
          backgroundColor: "#333333", // Dark background for better contrast
          color: "#FFFFFF", // White text for accessibility
          borderRadius: "4px", // Slight rounding for a modern feel
          padding: "8px 12px", // Comfortable padding for better visibility
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          color: "#1976D2", // Primary blue color for better integration with overall theme
        },
        track: {
          backgroundColor: "#B3E5FC", // Light blue track for contrast with the thumb
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: "#F9FAFB", // Subtle off-white for a softer table background
          borderRadius: "8px", // Rounded corners for a modern appearance
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Light shadow for a slight elevation effect
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#E3F2FD", // Light blue for selected rows to maintain readability
            "&:hover": {
              backgroundColor: "#BBDEFB", // Slightly darker on hover for feedback
            },
          },
        },
      },
    },
  },
});
