import React from "react";

import Translate from "Components/Translate";
import { Controller } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";

const SimpleInput = ({ register, inputName, errors, oldValue, control }) => {
  const { name, title, options, required } = inputName;
  const isReq = required === undefined ? true : false;

  return (
    <div className="col-3">
      <div className="flex flex-column ">
        <label>
          <Translate>{title}</Translate>
          {isReq && <span className="text-danger">*</span>}
        </label>

        <Controller
          defaultValue={oldValue}
          name={name}
          control={control}
          render={({ field }) => (
            <Dropdown
              id={field.name}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={options}
              optionLabel="label"
              invalid={errors[name]}
            />
          )}
        />

        {errors[name] && (
          <small className="text-danger">
            <Translate>{errors[name].message}</Translate>
          </small>
        )}
      </div>
    </div>
  );
};

export default SimpleInput;
