import {getTitleCodeLabel } from "../../helper/getOptions";

// API
export const API = {
  // list: "/back/deliveryForms",
  list: (code) => `/back/deliveryForms/${code}/details`,
  get: "/back/deliveryForm",
  edit: "/back/deliveryForm/",
  create: "/back/deliveryForm",
};

// Columns
export const COLUMNS = [

  { name: "Category", selector: "category" },
  { name: "Category label", selector: "category_label" },
  { name: "Quantity needed", selector: "quantity_needed" },
  { name: "Quantity ready", selector: "quantity_ready" },


];

// Search
export const SEARCH_ITEMS = [
  {
    title: "Status",
    name: "status",
    inputForm: "SelectInput",
    options: [
      { value: "canceled", label: "Canceled" },
      { value: "draft", label: "Draft" },
      { value: "in_preparation", label: "In Preparation" },
      { value: "to_pickup", label: "To Pickup" },
      { value: "in_delivery", label: "In Delivery" },
      { value: "received", label: "Received" },
    ],
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Sender informations",
    placeholder: "sender informations",
    name: "senderInformations",
    inputForm: "SimpleInput",
  },
  {
    title: "agency",
    name: "agency",
    inputForm: "SelectApiInput",
    optionsApi: "/back/agencies/choices",
    getOptions: getTitleCodeLabel,
  },
];

export const EDIT_ITEMS = [
  {
    title: "senderInformations",
    placeholder: "senderInformations",
    name: "senderInformations",
    inputForm: "SimpleInput",
  },
  {
    title: "agency",
    name: "agency",
    inputForm: "SelectApiInput",
    optionsApi: "/back/agencies/choices",
    getOptions: getTitleCodeLabel,
  },
];
