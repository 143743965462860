import { Group } from "@mantine/core";
import BooleanIcon from "../../../Components/DataTableBtns/BooleanIcon";
import EditBtn from "Components/MantineDataTableBtns/EditBtn";
import DeleteBtn from "Components/MantineDataTableBtns/DeleteBtn";

// Urls
export const API = {
  list: "/back/privacypolicies",
  create: "/back/privacypolicy",
};

// Columns
export const COLUMNS = [
  { name: "Destination", selector: "destination" },
  { name: "Version", selector: "version" },
  { name: "Status", selector: "status" },

  { name: "Created at", selector: "created_at" },

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 50,
    Cell: ({ row }) => {
      const { code } = row.original;

      return (
        <Group gap="xs">
          <EditBtn
            read={`/back/privacypolicy/${code}/clone`}
            // extra={fullName(row.firstname, row.lastname)}
          />
          <DeleteBtn delete={`/back/privacypolicy/${code}/cancel`} />,
        </Group>
      );
    },
  },
];
export const CREATE_ITEMS = [
  {
    name: "destination",
    title: "Destination",
    inputForm: "SelectInput",
    options: [
      {
        value: "passenger",
        label: "Passenger",
        name: "destination",
      },
      {
        value: "taxi",
        label: "Taxi",
        name: "destination",
      },
    ],
  },
];
