import { createSlice } from "@reduxjs/toolkit";

export const chooseProdSlicer = createSlice({
  name: "chooseProd",

  initialState: {
    prodList: [],
  },

  reducers: {
    chooseProd: (state, action) => {
      console.log(action.payload);
      console.log(state);
      state.prodList = action.payload;
    },
  },
});

export const { chooseProd } = chooseProdSlicer.actions;

export default chooseProdSlicer.reducer;
