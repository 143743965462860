function generateSearchLink(baseUrl, searchObject) {
  const queryParams = [];

  for (const key in searchObject) {
    if (searchObject.hasOwnProperty(key)) {
      const value = searchObject[key];

      if (
        (typeof value === "string" && value.trim() !== "") ||
        typeof value === "boolean" ||
        typeof value === "number"
      ) {
        queryParams.push(`${key}=${encodeURIComponent(value)}`);
      }
    }
  }

  if (queryParams.length > 0) {
    const queryString = queryParams.join("&");
    return `${baseUrl}?${queryString}`;
  } else {
    return baseUrl;
  }
}

export default generateSearchLink;
