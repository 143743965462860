import React, { Fragment, useState } from "react";
import { getCodeLabel, getModelsLabel } from "../../helper/getOptions";
import SelectApiInput from "Components/Inputs/SelectApiInput";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Row, Col, Label } from "reactstrap";
import dayjs from "dayjs";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import MDBox from "Components/MDBox";
import  Grid  from '@mui/material/Grid';
const FilterByCategoryModel = ({ params, inputs, setInputs, handleChange }) => {
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);

  const register = (e) => {
    setInputs((prevState) => ({ ...prevState, [e.name]: e.value }));
  };

  const handleYearChange = (date) => {
    setSelectedYear(date.year());
    setSelectedMonth(null);
    setSelectedDay(null);
    setInputs((prevState) => ({
      ...prevState,
      dateValue: date.year().toString(),
    }));
  };

  const handleMonthChange = (date) => {
    setSelectedMonth(date.month());
    setSelectedDay(null); 
    setInputs((prevState) => ({
      ...prevState,
      dateValue: `${selectedYear}-${(date.month() + 1)
        .toString()
        .padStart(2, "0")}`,
    }));
  };

  const handleDayChange = (date) => {
    setSelectedDay(date.date());
    setInputs((prevState) => ({
      ...prevState,
      dateValue: `${selectedYear}-${(selectedMonth + 1)
        .toString()
        .padStart(2, "0")}-${date.date().toString().padStart(2, "0")}`,
    }));
  };
  return (
    <Fragment>
      <Row>
        {params.status && (
          <SelectApiInput
            register={register}
            inputName={{
              title: "",
              name: "status",
              optionsApi: "/back/statuses/material",
              getOptions: getCodeLabel,
              extrOptions: {
                code: "",
                label: "All Status",
              },
            }}
            value={inputs.status}
          />
        )}
        {params.statusBooking && (
          <SelectApiInput
            register={register}
            inputName={{
              title: "",
              name: "status",
              optionsApi: "/back/statuses/booking",
              getOptions: getCodeLabel,
              extrOptions: {
                code: "",
                label: "All Status",
              },
            }}
            value={inputs.statusBook}
          />
        )}
        {params.material && (
          <SelectApiInput
            register={register}
            inputName={{
              title: "Raw Material",
              name: "material",
              optionsApi: "/back/rawmaterial/categories",
              getOptions: getCodeLabel,
              extrOptions: {
                code: "",
                label: "All Raw Material",
              },
            }}
            value={inputs.material}
          />
        )}
        {inputs.material && params.material && (
          <Fragment key={inputs.material}>
            <SelectApiInput
              register={register}
              inputName={{
                title: "Raw Material Model",
                name: "modelMaterial",
                optionsApi: `/back/rawmaterial/${inputs.material}/models`,
                getOptions: getCodeLabel,
                extrOptions: {
                  code: "",
                  label: "AllModel",
                },
              }}
              value={inputs.modelMaterial}
            />
          </Fragment>
        )}
        {params.category && (
          <SelectApiInput
            register={register}
            inputName={{
              title: "",
              name: "category",
              optionsApi: "/back/inventory-categories",
              getOptions: getCodeLabel,
              extrOptions: {
                code: "",
                label: "All Category",
              },
            }}
            value={inputs.category}
          />
        )}
        {inputs.category && params.category && (
          <Fragment key={inputs.category}>
            <SelectApiInput
              register={register}
              inputName={{
                title: "",
                name: "model",
                optionsApi: `/back/models/choices/${inputs.category}`,
                getOptions: getModelsLabel,
                extrOptions: {
                  code: "",
                  label: "All Model",
                },
              }}
              value={inputs.model}
            />
          </Fragment>
        )}
        {params.date && (
          <Fragment>
            <Col sm="" style={{ marginBottom: "10px" }}>
              <Label></Label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div style={{ display: "flex", gap: "15px" }}>
                  <DatePicker
                    label="Year"
                    views={["year"]}
                    value={
                      selectedYear ? dayjs(new Date(selectedYear, 0, 1)) : null
                    }
                    onChange={handleYearChange}
                    renderInput={(params) => <input {...params} />}
                    style={{ width: "50px" }}
                  />
                  {selectedYear && (
                    <DatePicker
                      label="Month"
                      views={["month"]}
                      minDate={dayjs(new Date(selectedYear, 0, 1))}
                      maxDate={dayjs(new Date(selectedYear, 11, 31))}
                      value={
                        selectedMonth !== null
                          ? dayjs(new Date(selectedYear, selectedMonth, 1))
                          : null
                      }
                      onChange={handleMonthChange}
                      renderInput={(params) => <input {...params} />}
                      style={{ width: "50px" }}
                    />
                  )}
                  {selectedMonth !== null && (
                    <DatePicker
                      label="Day"
                      views={["day"]}
                      minDate={dayjs(new Date(selectedYear, selectedMonth, 1))}
                      maxDate={dayjs(
                        new Date(selectedYear, selectedMonth + 1, 0)
                      )}
                      value={
                        selectedDay !== null
                          ? dayjs(
                              new Date(selectedYear, selectedMonth, selectedDay)
                            )
                          : null
                      }
                      onChange={handleDayChange}
                      renderInput={(params) => <input {...params} />}
                      style={{ width: "50px" }}
                    />
                  )}
                </div>
              </LocalizationProvider>
            </Col>
          </Fragment>
        )}
        {params.bookingChoices && (
          <MDBox py={2}>
            <FormControl >
              <InputLabel>View Mode</InputLabel>
              <Select
                style={{ height: "35px" }}
                value={inputs.viewMode}
                onChange={handleChange}
              >
                <MenuItem value="both">Both Departure and Arrival</MenuItem>
                <MenuItem value="departure">Departure Only</MenuItem>
                <MenuItem value="arrival">Arrival Only</MenuItem>
              </Select>
            </FormControl>
          </MDBox>
        )}
      </Row>
    </Fragment>
  );
};

export default FilterByCategoryModel;
