import React from "react";
import {
  Sunrise,
  Box,
  Award,
  Cast,
  PenTool,
  ShieldOff,
  HardDrive,
  AlertCircle,
  Printer,
  Zap,
  Monitor,
  ToggleLeft,
  Triangle,
  PhoneCall,
} from "react-feather";

import HorizontalTab from "Components/HorizontalTab";

import Boxes from "./Boxes";
import Taximeter from "./Taximeter";
import Badge from "./Badge";
import Sensor from "./Sensor";
import Luminous from "./Luminous";
import PrinterComp from "./Printer";
import Stylus from "./Stylus";
import Bracket from "./Bracket";
import Sunshield from "./Sunshield";
import ObdAdapter from "./ObdAdapter";
import SosButton from "./SosButton";
import RelaySwitches from "./RelaySwitches";
import FemaleBracket from "./FemaleBracket";

import {
  MY_BADGES,
  MY_BOXES,
  MY_BRACKETS,
  MY_FEMALE_BRACKET,
  MY_LUMINOUS,
  MY_OBD_ADAPTERS,
  MY_PRINTERS,
  MY_RELAY_SWITCHES,
  MY_SENSOR,
  MY_SOS_BUTTONS,
  MY_STYLUS,
  MY_SUNSHIELDS,
  MY_TAXIMETERS,
  SIMS,
} from "Constant";

const FPSPage = () => {
  const equipmentsStock = [
    {
      title: MY_TAXIMETERS,
      elem: <Taximeter />,
      icon: Monitor,
    },
    {
      title: MY_BADGES,
      elem: <Badge />,
      icon: Award,
    },
    {
      title: MY_SENSOR,
      elem: <Sensor />,
      icon: Sunrise,
    },
    {
      title: MY_LUMINOUS,
      elem: <Luminous />,
      icon: Cast,
    },
    {
      title: MY_PRINTERS,
      elem: <PrinterComp />,
      icon: Printer,
    },
    { title: MY_BOXES, elem: <Boxes />, icon: Box },
    {
      title: MY_STYLUS,
      elem: <Stylus />,
      icon: PenTool,
    },

    {
      title: MY_SUNSHIELDS,
      elem: <Sunshield />,
      icon: ShieldOff,
    },
    {
      title: MY_OBD_ADAPTERS,
      elem: <ObdAdapter />,
      icon: HardDrive,
    },
    {
      title: MY_SOS_BUTTONS,
      elem: <SosButton />,
      icon: AlertCircle,
    },
    {
      title: MY_RELAY_SWITCHES,
      elem: <RelaySwitches />,
      icon: ToggleLeft,
    },
    {
      title: MY_BRACKETS,
      elem: <Bracket />,
      icon: Zap,
    },
    // {
    //   title: SIMS,
    //   elem: <Bracket />,
    //   icon: PhoneCall,
    // },
    // {
    //   title: MY_FEMALE_BRACKET,
    //   elem: <FemaleBracket />,
    //   icon: Triangle,
    // },
  ];

  return <HorizontalTab table={equipmentsStock} />;
};
export default FPSPage;
