import { Fragment } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useParams } from "react-router-dom";

import Loading from "../../Components/Loading/Loading";
import NotFound from "../../Components/NotFound";
import Breadcrumbs from "../../Components/Breadcrumbs/SheetOf";
import HeaderTabs from "../../Components/Header/HeaderTabs";

import useFetchData from "../../hooks/useFetchData";
import Products from "./Products";

import { HiHomeModern } from "react-icons/hi2";

const ReadDeliveryFormPage = () => {
  let detailsData;

  const { code } = useParams();
  const { data, isLoading, isError } = useFetchData(
    "/back/deliveryForm/" + code + "/details"
  );

  if (isLoading) return <Loading />;

  if (isError) return <NotFound />;

  if (!isLoading && !isError) {
    detailsData = data.data.results.data;
  }
  const HeaderTabData = detailsData.header;

  const breadCrumbsProps = [HeaderTabData.senderInformations];

  const requiredKeys = [
    { key: "dfNumber", name: "Delivery form number", icon: <HiHomeModern /> },
    {
      key: "senderInformations",
      name: "Sender Informations",
      icon: <HiHomeModern />,
    },
    {
      key: "receiverInformations",
      name: "Receiver Informations",
      icon: <HiHomeModern />,
    },
    { key: "createdAt", name: "created At", icon: <HiHomeModern /> },
    { key: "updatedAt", name: "Updated At", icon: <HiHomeModern /> },
    { key: "status", name: "Status", icon: <HiHomeModern /> },
    { key: "agency", name: "Agency", icon: <HiHomeModern /> },
    { key: "receiver", name: "Receiver", icon: <HiHomeModern /> },
    { key: "purchaseOrder", name: "Purchase Order", icon: <HiHomeModern /> },
  ];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />
          <Row>
            <Col xs="12" md="3">
              {!isLoading && (
                <HeaderTabs
                  title={HeaderTabData.dfNumber}
                  objectData={detailsData.header}
                  requiredKeys={requiredKeys}
                />
              )}
            </Col>
            <Col xs="12" md="9">
              <Products products={detailsData.products} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default ReadDeliveryFormPage;
