import React, { Fragment } from "react";
import EmptyData from "./EmptyData";

const DataNotFound = () => {
  return (
    <Fragment>
      <div style={{ display: "flex", justifyContent: "center", margin: "5px" }}>
        <EmptyData />
      </div>
    </Fragment>
  );
};

export default DataNotFound;
