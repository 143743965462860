import { Group } from "@mantine/core";
import ActiveBtn from "../../Components/MantineDataTableBtns/ActiveBtn";
import EditBtn from "../../Components/MantineDataTableBtns/EditBtn";
import ReadPageBtn from "../../Components/MantineDataTableBtns/ReadPageBtn";

const timeOptions = require("../../Constant/timezone.json");
// Urls
export const API = {
  list: "/back/profiles",
  get: "/back/profile/",
  // GET_PROFILES_CHOICES: "/back/profiles/choices",
  create: "/back/profile",
  edit: "/back/profile/",
};

// Columns
export const COLUMNS = [
  { name: "Label", selector: "label" },
  { name: "Timezone", selector: "timezone" },
  { name: "Locale", selector: "locale" },
  { name: "Created at", selector: "created_at" },

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 80,
    Cell: ({ row }) => {
      const { code, active, label } = row.original;
      return (
        <Group gap="xs">
          <ActiveBtn patch={API.edit + code} state={active} />
          <ReadPageBtn read={`/profiles/${code}`} />
          <EditBtn read={API.get + code} edit={API.edit + code} extra={label} />
        </Group>
      );
    },
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Label",

    placeholder: "Label",
    name: "label",
    inputForm: "SimpleInput",
  },
  {
    title: "Timezone",
    name: "timezone",
    inputForm: "SelectInput",
    options: timeOptions,
  },
  {
    title: "Pause",
    name: "pause",
    inputForm: "CheckBox",
  },
];

export const EDIT_ITEMS = [
  {
    title: "Label",

    placeholder: "Label",
    name: "label",
    inputForm: "SimpleInput",
  },
  {
    title: "Timezone",
    name: "timezone",
    inputForm: "SelectInput",
    options: timeOptions,
  },
  {
    title: "Pause",
    name: "pause",
    inputForm: "CheckBox",
  },
];
