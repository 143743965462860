import React, { useState } from "react";
import { Divider, Button as MantineBtn, FileInput } from "@mantine/core";
import FilterInput from "Components/Inputs";
import handleInputs from "helper/handleInputs";
import { CREATE_ITEMS } from "./data";
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  ModalFooter,
  Row,
} from "reactstrap";
import CancelModalBtn from "Components/ModalApp/CancelModalBtn";
import { Button } from "primereact/button";
import axiosClient from "Services/axiosClient";
import { addAlert, ShowErrorMsg } from "Components/Alerts/Alerts";
import { useDispatch } from "react-redux";
import { toggleRefresh } from "../../redux/searchLink";
import { toggleModal } from "../../redux/modal";
import Translate from "Components/Translate";
import { FILE } from "Constant";

const CreateFirmware = () => {
  const [file, setFile] = useState(null);
  const [inputs, setInputs] = useState({ external: false });
  const [isSending, setIsSending] = useState(false);
  const dispatch = useDispatch();

  const addedWithSuccess = () => {
    dispatch(toggleRefresh());
    dispatch(toggleModal());
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setIsSending(true);
    try {
      const formData = new FormData();

      // Append inputs from form
      for (let key in inputs) {
        formData.append(key, inputs[key]);
      }

      // Append the file
      if (file) {
        formData.append("file", file);
      }

      axiosClient
        .post("/back/firmware", formData)
        .then((res) => {
          addAlert();
          setIsSending(false);
          addedWithSuccess();
        })
        .catch((error) => {
          ShowErrorMsg(error);
          setIsSending(false);
        });
    } catch (error) {
      console.error("Error:", error);
      setIsSending(false);
      // Handle error (e.g., show an error message)
    } finally {
    }
  };

  const handleImportImg = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile); // Store the file in state
    }
  };

  return (
    <>
      <Form onSubmit={onSubmit}>
        <Row>
          {CREATE_ITEMS.map((item, index) => (
            <FilterInput
              key={index}
              item={item}
              handleChange={(e) => handleInputs(e, setInputs)}
              index={index}
              //   oldValue={defVal ? defVal[item.name] : null}
            />
          ))}
          <Col sm="6">
            <FormGroup>
              <Label>
                <Translate>{FILE}</Translate>
                <span className="text-danger">*</span>
              </Label>
              <Input
                id="exampleFile"
                name="file"
                type="file"
                required
                onChange={handleImportImg}
              />
            </FormGroup>
          </Col>
        </Row>

        <Divider my="md" />
        <ModalFooter>
          <CancelModalBtn />
          <Button
            type="submit"
            label="Go"
            severity="success"
            loading={isSending}
            onClick={() => onSubmit}
          />
        </ModalFooter>
      </Form>
    </>
  );
};

export default CreateFirmware;
