import React, { Fragment } from "react";

import EditItem from "Components/EditItemWithZod";

const EditDriver = ({ itemsUpdate }) => {
  const getOldData = (data) => {
    try {
      const res = {
        ...data.data.results.data,
        taxi: data.data.results.data.taxi.code,
      };

      return res;
    } catch (error) {
      return [];
    }
  };

  return (
    <Fragment>
      <EditItem itemsUpdate={itemsUpdate} getOldData={getOldData} />
    </Fragment>
  );
};

export default EditDriver;
