import { Fragment } from "react";

import { Card, CardBody } from "reactstrap";
import { useParams } from "react-router-dom";

import DataTables from "Components/DataTables/DataTable";
import Breadcrumbs from "Components/Breadcrumbs";
import EditItem from "Components/EditItem";
import ModalApp from "Components/ModalApp";
import AddItem from "Components/AddItem";

import { API, CREATE_ITEMS, EDIT_ITEMS } from "./data";

import { CREATE_WORK_ORDERS, EDIT_WORK_ORDER, MY_WORK_ORDER } from "Constant";
//import axiosClient from "Services/axiosClient";

const ReadWorkOrder = () => {
  const { code } = useParams();
  const list = API.list + code;

  const breadCrumbsProps = [MY_WORK_ORDER];

  // Columns
  const COLUMNS = [
    { name: "Work-Order Number", selector: "woNumber" },

    { name: "Status", selector: "status" },

    { name: "Creator", selector: "creator" },

    { name: "Executor", selector: "executor_name" },

    { name: "Description", selector: "description" },

    { name: "Tasks", selector: "tasks" },

    { name: "Created at", selector: "createdAt" },
  ];
  // console.log();
  // return null;
  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTables newColumns={COLUMNS} link={list} />
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_WORK_ORDERS}>
        <AddItem addURL={API.create(code)} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_WORK_ORDER}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default ReadWorkOrder;
