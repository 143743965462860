import React, { Fragment } from "react";
import useFetchData from "hooks/useFetchDataWithDep";
import { getData } from "helper/getData";
import generateSearchLink from "Services/generateSearchLink";
import NotFound from "Components/NotFound";
import ConstructionIcon from "@mui/icons-material/Construction";

import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { Codepen } from "react-feather";
import StatisticLoading from "Components/Loading/StatisticLoading";

const CountMaterialStock = ({ inputs, params }) => {
  const searchItems = {
    category: inputs.material,
    model_code: inputs.modelMaterial,
    amount: params.amountUsed,
  };
  const url = generateSearchLink(
    "/stat/stock-Materials/sum-amounts",
    searchItems
  );
  const { data, isLoading, isError } = useFetchData(url, inputs);

  if (isError) return <NotFound />;

  const productStatus = getData(data) ?? [];

  const totalAmount =
    productStatus.length > 0 ? productStatus[0].total_amount : 0;
  const message = productStatus.length > 0 ? productStatus[0].message : "";
  const color = message.includes("is sufficient") ? "success" : "error";
const messag = `the amount used of ${inputs.material}`;
function getToday() {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0"); // January is 0
  const year = today.getFullYear();

  return `${day}/${month}/${year}`;
} 

  return (
    <Fragment>
      {!isLoading && (
        <>
          {params.material && (
            <ComplexStatisticsCard
              color="dark"
              icon={<Codepen />}
              title="Stock Raw Material"
              count={totalAmount}
              percentage={{
                color: color,
                label: getToday(),
                amount: message,
              }}
            />
          )}
          {params.materialUsed && (
            <ComplexStatisticsCard
              color="primary"
              icon=<ConstructionIcon />
              title="Used Raw Material"
              count={totalAmount}
              percentage={{
                color: "secondary",
                amount: messag,
                label: getToday(),
              }}
            />
          )}
        </>
      )}
      {isLoading && <StatisticLoading />}
    </Fragment>
  );
};

export default CountMaterialStock;
