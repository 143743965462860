import React, { Fragment, useState, useEffect } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import Grid from "@mui/material/Grid";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import { useTranslation } from "react-i18next";
import MDBox from "Components/MDBox";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import FilterByCategoryModel from "./FilterByCategoryModel";
import CountMaterialStock from "pages/StatStockPage/data/CountMaterialStock";
import PurchaseRequestPieChart from "./data/PurchaseRequestPieChart";
import AlerteMaterialStock from "pages/StatReservationPage/data/alerteMaterialStock";

const StatStockPage = () => {
  const { t } = useTranslation();
  const [dateValue, setDateValue] = useState();
  const [inputs, setInputs] = useState({
    material: "",
    modelMaterial: "",
  });

  const params = {
    material: true,
    modelMaterial: true,
  };

  return (
    <>
      <DashboardNavbar />
      <MDBox py={1}></MDBox>
      <Row>
        <Fragment>
          <Row>
            <Col>
              <FilterByCategoryModel
                params={params}
                inputs={inputs}
                setInputs={setInputs}
              />
            </Col>
          </Row>
        </Fragment>
      </Row>
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <CountMaterialStock
                inputs={inputs}
                params={{ material: true, amountUsed: "" }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <CountMaterialStock
                inputs={inputs}
                params={{ materialUsed: true, amountUsed: "amount_used" }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <PurchaseRequestPieChart />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
              <MDBox mb={3}>
                <VerticalBarChart
                  icon={{ color: "secondary", component: <LeaderboardIcon /> }}
                  height="20.125rem"
                  title="Fake dAta Raw Material Stock By Model "
                  description="Coming Soon "
                  chart={{
                    labels: [
                      "pcb_luminous",
                      "pcb_box",
                      "pcb_sensor",
                      "case_luminous",
                      "case_box",
                      "case_sensor",
                      "wire",
                      "pcb_luminous",
                      "pcb_box",
                      "pcb_sensor",
                      "case_luminous",
                      "case_box",
                      "case_sensor",
                      "wire",
                    ],
                    datasets: [
                      {
                        label: "Sales by age",
                        color: "dark",
                        data: [
                          15, 20, 12, 60, 20, 15, 20, 30, 40, 50, 60, 2, 50,
                        ],
                      },
                    ],
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              {/* <Projects /> */}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {/* <OrdersOverview /> */}
            </Grid>
          </Grid>
        </MDBox>
        <Card>
          <CardBody>
            <AlerteMaterialStock />
          </CardBody>
        </Card>
      </MDBox>
    </>
  );
};

export default StatStockPage;

