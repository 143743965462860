import React from "react";
import { Button } from "@mantine/core";

import Translate from "Components/Translate";

import patchItem from "Services/patchItem";

import { CANCEL, CREATE_BILL, DELIVER, READY } from "Constant";

import createItemWithPopUp from "Services/createItemWithPopUp";

export const CancelBtn = ({ url, disabled }) => {
  return (
    <Button
      variant="filled"
      color="red"
      size="xs"
      onClick={() => patchItem(url)}
      disabled={disabled}
    >
      <Translate>{CANCEL}</Translate>
    </Button>
  );
};

export const ReadyBtn = ({ url, disabled }) => {
  return (
    <Button
      variant="filled"
      color="orange"
      size="xs"
      onClick={() => patchItem(url)}
      disabled={disabled}
    >
      <Translate>{READY}</Translate>
    </Button>
  );
};

export const DeliverBtn = ({ url, disabled }) => {
  return (
    <Button
      variant="filled"
      color="orange"
      size="xs"
      onClick={() => patchItem(url)}
      disabled={disabled}
    >
      <Translate>{DELIVER}</Translate>
    </Button>
  );
};

export const CreateBillBtn = ({ url, disabled }) => {
  return (
    <Button
      variant="filled"
      color="green"
      size="xs"
      onClick={() => createItemWithPopUp(url)}
      disabled={disabled}
    >
      <Translate>{CREATE_BILL}</Translate>
    </Button>
  );
};
