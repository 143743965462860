import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import TableResult from "../../Components/InputQuery/Table";
import InputQuery from "../../Components/InputQuery";
import { LI } from "../../AbstractElements";
import useExecuteData from "../../hooks/useExcuteData";

const RequetePage = () => {
  const { loading, columns, data } = useExecuteData("/execute_sql");
  const refBottom = useRef(null);

  useEffect(() => {
    if (data.length > 0){      refBottom.current?.scrollIntoView({behavior:'smooth'});
}
  }, [data]);

  return (
    <>
      <PageWrapper>
        <InputQuery loading={loading} />
        {/*<Sidebar />*/}
      </PageWrapper>
      <TitleContainer>
        <StyledLI>
          <div >
            <StyledH6>Query Result</StyledH6>
          </div>
        </StyledLI>
      </TitleContainer>

      <Container>
        {data.length > 0 ? (
          <div ref={refBottom}>
            <TableResult loading={loading} columns={columns} data={data} />
          </div>
        ) : (
          <Message>Please type a select query to view the result</Message>
        )}
      </Container>
    </>
  );
};
const Message = styled.div`
  text-align: center;
  font-size: 18px;
  color: #888;
`;
const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Container = styled.div`
  margin: 10px;
  margin-bottom: 30px;
`;

const TitleContainer = styled.div`
  padding-left: 30px;
  padding-bottom: 5px;
  margin-top: 50px;
  margin-bottom: 20px;
`;

const StyledLI = styled(LI)`
  &.sidebar-main-title {
    margin-bottom: 10px;
  }
`;

const StyledH6 = styled.h5`
  /* Add styles to H6 */
  color: #e2c636;
  font-size: 25px;
  font-weight: bold;
`;

export default RequetePage;
