import React, { Fragment } from "react";
import { Button } from "primereact/button";

import Translate from "Components/Translate";

import { ADD_PRODUCT, ADD_SERVICE } from "Constant";

import { useDispatch } from "react-redux";
import {
  addProduct,
  addService,
  onRemoveService,
  onRemoveProduct,
} from "../../redux/estimateSlicer";

export const AddProductBtn = () => {
  const handleAddProduct = () => {
    dispatch(addProduct());
  };
  const dispatch = useDispatch();

  return (
    <Fragment>
      <Button
        label={<Translate>{ADD_PRODUCT}</Translate>}
        icon="pi pi-plus"
        iconPos="right"
        severity="success"
        onClick={handleAddProduct}
      />
    </Fragment>
  );
};

export const AddServiceBtn = () => {
  const dispatch = useDispatch();

  const handleAddService = () => {
    dispatch(addService());
  };

  return (
    <Fragment>
      <Button
        label={<Translate>{ADD_SERVICE}</Translate>}
        icon="pi pi-plus"
        iconPos="right"
        severity="success"
        onClick={handleAddService}
      />
    </Fragment>
  );
};

export const DeleteProductBtn = ({ i }) => {
  const dispatch = useDispatch();

  const handleDeleteProduct = () => {
    console.log("ee");

    dispatch(onRemoveProduct(i));
  };
  return (
    <Button
      icon="pi pi-trash"
      rounded
      outlined
      severity="danger"
      aria-label="Cancel"
      onClick={handleDeleteProduct}
    />
  );
};

export const DeleteServiceBtn = ({ i }) => {
  const dispatch = useDispatch();

  const handleDeleteService = () => {
    console.log("ee");

    dispatch(onRemoveService(i));
  };
  return (
    <Button
      icon="pi pi-trash"
      rounded
      outlined
      severity="danger"
      aria-label="Cancel"
      onClick={handleDeleteService}
    />
  );
};
