import { Fragment } from "react";

import { Card, CardBody } from "reactstrap";

import DataTables from "Components/DataTables/DataTable";
import Breadcrumbs from "Components/Breadcrumbs/SheetOf";
import Loading from "Components/Loading/Loading";
import ImportExcel from "Components/ImportExcel";
import EditItem from "Components/EditItem";
import ModalApp from "Components/ModalApp";
import NotFound from "Components/NotFound";
import AddItem from "Components/AddItem";

import { COLUMNS, CREATE_ITEMS, EDIT_ITEMS } from "./data";

import { useParams } from "react-router-dom";
import { CREATE_WORDING, EDIT_WORDING } from "Constant";
import useFetchData from "hooks/useFetchData";

const ReadWordingPage = () => {
  const { code } = useParams();

  const API_LIST = `/back/wording/${code}/translations`;
  const API_CREATE = `/back/wording/${code}/translation`;
  const list = API_LIST;

  const handleImport = (data) => `{"data":${data}}`;

  let workingData;
  const { data, isLoading, isError } = useFetchData("/back/wording/" + code);

  if (isLoading) return <Loading />;

  if (isError) return <NotFound />;

  if (!isLoading && !isError) {
    workingData = data.data.results.data;
  }

  const dynamicPart = ` ${workingData.code} `;

  const breadCrumbsProps = [dynamicPart];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTables newColumns={COLUMNS} link={list} globalSearch={false} />
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_WORDING}>
        <AddItem addURL={API_CREATE} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_WORDING}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>

      <ModalApp type="import">
        <ImportExcel
          importApi={API_LIST}
          file_name="Translation"
          handleImport={handleImport}
        />
      </ModalApp>
    </Fragment>
  );
};

export default ReadWordingPage;
