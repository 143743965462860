import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { SHEET_OF } from "../../Constant";
import Translate from "../Translate";

const Breadcrumbs = ({ breadCrumbsProps, btn }) => {
  const { t } = useTranslation();
  const title = t(breadCrumbsProps[0]);

  console.log(btn);
  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col sm="6">
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <h4>
                  <Translate>{SHEET_OF}</Translate>
                </h4>
                <h4>
                  <Translate>{title}</Translate>
                </h4>
                <h4>{btn}</h4>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default Breadcrumbs;
