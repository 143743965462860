import React, { Fragment } from "react";
import { taxiInp } from "./data";
import { Space } from "@mantine/core";
import { DEPOSIT, REMAINIG_AMOUNT, TAXI, TIME_SCALE } from "Constant";
import Translate from "Components/Translate";
import SelectApiInput from "./SelectApiInput";

const EstimateDetails = ({
  client,
  inputs,
  register,
  errors,
  setErrors,
  remainingAmount,
}) => {
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (value > 0) register((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <Fragment>
      <Space w="md" />

      <div className="formgrid grid">
        <div className="field col-12 md:col-3">
          <label for="lastname6">
            <Translate>{TAXI}</Translate>
          </label>
          <Fragment key={client}>
            <SelectApiInput
              register={(e) => {
                setErrors((prevState) => ({ ...prevState, taxi: false }));
                register((prevState) => ({ ...prevState, taxi: e.value }));
              }}
              inputName={taxiInp(client)}
              isDisabled={!client}
              oldValue={inputs.taxi}
            />
            {errors.taxi && (
              <span className="text-danger">Taxi should not be empty </span>
            )}
          </Fragment>
        </div>
        {/* -- deposit -- */}
        <div className="field col-12 md:col-2">
          <label>
            <Translate>{DEPOSIT}</Translate>
          </label>
          <input
            className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
            onChange={(e) => {
              handleChange(e, "deposit");
            }}
            value={inputs["deposit"]}
            type="number"
            name="deposit"
          />
        </div>

        {/* -- Time scale -- */}
        <div className="field col-12 md:col-2">
          <label>
            <Translate>{TIME_SCALE}</Translate>
          </label>
          <input
            className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
            onChange={(e) => handleChange(e, "timescale")}
            value={inputs["timescale"]}
            type="number"
            name="timescale"
          />
        </div>
        {/* -------- */}

        {/* -- Remainig Amount -- */}
        <div className="field col-12 md:col-2">
          <label>
            <Translate>{REMAINIG_AMOUNT}</Translate>
          </label>
          <input
            className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
            onChange={(e) => handleChange(e, "remainingAmount")}
            value={remainingAmount}
            type="number"
            name="remainingAmount"
            disabled={true}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default EstimateDetails;
