import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";

import StaticTextTable from "Components/StaticData/StaticTextTable";

import { PRICE, PRICE_TTC, TOTAL, VAT } from "Constant";

import calculatePrice from "./calculatePrice";

const TotalResultArray = () => {
  const { products, services } = useSelector((state) => state.estimate);
  const res = calculatePrice(products, services);

  const totalData = [
    { label: PRICE, value: res.price.toFixed(1) },
    { label: VAT, value: res.vatAmount.toFixed(1) },
    { label: PRICE_TTC, value: res.priceVat.toFixed(1) },
  ];

  return (
    <Fragment>
      <StaticTextTable requiredData={totalData} title={TOTAL} />
    </Fragment>
  );
};

export default TotalResultArray;
