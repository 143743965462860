import { Group } from "@mantine/core";
import ActiveBtn from "../../Components/MantineDataTableBtns/ActiveBtn";
import EditBtn from "../../Components/MantineDataTableBtns/EditBtn";
import ReadPageBtn from "../../Components/MantineDataTableBtns/ReadPageBtn";
import { getCodeLabel } from "../../helper/getOptions";

// Urls
export const API = {
  list: "/back/brands",
  create: "/back/brand",
  patch: "/back/brand/",
  edit: "/back/brand/",
};

// Columns
export const COLUMNS = [
  { name: "Label", selector: "label" },
  { name: "Category", selector: "category" },
  { name: "Created at", selector: "created_at" },

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 70,
    Cell: ({ row }) => {
      const { code, active, label } = row.original;
      return (
        <Group gap="xs">
          <ActiveBtn patch={API.patch + code} state={active} />
          <ReadPageBtn read={`/brands/${code}`} />
          <EditBtn read={API.edit + code} extra={label} />
        </Group>
      );
    },
  },
];

// Search
export const SEARCH_ITEMS = [
  {
    title: "Label",

    placeholder: "Label",
    name: "label",
    inputForm: "SimpleInput",
  },
  {
    title: "Category",
    name: "category",
    inputForm: "SelectApiInput",
    optionsApi: "/back/inventory-categories",
    getOptions: getCodeLabel,
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Label",
    placeholder: "Label",
    name: "label",
    inputForm: "SimpleInput",
  },
  {
    title: "Category",
    name: "category",
    inputForm: "SelectApiInput",
    optionsApi: "/back/inventory-categories",
    getOptions: getCodeLabel,
  },
];

export const EDIT_ITEMS = [
  {
    title: "Label",
    placeholder: "Label",
    name: "label",
    inputForm: "SimpleInput",
  },
  {
    title: "Category",
    name: "category",
    inputForm: "SelectApiInput",
    optionsApi: "/back/inventory-categories",
    getOptions: getCodeLabel,
  },
];
