import React, { Fragment, useState} from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import Grid from "@mui/material/Grid";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";


import { useTranslation } from "react-i18next";
import MDBox from "Components/MDBox";
import FilterByCategoryModel from "pages/StatStockPage/FilterByCategoryModel";
import CountMaterialStock from "./data/CountMaterialStock";
import BarChartCountByCategory from "./data/BarChartCountByCategory";
import PieChartStockByCategory from "./data/PieChartStockByCategory";
import BarChartMovement from "./data/BarChartMovement";
import Products from "./data/Products";

const StatStockFinishedProductPage = () => {
  const { t } = useTranslation();
  const [inputs, setInputs] = useState({
    category: "",
    model: "",
    status: "in_stock",
  });

  const params = {
    category: true,
    model: true,
    status: true,
  };

  return (
    <>
      <DashboardNavbar />
      <Grid pt={8} pb={1} item xs={12} md={6} lg={12}>
        <MDBox mb={3}>
          <Products />
        </MDBox>
      </Grid>
      <Row>
        <Fragment>
          <Row>
            <Col>
              <FilterByCategoryModel
                params={params}
                inputs={inputs}
                setInputs={setInputs}
              />
            </Col>
          </Row>
        </Fragment>
      </Row>
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <CountMaterialStock inputs={inputs} params={{ material: true }} />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <CountMaterialStock
                inputs={inputs}
                params={{ materialUsed: true }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={5}>
              <MDBox mb={3}>
                <PieChartStockByCategory />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={7}>
              <MDBox mb={3}>
                <BarChartMovement />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <BarChartCountByCategory />
      </MDBox>
    </>
  );
};

export default StatStockFinishedProductPage;
