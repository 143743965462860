import { Fragment } from "react";
import { EDIT_FAQ, MY_FAQ } from "Constant";

import DataTable from "Components/DataTables/DataTable";
import Breadcrumbs from "Components/Breadcrumbs";
import ModalApp from "Components/ModalApp";
import EditItem from "Components/EditItem";

import { API, COLUMNS, EDIT_ITEMS } from "./data";
import { Paper } from "@mantine/core";
import EditRow from "./EditRow";
import CreateRow from "./CreateRow";

const TestDataTableV2 = () => {
  const breadCrumbsProps = [MY_FAQ];
  return (
    <Fragment>
      <Paper shadow="md" withBorder p="xl">
        <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />
        <DataTable newColumns={COLUMNS} link={API.list} />
      </Paper>
      <ModalApp type="edit" title={EDIT_FAQ}>
        <EditRow />
      </ModalApp>

      <ModalApp type="create" title={EDIT_FAQ}>
        <CreateRow />
      </ModalApp>
    </Fragment>
  );
};

export default TestDataTableV2;
