import React, { Fragment, useState, useEffect } from "react";
import DataNotFound from "Components/NotFound/DataNotFound";
import { getData } from "helper/getData";
import { Row, Col } from "reactstrap";
import generateSearchLink from "Services/generateSearchLink";
import useFetchData from "hooks/useFetchDataWithDep";

import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import CategoryIcon from "@mui/icons-material/Category";
import FilterByCategoryModel from "pages/StatStockPage/FilterByCategoryModel";
import Pagination from "@mui/material/Pagination";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";

const BarChartMovement = () => {
  const [inputs, setInputs] = useState({
    category: "",
    model: "",
    dateValue: "",
    index: 1,
    size: 10,
  });

  let totalItems = 0;

  let category = inputs.category || "";
  let cat = category.toUpperCase();
  const searchItems = {
    date: inputs.dateValue,
    category: cat,
    model: inputs.model,
    index: inputs.index,
    size: inputs.size,
  };
  const url = generateSearchLink("/stat/stock/movement", searchItems);
  let labels = [];
  let labelRes = [];
  const { data, isLoading, isError } = useFetchData(url, inputs);

  const params = {
    category: true,
    model: true,
    date: true,
  };

  let dataPie = {};

  const productStatus = getData(data);
  totalItems = productStatus.length;
  productStatus.forEach((elem) => {
    labels.push(elem.label);
    labelRes.push(elem.data);
  });

  dataPie = {
    labels: labels,
    datasets: [
      {
        label: "Number of categories",
        data: labelRes,
        borderWidth: 1,
      },
    ],
  };
  const newTotalPages = Math.ceil(totalItems / inputs.size);

  if (isError) return <DataNotFound />;

  const handleChangePage = (value) => {
    setInputs((prevState) => ({
      ...prevState,
      index: value,
    }));
  };

  const handleChangeSize = (event) => {
    setInputs((prevState) => ({
      ...prevState,
      size: event.target.value,
      index: 1,
    }));
  };

  return (
    <Fragment>
      <>
        <VerticalBarChart
          icon={{ color: "warning", component: <CategoryIcon /> }}
          height="17rem"
          title="Quantity Of Finished Product "
          description="By Product"
          chart={dataPie}
          isLoading={isLoading}
          customComponent={
            <FilterByCategoryModel
              params={params}
              inputs={inputs}
              setInputs={setInputs}
            />
          }
          pagination={
            <>
              <Row>
                <Col>
                  <FormControl
                    variant="outlined"
                    style={{ minWidth: 120, padding: 20, marginRight: 20 }}
                  >
                    <InputLabel id="select-size-label">
                      Items per page
                    </InputLabel>
                    <Select
                      labelId="select-size-label"
                      value={inputs.size}
                      onChange={handleChangeSize}
                      label="Items per page"
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
                <Col>
                  <Pagination
                    count={newTotalPages}
                    page={inputs.index}
                    onChange={handleChangePage}
                  />
                </Col>
              </Row>
            </>
          }
        />
      </>
    </Fragment>
  );
};

export default BarChartMovement;
