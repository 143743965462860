import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { Card, CardBody } from "reactstrap";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ data }) => {
  const options = {
    maintainAspectRatio: true,
  };

  console.log(data);
  return (
    <Card style={{ width: "600px", height: "600px" }}>
      <CardBody>
        <Pie data={data} options={options} />
      </CardBody>
    </Card>
  );
};

export default PieChart;
