import React, { useState } from "react";
import { Fragment } from "react";
import Select from "react-select";
import { Col, FormGroup, Label } from "reactstrap";
import SelectSpinner from "../SelectSpinner";
import useFetchData from "../../hooks/useFetchData";
import SelectApiInput from "./SelectApiInput";
import { getData } from "../../helper/getData";
import SelectIsMultiApi from "./SelectIsMultiApi";

const MultiSelectApiInput = ({ register, inputName, oldValue }) => {
  const [mainSelect, setMainSelect] = useState(false);

  const { name, title, optionsApi, getOptions, secondSelect, secondInp } =
    inputName;
  const { data, isLoading, isError } = useFetchData(optionsApi);
  let options = [];
  const handleOnChange = (e) => {
    setMainSelect(e.value);
    register({ name: name, value: e.value });
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#fbdc10" : "#ccc",
      boxShadow: state.isFocused ? "0 0 0 0.2rem #fbdc10" : "none",
    }),
  };

  if (isError) options = [];
  if (!isLoading) options = getOptions(getData(data));

  const props = secondSelect(mainSelect);
  return (
    <Fragment>
      <Col sm="4">
        <FormGroup>
          <Label>{title}</Label>
          <Select
            styles={customStyles}
            placeholder={oldValue}
            onChange={handleOnChange}
            options={options}
            name={name}
            required
            components={
              isLoading
                ? {
                    DropdownIndicator: SelectSpinner,
                  }
                : {}
            }
          />
        </FormGroup>
      </Col>
      <Fragment key={props.optionsApi}>
        {secondInp ? (
          <SelectIsMultiApi
            register={register}
            inputName={props}
            isDisabled={mainSelect ? false : true}
          />
        ) : (
          <SelectApiInput
            register={register}
            inputName={props}
            isDisabled={mainSelect ? false : true}
          />
        )}
      </Fragment>
    </Fragment>
  );
};
export default MultiSelectApiInput;
