import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShowErrorMsg} from "../Components/Alerts/Alerts";
import {
  setButtonClicked,
} from "../redux/requeteSql";
import axiosClient from "../Services/axiosClient";
import { createMRTColumnHelper } from "material-react-table";
import getRowsFromRes from "../helper/getRowsFromRes";

const columnHelper = createMRTColumnHelper();
const url = "/stat/query/execute-query";

const useExecuteData = () => {
  const dispatch = useDispatch();
  const { editorContent, buttonClicked } = useSelector(
    (state) => state.requeteSql
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  const execute = async () => {
    try {
      setLoading(true);
      const response = await axiosClient.post(url, {
        query : editorContent,
      });

        const responseData = getRowsFromRes(response);

        if (responseData) {
          setColumns(
            Object.keys(responseData[0]).map((key) =>
              columnHelper.accessor(key, {
                header: key,
              })
            )
          );
          setData(responseData);
        }else {
        ShowErrorMsg(response.data.results.error);
      }
    } catch (error) {
        ShowErrorMsg("Une erreur s'est produite lors de la requête.",error);
      setError(error);
    } finally {
      setLoading(false);
      dispatch(setButtonClicked(false)); 
    }
  };



  useEffect(() => {
    if (buttonClicked) {
      execute();
    }
  }, [buttonClicked, editorContent]);

  return { execute, loading, error, columns, data};
};

export default useExecuteData;
