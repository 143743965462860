import axiosClient from "../../Services/axiosClient";
import errorMsg from "../../Services/errorMsg";

import { changedAlert } from "../Alerts/Alerts";

const editData = (inputs, oldData, itemsUpdate, api, fn, success) => {
  fn(true);

  let postData = {};
  itemsUpdate.forEach((item) => {
    const key = item.name;
    if (inputs.hasOwnProperty(key)) {
      postData[key] = inputs[key];
    } else {
      postData[key] = oldData[key];
    }
  });

  axiosClient
    .put(api, postData)
    .then(() => {
      changedAlert();
      fn(false);
      success();
    })
    .catch((error) => {
      console.error("Request failed:", error);
      errorMsg(error);
      fn(false);
    });
};
export default editData;
