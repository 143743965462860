import React, { Fragment, useState } from "react";
import useFetchData from "hooks/useFetchDataWithDep";
import { getData } from "helper/getData";
import generateSearchLink from "Services/generateSearchLink";

import NotFound from "Components/NotFound";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";

import CategoryIcon from "@mui/icons-material/Category";
import PieChart from "examples/Charts/PieChart";
import FilterByCategoryModel from "pages/StatStockPage/FilterByCategoryModel";

const PieChartStockByCategory = () => {
  const [inputs, setInputs] = useState({
    category: "",
    model: "",
    dateValue: "",
  });

  const params = {
    category: true,
    model: true,
    date: true,
  };

  let category = inputs.category || "";
  let cat = category.toUpperCase();
  const searchItems = {
    category: cat,
    model: inputs.model,
    date: inputs.dateValue,
  };
  const url = generateSearchLink("/stat/stock/product-by-status", searchItems);

  let labels = [];
  let labelRes = [];
  const { data, isLoading, isError } = useFetchData(url, inputs);

  let dataPie = {};

  if (isError) return <NotFound />;
  if (!isLoading) {
    const productStatus = getData(data);

    productStatus.forEach((elem) => {
      labels.push(elem.label);
      labelRes.push(elem.data);
    });

    dataPie = {
      labels: labels,
      datasets: {
        label: `Quantity Of Category ${category ?? ""} model ${
          inputs.model ?? ""
        }`,
        backgroundColors: [
          "primary",
          "secondary",
          "info",
          "success",
          "warning",
          "error",
          "light",
          "dark",
        ],
        data: labelRes,
      },
    };
  }

  return (
    <Fragment>
      <PieChart
        icon={{ color: "info", component: <MilitaryTechIcon /> }}
        title="Finished Product"
        description="By Status"
        height="320px"
        chart={dataPie}
        isLoading={isLoading}
        customComponent={
          <FilterByCategoryModel
            params={params}
            inputs={inputs}
            setInputs={setInputs}
          />
        }
      />
    </Fragment>
  );
};

export default PieChartStockByCategory;
