import { useMemo, useState } from "react";
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  // createRow,
  useMaterialReactTable,
} from "material-react-table";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { createTheme, ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import { useCreateUser, useUpdateUser, useDeleteUser } from "./customHooks";
import { validateRequired } from "./validations";
import useFetchData from "hooks/useFetchData";
import getRows from "./getRows";
import { Card, CardBody } from "reactstrap";
import Breadcrumbs from "Components/Breadcrumbs";
import { MY_FAQ } from "Constant";
import { Button } from "primereact/button";
import CreateRowDialogContent from "./CreateRowDialogContent";

const Example = () => {
  const [validationErrors, setValidationErrors] = useState({});
  const {
    data: fetchedUsers = [],
    isError: isLoadingUsersError,
    isLoading: isLoadingUsers,
  } = useFetchData("/back/faqs");

  const tableTheme = createTheme(theme);

  const columns = useMemo(
    () => [
      {
        accessorKey: "question",
        header: "Question",
        enableEditing: false,
        size: 80,
        create: true,
      },
      {
        accessorKey: "answer",
        header: "Answer",
        create: true,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.firstName,
          helperText: validationErrors?.firstName,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              firstName: undefined,
            }),
          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        id: "actions",
        header: "Actions",
        size: 5,
        enableEditing: false,
        Cell: ({ row, table }) => {
          return (
            <Box
              sx={{
                display: "flex",
                gap: "0.5rem",
                width: "10px",
                // justifyContent: "center",
              }}
            >
              <Tooltip title="Edit">
                <IconButton onClick={() => table.setEditingRow(row)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  color="error"
                  onClick={() => openDeleteConfirmModal(row)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    ],
    [validationErrors]
  );

  //call CREATE hook
  const { mutateAsync: createUser, isPending: isCreatingUser } =
    useCreateUser();
  //call READ hook

  //call UPDATE hook
  const { mutateAsync: updateUser, isPending: isUpdatingUser } =
    useUpdateUser();
  //call DELETE hook
  const { mutateAsync: deleteUser, isPending: isDeletingUser } =
    useDeleteUser();

  //CREATE action
  const handleCreateUser = async ({ values, table }) => {
    console.log(values);

    const newValidationErrors = validateRequired(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await createUser(values);
    table.setCreatingRow(null); //exit creating mode
  };

  //UPDATE action
  const handleSaveUser = async ({ values, table }) => {
    const newValidationErrors = validateRequired(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateUser(values);
    table.setEditingRow(null); //exit editing mode
  };

  //DELETE action
  const openDeleteConfirmModal = (row) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      deleteUser(row.original.id);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: getRows(fetchedUsers),
    createDisplayMode: "modal", //default ('row', and 'custom' are also available)
    editDisplayMode: "modal", //default ('row', 'cell', 'table', and 'custom' are also available)
    // enableEditing: true,
    getRowId: (row) => row.id,
    muiToolbarAlertBannerProps: isLoadingUsersError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateUser,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveUser,
    //optionally customize modal content
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => {
      return (
        <CreateRowDialogContent
          internalEditComponents={internalEditComponents}
          row={row}
          table={table}
          columns={columns}
        />
      );
    },
    //optionally customize modal content
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        {/* <DialogTitle variant="h3">Edit User</DialogTitle> */}
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    // renderRowActions: ({ row, table }) => {
    //   return (
    //     <Box sx={{ display: "flex", gap: "1rem" }}>
    //       <Tooltip title="Edit">
    //         <IconButton onClick={() => table.setEditingRow(row)}>
    //           <EditIcon />
    //         </IconButton>
    //       </Tooltip>
    //       <Tooltip title="Delete">
    //         <IconButton
    //           color="error"
    //           onClick={() => openDeleteConfirmModal(row)}
    //         >
    //           <DeleteIcon />
    //         </IconButton>
    //       </Tooltip>
    //     </Box>
    //   );
    // },
    renderTopToolbarCustomActions: ({ table }) => (
      <div style={{ margin: "5px" }}>
        <Button
          label="Create"
          icon="pi pi-plus"
          severity="success"
          onClick={() => {
            table.setCreatingRow(true); //simplest way to open the create row modal with no default values
            //or you can pass in a row object to set default values with the `createRow` helper function
            // table.setCreatingRow(
            //   createRow(table, {
            //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
            //   }),
            // );
          }}
          raised
        />
      </div>
    ),
    state: {
      isLoading: isLoadingUsers,
      isSaving: isCreatingUser || isUpdatingUser || isDeletingUser,
      showAlertBanner: isLoadingUsersError,
      showProgressBars: isLoadingUsers,
    },
  });

  return (
    <ThemeProvider theme={tableTheme}>
      <MaterialReactTable table={table} />
    </ThemeProvider>
  );
};

const queryClient = new QueryClient();

const NewFaqPage = () => {
  const breadCrumbsProps = [MY_FAQ];
  return (
    //Put this with your other react-query providers near root of your app
    <QueryClientProvider client={queryClient}>
      <Card>
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <Example />
        </CardBody>
      </Card>
    </QueryClientProvider>
  );
};

export default NewFaqPage;
