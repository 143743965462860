import { Group } from "@mantine/core";
import { activeObj } from "../../Constant/objs";
import { getCodeLabel } from "../../helper/getOptions";
import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";
import EditBtn from "Components/MantineDataTableBtns/EditBtn";
import {
  CATEGORY,
  Category,
  CHECKSUM,
  CODE_VERSION,
  CREATED_AT,
  EXTERNAL,
  FIRMWARE,
  PRIVATE_CHECKSUM,
} from "Constant";

// Urls
export const API = {
  list: "/back/firmwares",
  get: "/back/firmware/",
  // GET_FIRMWARES_CHOICES: "/back/firmwares/choices",
  create: "/back/firmware",
  patch: "/back/firmware/",
};

// Columns
export const COLUMNS = [
  { name: "Code version", selector: "code_version" },
  {
    name: Category,
    selector: "category",
  },
  {
    name: CHECKSUM,
    selector: "checksum",
  },
  {
    name: CREATED_AT,
    selector: "created_at",
  },

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 70,
    Cell: ({ row }) => {
      const { code, active, category } = row.original;
      return (
        <Group gap="xs">
          <ActiveBtn patch={API.patch + code} state={active} />
          <EditBtn read={API.get + code} extra={category} />
        </Group>
      );
    },
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: CODE_VERSION,
    placeholder: CODE_VERSION,
    name: "code_version",
    inputForm: "SimpleInput",
  },
  {
    title: CHECKSUM,
    placeholder: CHECKSUM,
    name: "checksum",
    inputForm: "SimpleInput",
  },

  {
    name: "category",
    title: CATEGORY,
    inputForm: "SelectInput",
    options: [
      {
        value: "taximeter",
        label: "Taximeter",
      },
      {
        value: "box",
        label: "Box",
      },
      {
        value: "luminous",
        label: "Luminous",
      },
      {
        value: "sensor",
        label: "Sensor",
      },
    ],
  },
  {
    title: EXTERNAL,
    placeholder: "External",
    name: "external",
    inputForm: "CheckBox",
  },
];

export const EDIT_ITEMS = [
  {
    title: CODE_VERSION,
    placeholder: CODE_VERSION,
    name: "code_version",
    inputForm: "SimpleInput",
  },

  {
    title: CHECKSUM,
    placeholder: CHECKSUM,
    name: "checksum",
    inputForm: "SimpleInput",
  },

  {
    title: "Category",
    name: "category",
    inputForm: "SelectApiInput",
    optionsApi: "/back/inventory-categories",
    getOptions: getCodeLabel,
  },

  {
    title: "External",
    placeholder: "external",
    name: "external",
    inputForm: "CheckBox",
  },
];
