import React, {useState } from "react";
import { Card, CardBody, Form, Row } from "reactstrap";
import { SEND, SENDING } from "../../Constant";
import LoadingBtn from "../../Components/LoadingBtn";
import { useDispatch, useSelector } from "react-redux";
import axiosClient from "../../Services/axiosClient";
import onChangeInput from "../../helper/onChangeInput";
import { areYouSureAlert, changedAlert } from "../../Components/Alerts/Alerts";
import errorMsg from "../../Services/errorMsg";
import { toggleRefresh } from "../../redux/searchLink";
import { toggleModal } from "../../redux/modal";
import NumberInput from "../../Components/Inputs/NumberInput";

const StockCart = () => {
  const dispatch = useDispatch();

  const [quantity, setQuantity] = useState(NaN);
  const [isSending, setIsSending] = useState(false);

  const { rowMaterialStock } = useSelector((state) => state.currentRow);

  const postData = {
    quantity: quantity,
    category: rowMaterialStock.category,
    model: rowMaterialStock.model,
  };

  const submitedWithSuccess = () => {
    dispatch(toggleRefresh());
    dispatch(toggleModal());
  };

  const onSubmit = () => {
    areYouSureAlert().then((result) => {
      if (result.isConfirmed) {
        setIsSending(true);
        axiosClient
          .post("/back/rawmaterialpr", postData)
          .then((res) => {
            changedAlert();
            submitedWithSuccess();
            setIsSending(false);
          })
          .catch((error) => {
            console.error("Request failed:", error);
            errorMsg(error);
            setIsSending(false);
          });
      }
    });
  };

  const handleChange = (e) => {
    setQuantity(onChangeInput(e).quantity);
  };

  return (
    <Card>
      <CardBody>
        <Form className="theme-form">
          <Row>
            <NumberInput
              register={(e) => handleChange(e)}
              inputName={{
                title: "Quantity",
                name: "quantity",
                inputForm: "NumberInput",
                number: true,
              }}
            />
          </Row>
        </Form>

        <LoadingBtn
          isLoading={isSending}
          content={{ done: SEND, loading: SENDING }}
          attrBtn={{
            onClick: onSubmit,
            color: "success",
          }}
        />
      </CardBody>
    </Card>
  );
};

export default StockCart;
