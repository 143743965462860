const getRowRes = (data) => {
  console.log(data);
  try {
    const res = data.data.results.data;
    console.log(res);
    return res;
  } catch {
    return {};
  }
};
export default getRowRes;
