import React from "react";
import { CheckCircle } from "react-feather";
import { Btn } from "../../AbstractElements";

const DoneBtn = () => {
  return (
    <Btn
      attrBtn={{
        color: "success",
        variant: "contained",
        className: "btn btn-secondary btn-sm",
        style: { fontSize: "11px", whiteSpace: "nowrap" },
      }}
    >
      <CheckCircle />
    </Btn>
  );
};

export default DoneBtn;
