import { Fragment } from "react";
import { Card, CardBody } from "reactstrap";

import DataTables from "Components/DataTables/DataTable";
import EditItem from "Components/EditItemWithZod";
import AddItem from "Components/AddItemWithZod";
import Breadcrumbs from "Components/Breadcrumbs";
import ModalApp from "Components/ModalApp";

import { API, COLUMNS, CREATE_ITEMS, EDIT_ITEMS } from "./data";

import { CREATE_SERVICE, EDIT_SERVICE, MY_SERVICES } from "Constant";

const PricingServicePage = () => {
  const list = API.list;
  const breadCrumbsProps = [MY_SERVICES];
  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />
          <DataTables newColumns={COLUMNS} link={list} />
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_SERVICE}>
        <AddItem addURL={API.create} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_SERVICE}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default PricingServicePage;
