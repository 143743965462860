import { getCodeLabel } from "helper/getOptions";

// Urls
export const API = {
  list: (p) => `/back/profile/${p}/parametervalues`,
  create: (p) => `/back/profile/${p}/parametervalue`,
};

// Columns
export const COLUMNS = [
  {
    name: "label",
    selector: "param_label",
  },
  {
    name: "Value",
    selector: "value",
  },

  {
    header: "Default",
    id: "default",
    // button: "true",
    Cell: ({ row }) => {
      const val = row.original.defaul;
      return val === undefined ? "" : val.toString();
    },
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Parameter",
    name: "parameter",
    inputForm: "SelectApiInput",
    optionsApi: "/back/paramters/choices",
    getOptions: getCodeLabel,
  },
  {
    title: "Value",
    placeholder: "value",
    name: "value",
    inputForm: "SimpleInput",
  },
  {
    title: "Date Begin",
    placeholder: "date begin",
    name: "date_begin",
    inputForm: "DateInput",
  },
];

export const EDIT_ITEMS = [
  {
    title: "Parameter",
    name: "parameter",
    inputForm: "SelectApiInput",
    optionsApi: "/back/paramters/choices",
    getOptions: getCodeLabel,
  },
  {
    title: "Value",
    placeholder: "value",
    name: "value",
    inputForm: "SimpleInput",
  },
  {
    title: "Date Begin",
    placeholder: "date begin",
    name: "date_begin",
    inputForm: "DateInput",
  },
];
