import axiosClient from "../../Services/axiosClient";
import errorMsg from "../../Services/errorMsg";

import { addAlert } from "../Alerts/Alerts";

const postData = async (new_data, URL_CREATE, changeLoadingState, success) => {
  console.log(new_data);
  await axiosClient
    .post(URL_CREATE, new_data)
    .then((res) => {
      addAlert();
      success();
    })
    .catch((error) => {
      errorMsg(error);
    });
  changeLoadingState(false);
};

export default postData;
