import styled from "styled-components";

const Card = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  &:hover {
    border-bottom: 5px solid red; /* Change to red to indicate alert */
    transform: scale(1.2);
    transition: 1s ease-in-out;
  }
`;

const Header = styled.div`
  background-color: red;
  color: #fff;
  padding: 5px;
  text-align: center;
  font-size: 18px;
`;

const Body = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;

  padding-bottom: 5px;
`;

const Skill = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const SkillName = styled.div`
  width: 40%;
  font-size: 10px;
`;

const SkillLevel = styled.div`
  width: 60%;
  height: 10px;
  background-color: #eee;
  border-radius: 10px;
  overflow: hidden;
  margin-left: 20px;
`;

const SkillPercent = styled.div`
  background-color: red;
  height: 100%;
`;

const SkillPercentNumber = styled.div`
  margin-left: 20px;
  font-size: 16px;
`;

export {
  Card,
  Header,
  Body,
  Skill,
  SkillName,
  SkillLevel,
  SkillPercent,
  SkillPercentNumber,
};
