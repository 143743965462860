import { Fragment } from "react";

import { Card, CardBody } from "reactstrap";

import DataTables from "Components/DataTables/DataTable";
import Breadcrumbs from "Components/Breadcrumbs";
import EditItem from "Components/EditItem";
import ModalApp from "Components/ModalApp";
import AddItem from "Components/AddItem";

import { API, COLUMNS, CREATE_ITEMS, EDIT_ITEMS } from "./data";

import {
  CREATE_COLLABORATOR,
  EDIT_COLLABORATOR,
  MY_COLLABORATORS,
} from "Constant";

const CollaboratorPage = () => {
  const list = API.list;
  const breadCrumbsProps = [MY_COLLABORATORS];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTables newColumns={COLUMNS} link={list} />
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_COLLABORATOR}>
        <AddItem addURL={API.create} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_COLLABORATOR}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default CollaboratorPage;
