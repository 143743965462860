import { Fragment } from "react";

import { Card, CardBody, Col, Row } from "reactstrap";

import DataTables from "../../Components/DataTables";
import DataTableHeader from "../../Components/DataTableHeader";
import Breadcrumbs from "../../Components/Breadcrumbs/SheetOf";
import EditItem from "../../Components/EditItem";
import ModalApp from "../../Components/ModalApp";
import AddItem from "../../Components/AddItem";
import ActiveBtn from "../../Components/DataTableBtns/ActiveBtn";
import EditBtn from "../../Components/DataTableBtns/EditBtn";
import DeleteBtn from "../../Components/DataTableBtns/DeleteBtn";

import { API, CREATE_ITEMS, EDIT_ITEMS } from "./data";

import { useParams } from "react-router-dom";
import {
  CREATE_COLLABORATOR,
  EDIT_COLLABORATOR,
  MY_COLLABORATOR,
} from "../../Constant";
import { useTranslation } from "react-i18next";
import { fullName } from "../../helper/fullName";
import HeaderTabs from "Components/Header/HeaderTabs";
import { HiHomeModern } from "react-icons/hi2";
import { AiOutlineIdcard } from "react-icons/ai";
import useFetchData from "hooks/useFetchData";
import Loading from "Components/Loading/Loading";
import NotFound from "Components/NotFound";

const ReadCollaborator = () => {
  const { code: collabCode } = useParams();
  const list = API.list(collabCode);
  const { t } = useTranslation();
  const COLUMNS = [
    { name: "First name", selector: "firstname" },
    { name: "Last name", selector: "lastname" },
    { name: "Role", selector: "role" },
    { name: "Created at", selector: "created_at" },

    {
      name: "Active",
      cell: (row) => (
        <ActiveBtn patch={API.patch(collabCode, row.code)} state={row.active} />
      ),
      button: "true",
    },
    {
      name: "Edit",
      cell: (row) => (
        <EditBtn
          read={API.get(collabCode, row.code)}
          extra={fullName(row.firstname, row.lastname)}
        />
      ),
      button: "true",
    },
    {
      name: "Delete",
      cell: (row) => <DeleteBtn delete={"/back/collaborator/" + row.code} />,
      button: "true",
    },
  ];

  const breadCrumbsProps = [t(MY_COLLABORATOR)];

  const url = `/back/collaborator/${collabCode}`;
  const { data, isLoading, isError } = useFetchData(url);
  let parameterData;

  if (isLoading) return <Loading />;

  if (isError) return <NotFound />;

  if (!isLoading && !isError) {
    parameterData = data.data.results.data;
  }

  const dynamicPart = fullName(parameterData.firstname, parameterData.lastname);

  const requiredKeys = [
    {
      key: "mail",
      name: "mail",
      icon: <HiHomeModern />,
    },
    {
      key: "phone",
      name: "phone",
      icon: <HiHomeModern />,
    },
    {
      key: "registry",
      name: "registry",
      icon: <HiHomeModern />,
    },
    {
      key: "address",
      name: "address",
      icon: <HiHomeModern />,
    },

    {
      key: "active",
      name: "Active",
      icon: <AiOutlineIdcard />,
      btn: <ActiveBtn patch={API.patch(url)} state={parameterData.active} />,
    },
    {
      key: "createdAt",
      name: "Created at",
      icon: <HiHomeModern />,
    },
  ];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <Row>
            <Col xs="12" md="3">
              {!isLoading && (
                <HeaderTabs
                  title={dynamicPart}
                  objectData={parameterData}
                  requiredKeys={requiredKeys}
                />
              )}
            </Col>
            <Col xs="12" md="9">
              <DataTableHeader createBtn={true} />

              <DataTables newColumns={COLUMNS} link={list} />
            </Col>
          </Row>
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_COLLABORATOR}>
        <AddItem addURL={API.create(collabCode)} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_COLLABORATOR}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default ReadCollaborator;
