import React, { Fragment, useState } from "react";
import { Col, Row } from "reactstrap";

const Product = ({ obj }) => {
  const { title, name, price, number } = obj;
  const [productNbr, setProductNbr] = useState(number);

  const increment = () => {
    setProductNbr((prevNbr) => prevNbr + 1);
  };

  const decrement = () => {
    setProductNbr((prevNbr) => (prevNbr > 0 ? prevNbr - 1 : prevNbr));
  };

  return (
    <Fragment>
      <h4>
        <Row>
          <Col>Title: {title}</Col>
          <Col>name: {name}</Col>
          <Col>price: {price}</Col>
          <Col>
            productNbr:
            <button onClick={() => increment()}>+</button>
            {productNbr}
            <button onClick={() => decrement()}>-</button>
          </Col>
          <Col>Total: {productNbr * price}</Col>
        </Row>
      </h4>
    </Fragment>
  );
};

export default Product;
