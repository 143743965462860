import React from "react";
import { Table } from "@mantine/core";

import HandleResult from "helper/HandleResult";
import Translate from "Components/Translate";

const SimpleTable = ({ requiredKeys }) => {
  return (
    <Table highlightOnHover withTableBorder withColumnBorders>
      <Table.Thead>
        <Table.Tr>
          {requiredKeys.map((elem, i) => (
            <Table.Th key={i}>
              <Translate>{elem.title}</Translate>
            </Table.Th>
          ))}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        <Table.Tr>
          {requiredKeys.map((elem, i) => (
            <Table.Td key={i}>
              <HandleResult data={elem.key} />
            </Table.Td>
          ))}
        </Table.Tr>
      </Table.Tbody>
    </Table>
  );
};

export default SimpleTable;
