import { Fragment } from "react";

import { Card, CardBody } from "reactstrap";

import DataTables from "Components/DataTables/DataTable";
import Breadcrumbs from "Components/Breadcrumbs";
import EditItem from "Components/EditItem";
import ModalApp from "Components/ModalApp";
import AddItem from "Components/AddItem";

import { API, COLUMNS, CREATE_ITEMS, EDIT_ITEMS } from "./data";

import { CREATE_POLYGON, EDIT_POLYGON, MY_POLYGONS } from "Constant";

const PolygonsPage = () => {
  const list = API.list;

  const breadCrumbsProps = [MY_POLYGONS];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTables newColumns={COLUMNS} link={list} columnSearch={false} />
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_POLYGON}>
        <AddItem
          addURL={API.create}
          items={CREATE_ITEMS}
          defVal={{ active: true }}
        />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_POLYGON}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default PolygonsPage;
